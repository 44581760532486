import { Link } from "react-router-dom";
import React from "react";

export default function CommonBreadCrumb({ dark, breadCrumbObject }) {
	return (
		<section className={`breadcrumbs ${dark ? "bg-dark-1" : ""} `}>
			<div className="container">
				<div className="row">
					<div className="col-auto">
						<div className="breadcrumbs__content">
							{breadCrumbObject?.length
								? breadCrumbObject?.map((item, index) => (
										<div
											className={`breadcrumbs__item ${
												dark ? "text-dark-3" : ""
											} `}>
											<Link
                                            to={{
                                                pathname : item?.navigate ? item.navigate : ""
                                            }}
                                             >
												{item?.label ?? ""}
											</Link>
										</div>
								  ))
								: null}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
