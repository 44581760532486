import React from "react";
import { Link } from "react-router-dom";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MobileFooter() {
    return (
        <>
            <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                <div className="mobile-footer__number">
                    <div className="text-17 fw-500 text-dark-1">Contact us</div>
                    {/* <div className="text-17 fw-500 text-purple-1">800 388 80 90</div> */}
                </div>

                <div className="lh-2 mt-10">
                    <div>
                        1635 W.Trade Street #1F Charlotte, NC 28216.
                    </div>
                    <div>Info@probeautyport.com</div>
                </div>

                <div className="mobile-socials mt-10">
                    <Link
                        to="#"
                        className="d-flex items-center justify-center rounded-full size-40"
                    >
                        <i className="fa fa-facebook"></i>
                    </Link>

                    <Link
                        to="#"
                        className="d-flex items-center justify-center rounded-full size-40"
                    >
                        {/* <i className="fa fa-twitter"></i> */}
                        <FontAwesomeIcon icon={faXTwitter} />
                    </Link>

                    <Link
                        to="#"
                        className="d-flex items-center justify-center rounded-full size-40"
                    >
                        <i className="fa fa-instagram"></i>
                    </Link>

                    <Link
                        to="#"
                        className="d-flex items-center justify-center rounded-full size-40"
                    >
                        <i className="fa fa-linkedin"></i>
                    </Link>
                </div>
            </div>
        </>
    );
}
