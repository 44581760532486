import React from "react";
import MetaComponent from "../../components/common/MetaComponent";
import Preloader from "../../components/common/Preloader";
import Header from "../../components/layout/headers/Header";
import Footer from "../../components/layout/footers/Footer";
import {COMPANY_NAME, IMAGE_FILE_URL} from "../../config/config";

const metadata = {
    title:
        `Page Not Found || ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function PageNotFound() {
    return (
        <div className="main-content  ">
            <MetaComponent meta={metadata}/>

            <Preloader/>

            <Header/>
            <div className="content-wrapper js-content-wrapper overflow-hidden">
                <section className="no-page layout-pt-lg layout-pb-lg bg-beige-1">
                    <div className="container">
                        <div className="row y-gap-50 justify-between items-center">
                            <div className="col-lg-6">
                                <div className="no-page__img">
                                    <img src={`${IMAGE_FILE_URL}/assets/img/404/1.svg`} alt="page not found"/>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-6">
                                <div className="no-page__content">
                                    <h1 className="no-page__main text-dark-1">
                                        40<span className="text-purple-1">4</span>
                                    </h1>
                                    <h2 className="text-35 lh-12 mt-5">
                                        Oops! It looks like you're lost.
                                    </h2>
                                    <div className="mt-10">
                                        The page you're looking for isn't available. Try to search again
                                        <br/> or use the go to.
                                    </div>
                                    <button onClick={() => window.location.replace('/')}
                                            className="button -md -purple-1 text-white mt-20">
                                        Go Back To Homepage
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
    );
}
