import React from "react";

const steps = [
	{
		id: 1,
		icon: "icon-online-learning-4 text-64 text-green-1",
		title: "Access to High-Quality Courses",
		text: "Our platform provides access to a diverse selection of courses covering various aspects of beauty, from hairstyling and makeup artistry to skincare and nail techniques.",
	},
	{
		id: 2,
		icon: "icon-graduation-1 text-64 text-green-1",
		title: "Learning From Industry Experts",
		text: "Our courses are developed and taught by experienced beauty professionals who are experts in their respective fields. You'll learn from the best and gain insights and techniques directly from those who have excelled in the industry.",
	},
	{
		id: 3,
		icon: "icon-working-at-home-2 text-64 text-green-1",
		title: "Flexible Learning Options",
		text: "With our platform, you can study at your own pace and on your own schedule. Whether you prefer to learn in short bursts or dedicate longer periods to your studies, our flexible learning options accommodate your needs.",
	},
];

export default function WhyOurCourses() {
	return (
		<section className="layout-pt-lg layout-pb-lg bg-dark-2">
			<div className="container">
				<div className="row justify-center text-center">
					<div className="col-auto">
						<div className="sectionTitle ">
							<h2
								className="sectionTitle__title_2 text-white lh-14"
								data-aos="fade-up"
								data-aos-duration={800}>
								What to Expect From Our Courses?
							</h2>

							<p
								className="sectionTitle__text text-white"
								data-aos="fade-up"
								data-aos-duration={800}>
								Empower Your Journey with Expert-led Education
							</p>
						</div>
					</div>
				</div>

				<div className="row y-gap-30 pt-50">
					{steps.map((elm, i) => (
						<div
							key={i}
							className="col-lg-4 col-md-6"
							data-aos="fade-up"
							data-aos-duration={(i + 1) * 400}
							style={{ display: "flex" }}>
							<div className="stepCard -type-1 -stepCard-hover">
								<div className="stepCard__content">
									<div className="stepCard__icon">
										<i className={elm.icon}></i>
									</div>
									<h4 className="stepCard__title">{elm.title}</h4>
									<p className="stepCard__text"> {elm.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
