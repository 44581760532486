import React, { useCallback, useEffect, useState } from "react";
import {
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	Rating,
	useMediaQuery,
	Pagination,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getSuperInstructors } from "../../../../redux/action/superAdminActions/superInstructorsActions";
import {
	commonDashboardHeading,
	commonDashboardSubHeading,
} from "../../../../components/common/DashboardElements";
import { debounce } from "lodash";
import SuperInstructorFilterAndSortingArea from "./SuperInstructorFilterAndSortingArea";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { useParams, useNavigate } from "react-router-dom";
import SuperStudentDetail from "../students/SuperStudentDetail";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperInstructors() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const [search, setSearch] = useState("");
	const [filterRating, setFilterRating] = useState(0);
	const [sortBy, setSortBy] = useState([]);
	const [openDetailPage, setOpenDetailPage] = useState(!!id);

	const {
		data: Instructors,
		isLoading,
		isSuccess,
		meta_data,
	} = useSelector((state) => state.superAdmin.superInstructors);

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		!isSuccess && dispatch(getSuperInstructors());
	}, [dispatch, isSuccess]);

	// Fetch data when student ID changes
	useEffect(() => {
		if (id) {
			// Fetch the student detail data based on the ID
			setOpenDetailPage(true);
		}
	}, [id]);

	const bodyForGetApi = () => {
		let body = {
			page: meta_data?.current ?? 1,
			search: search,
			rating: filterRating,
			ordering: sortBy[0],
		};
		return body;
	};

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value, filterRating, sortBy) => {
			let body = {
				search: value,
				page: 1,
				rating: filterRating,
				ordering: sortBy[0],
			};
			dispatch(getSuperInstructors(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value, filterRating, sortBy);
	};

	const handleApplyFilters = () => {
		const body = {
			page: 1,
			search: search,
			rating: filterRating,
			ordering: sortBy[0],
		};

		dispatch(getSuperInstructors(body));
	};

	const handleResetFilters = () => {
		setFilterRating(0);
		let updatedBody = {
			page: 1,
			search: search,
			ordering: sortBy[0],
		};
		dispatch(getSuperInstructors(updatedBody));
	};

	const handleSorting = (item) => {
		const body = {
			page: meta_data.current ?? 1,
			search: search,
			rating: filterRating,
			ordering: item,
		};
		dispatch(getSuperInstructors(body));
	};

	const handlePageChange = (value) => {
		const body = {
			page: value,
			search: search,
			rating: filterRating,
			ordering: sortBy[0]
		};
		dispatch(getSuperInstructors(body));
	};

	const handleInstructorClicked = (instructorId) => {
		navigate(`${instructorId}`);
		setOpenDetailPage(true);
	};

	const handleDetailPageClose = () => {
		navigate("/super_admin/instructors");
		setOpenDetailPage(false);
	};

	const fullColumns = [
		{
			field: "instructor",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Instructor"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={
								<b
									className="text-purple-1 cursor"
									onClick={() => handleInstructorClicked(params?.row?.seller_id)}>
									{params?.row?.user_first_name +
										" " +
										params?.row?.user_last_name ?? "--"}
								</b>
							}
							secondary={
								<div>
									{params.row.designation}&nbsp; (
									{params.row.email_verified ? (
										<span style={{ color: "green" }}>Verified</span>
									) : (
										<span style={{ color: "red" }}>Not Verified</span>
									)}
									)
								</div>
							}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Email"}</Typography>,
			renderCell: (params) => {
				return <div>{params?.row?.user_email ?? "--"}</div>;
			},
		},
		{
			field: "courses_count",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Courses"}</Typography>,
		},
		{
			field: "student_count",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Students"}</Typography>,
		},
		{
			field: "rating",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Ratings"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItemText
						primary={
							<span style={{ display: "flex", alignItems: "center" }}>
								{getRatingStars(params.row?.ratings?.total_rating ?? 0)}(
								{params.row?.ratings?.total_rating ?? 0})
							</span>
						}
						secondary={`${params.row?.ratings?.total_reviews_count} reviews`}
					/>
				);
			},
		},
		// {field: 'actions', headerName: 'Actions', sortable: false},
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "instructor",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Instructor"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={
								<b
									className="text-purple-1 cursor"
									onClick={() => handleInstructorClicked(params?.row?.seller_id)}>
									{params?.row?.user_first_name +
										" " +
										params?.row?.user_last_name ?? "--"}
								</b>
							}
							secondary={
								<div>
									{params.row.designation}&nbsp; (
									{params.row.email_verified ? (
										<span style={{ color: "green" }}>Verified</span>
									) : (
										<span style={{ color: "red" }}>Not Verified</span>
									)}
									)
								</div>
							}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "student_count",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Students"}</Typography>,
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForFilterAndSortingComponent = {
		loading: isLoading,
		handleApplyFilters,
		handleResetFilters,
		sortBy,
		setSortBy,
		handleSorting,
		filterRating,
		setFilterRating,
		getRatingStars,
	};

	return (
		<div className="dashboard__main">
			<div
				className="dashboard__content bg-light-4"
				style={{ paddingBottom: "0px" }}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("Educators")}
						{commonDashboardSubHeading("Welcome to the Educators Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</div>

				<SuperInstructorFilterAndSortingArea
					{...propsForFilterAndSortingComponent}
				/>

				{openDetailPage && (
					<SuperStudentDetail
						open={openDetailPage}
						onClose={handleDetailPageClose}
						bodyForGetApi={bodyForGetApi}
						type="INSTRUCTOR"
					/>
				)}

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(95vh - 320px)",
					}}>
					<DataGrid
						rows={Instructors}
						columns={columns}
						rowHeight={100}
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter />
		</div>
	);
}
