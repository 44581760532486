import React, { useEffect, useState } from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Footer from "../../../components/layout/footers/Footer";
import Faq from "./../../../components/common/Faq";
import { useParams } from "react-router";
import { instructor_account_profile, instructor_course_building, instructor_getting_started, instructor_payment, trainer_account_profile, trainer_getting_started, trainer_learning_experience } from "./faqlist";

const metadata = {
	title: `FAQ || ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function FAQSection() {
	const { faqTopic } = useParams();
	const [faqObject, setFaqObject] = useState({});

	const getData = () => {
		switch (faqTopic) {
			case "trainer_getting_started": {
				setFaqObject(trainer_getting_started);
				break;
			}
            case "trainer_account_profile": {
				setFaqObject(trainer_account_profile);
				break;
			}
            case "trainer_learning_experience": {
				setFaqObject(trainer_learning_experience);
				break;
			}
			case "instructor_getting_started" :{
				setFaqObject(instructor_getting_started);
				break;
			}
            case "instructor_course_building" :{
				setFaqObject(instructor_course_building);
				break;
			}
			case "instructor_account_profile" :{
				setFaqObject(instructor_account_profile);
				break;
			}
            case "instructor_payment" :{
				setFaqObject(instructor_payment);
				break;
			}
			default: {
				setFaqObject(trainer_getting_started);
			}
		}
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [faqTopic]);

	return (
		<div className="main-content">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<div className="mt-90">
					<Faq faqObject={faqObject} />
				</div>
				<Footer />
			</div>
		</div>
	);
}
