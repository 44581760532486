import {GET_TESTIMONIALS} from "../../action/commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";

// const tempTestimonials = {
//     id: 'random',
//     imageSrc: "https://educrat-react.vercel.app/assets/img/testimonials/4.png",
//     name: "Emily Johnson",
//     position: "Freelancer",
//     comment: "Great Work",
//     description: "I've been using this platform and I'm impressed by the quality of the courses. They cover a wide range of topics and are easy to follow.",
// }

const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    isError: false
};

const testimonialsReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_TESTIMONIALS : {
            switch (action.status) {
                case LOADING: {
                    return {
                        ...state,
                        isLoading: true
                    }
                }
                case SUCCESS: {
                    const updatedDataAccordingToTemplate = action?.payload?.data?.length ? action.payload.data.map((item, index) => {
                        return {
                            id: item.id,
                            imageSrc: item?.user_profile_image ?? "",
                            name: item?.user_name ?? "",
                            position: item?.user_designation ?? '',
                            comment: item?.title ?? '',
                            description: item?.content ?? ''
                        }
                    }) : []
                    return {
                        ...state,
                        data: updatedDataAccordingToTemplate,
                        isSuccess: true,
                        isLoading: false
                    }
                }
                case ERROR: {
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isSuccess: false
                    }
                }

                default:
                    return state
            }
        }
        default :
            return state;
    }
};
export default testimonialsReducers;
