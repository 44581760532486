import {Link} from "react-router-dom";
import React from "react";
import about_us_instructor_image from '../../../images/aboutUs/about_us_instructor_image.png'

export default function InstructorJoinToday() {
    return (
        <section className="layout-pt-lg layout-pb-lg bg-light-4">
            <div className="container">
                <div className="row y-gap-50 justify-between items-center">
                    <div className="col-lg-7 ">
                        <img 
                            src={about_us_instructor_image}
                            alt="join-today"/>
                    </div>

                    <div className="col-lg-5">
                        <h2 className="text-45 lh-15">
                            Become an Educator <span className="text-purple-1">Today</span>
                        </h2>
                        <p className="text-dark-1 mt-25">
                            No risk, all reward. Upload your content for free and start earning without any financial investment.
                        </p>
                        <div className="d-inline-block mt-30">
                            <Link
                                to="/trainer-sign-up"
                                className="button -md -dark-1 text-white"
                            >
                                Start Selling Now!
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
