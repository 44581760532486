import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { userForgotPasswordAction } from "../../redux/action/userActions/userActions";

export default function ForgotPasswordForm() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		// Accessing form values
		const email = event.target.elements.email.value;
		if (email) {
			let body = {
				email,
			};
			dispatch(
				userForgotPasswordAction(body, {
					onLoading: (loading) => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						event.target.reset();
					},
				})
			);
		}
	};
	return (
		<div className="form-page__content lg:py-50">
			<div className="container">
				<div className="row justify-center items-center">
					<div
						// className="col-xl-6 col-lg-8"
						className="col-xl-8 col-lg-10">
						<div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
							<h3 className="text-30 lh-13 text-center">Forgot Password</h3>
							<p className="mt-10 text-center">
								Don't have an account yet? &nbsp;
								<Link to="/signup" className="text-purple-1">
									Sign up
								</Link>
							</p>

							<form
								className="contact-form respondForm__form row y-gap-20 pt-30"
								onSubmit={handleSubmit}>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Email
									</label>
									<input
										required
										type="email"
										autoComplete={"email"}
										name="email"
										placeholder="Enter your email here..."
									/>
								</div>
								<div className="col-12">
									<button
										type="submit"
										name="submit"
										id="submit"
										className={`button -md ${
											loading ? "-blue-3" : "-green-1"
										} text-dark-1 fw-500 w-1/1`}
										disabled={loading}>
										{loading ? "Loading..." : "Submit"}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
