import React, { useEffect, useState } from "react";
import {
	Avatar,
	Button,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Pagination,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteSuperTestimonialsAction,
	getSuperTestimonials,
} from "../../../../redux/action/superAdminActions/superTestimonialsActions";
import AddSuperTestimonials from "./AddSuperTestimonials";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";
import { commonDashboardHeading, commonDashboardSubHeading } from "../../../../components/common/DashboardElements";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { useCallback } from "react";
import { debounce } from "lodash";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperTestimonials() {
	const dispatch = useDispatch();
	const [openAddForm, setOpenAddForm] = useState(false);
	const [openEditForm, setOpenEditForm] = useState(false);
	const [selectedTestimonials, setSelectedTestimonials] = useState({});
	const [search, setSearch] = useState("")
	const { enqueueSnackbar } = useSnackbar();

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	const {
		data: Testimonials,
		isLoading,
		isSuccess,
		meta_data
	} = useSelector((state) => state.superAdmin.superTestimonials);

	const bodyForGetApi = () => {
		let body = {
			page: meta_data?.current ?? 1,
			search: search,
		};
		return body;
	};

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		!isSuccess && dispatch(getSuperTestimonials());
	}, [dispatch, isSuccess]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value) => {
			let body = {
				search: value,
				page: 1,
			};
			dispatch(getSuperTestimonials(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value);
	};

	const handlePageChange = (value) => {
		let tempBody = bodyForGetApi()
		let body = {
			...tempBody,
			page: value,
		};
		dispatch(getSuperTestimonials(body));
	};

	const handleDelete = (categoryId) => {
		dispatch(
			deleteSuperTestimonialsAction(categoryId, {
				onSuccess: (success) => {
					enqueueSnackbar("Testimonial Deleted Successfully...", {
						variant: "success",
					});
					dispatch(getSuperTestimonials());
				},
			})
		);
	};

	const fullColumns = [
		{
			field: "profile",
			width: 200,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Profile "}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={<b>{params?.row?.user_name ?? "--"}</b>}
							secondary={params.row.user_designation}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "detail",
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Title & Content"}</Typography>
			),
			renderCell: (params) => {
				return (
					<ListItemText
						primary={params.row.title}
						secondary={
							<Tooltip title={params.row.content}>
								<span>
									{params.row.content.length > 30
										? params.row.content.substring(0, 60) + "..."
										: params.row.content}
								</span>
							</Tooltip>
						}
					/>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedTestimonials(params.row);
								setOpenEditForm(true);
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this testimonial?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "profile",
			width: 200,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Profile "}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={<b>{params?.row?.user_name ?? "--"}</b>}
							secondary={params.row.user_designation}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedTestimonials(params.row);
								setOpenEditForm(true);
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this testimonial?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForAddForm = {
		open: openAddForm,
		setOpen: setOpenAddForm,
		type: "ADD",
		bodyForGetApi
	};

	const propsForEditForm = {
		open: openEditForm,
		setOpen: setOpenEditForm,
		type: "EDIT",
		selectedTestimonial: selectedTestimonials,
		bodyForGetApi
	};

	return (
		<div className="dashboard__main">
			{openAddForm && <AddSuperTestimonials {...propsForAddForm} />}
			{openEditForm && <AddSuperTestimonials {...propsForEditForm} />}
			<div className="dashboard__content bg-light-4" style={{paddingBottom:'0px'}}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("Testimonials")}
						{commonDashboardSubHeading("Welcome to the Testimonials Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
					<div className="col-auto">
						<Button
							className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
							startIcon={<Add />}
							onClick={() => setOpenAddForm(true)}>
							Add Testimonials
						</Button>
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(98vh - 260px)",
					}}>
					<DataGrid
						rows={Testimonials}
						columns={columns}
						rowHeight={80}
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter/>
		</div>
	);
}
