import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Avatar,
	LinearProgress,
	Pagination,
	Rating,
	Tooltip,
} from "@mui/material";
import moment from "moment";
import { Empty } from "antd";
import { getTrainerCoursesRatingsAction } from "../../../../../../redux/action/trainerActions/trainerActions";
import { addCourseRatingAction } from "./../../../../../../redux/action/courseActions/courseRatingsActions";
import { useSnackbar } from "notistack";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function MainReviewPage() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [instructorReplies, setInstructorReplies] = useState({});
	const [loading, setLoading] = useState(false);

	const {
		data: reviews_obj,
		meta_data,
		isLoading,
	} = useSelector((state) => state.trainers.trainerCoursesReviews);

	useEffect(() => {
		let body = {
			page: 1,
		};
		dispatch(getTrainerCoursesRatingsAction(body));
	}, [dispatch]);

	const handlePageChange = (value) => {
		let body = {
			page: value,
		};
		dispatch(getTrainerCoursesRatingsAction(body));
	};

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const handleSubmitInstructorReview = (rating) => {
		let body = {
			description: instructorReplies[rating.id],
			rating_id: rating.id,
			reply: true,
		};
		dispatch(
			addCourseRatingAction(body, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					let body = {
						page: meta_data?.current ?? 1,
					};
					dispatch(getTrainerCoursesRatingsAction(body));
					setInstructorReplies({});
				},
			})
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-30 lh-1 fw-700">Review</h1>
						<div className="mt-10">
							Welcome to the review section for all Courses.
						</div>
					</div>
				</div>

				<div className="row y-gap-30">
					<div className="col-12">
						{isLoading && <LinearProgress />}
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4">
							<ul className="comments__list">
								{reviews_obj.length ? (
									reviews_obj.map((elm, i) => (
										<li
											key={i}
											className="comments__item"
											style={{ padding: "30px" }}>
											<div className="comments__item-inner md:direction-column d-flex">
												<div className="comments__img mr-20">
													<Avatar
														variant="circular"
														sx={{ width: 50, height: 50 }}
														alt="student_profile"
														src={elm.user_profile_image}
													/>
												</div>

												<div
													className="comments__body md:mt-15"
													style={{ width: "100%" }}>
													<div className="comments__header">
														<h4 className="text-17 fw-500 lh-15">
															{elm.user_first_name + " " + elm.user_last_name}{" "}
															<span className="text-13 text-light-1 fw-400">
																({moment(elm.created_at).format("MMM Do YYYY")})
															</span>
														</h4>
														<div className="d-flex text-17">
															<span className="fw-500">Course:</span>&nbsp;{" "}
															{elm?.course_name ?? ""}
														</div>
														<div className="d-flex">
															{getRatingStars(elm?.rating ?? 0)}
														</div>
													</div>

													<h5 className="text-15 fw-500 mt-15">{elm.title}</h5>
													<div className="comments__text mt-10">
														<p>{elm.description}</p>
													</div>
													<ul className="comments__list__instructor_reply mt-20 pl-20">
														{elm?.instructor_review?.length
															? elm.instructor_review.map(
																	(instReply, index) => (
																		<li
																			key={i + "-" + index}
																			className="comments__item"
																			style={
																				index ===
																				elm.instructor_review.length - 1
																					? { padding: "10px 0px" }
																					: {
																							padding: "10px 0px",
																							borderBottom: "1px solid #E4E4E4",
																					  }
																			}>
																			<div className="comments__item-inner md:direction-column d-flex">
																				<div className="comments__img mr-20">
																					<Avatar
																						variant="circular"
																						sx={{ width: 50, height: 50 }}
																						alt="student_profile"
																						src={
																							instReply?.user_profile_image ??
																							""
																						}
																					/>
																				</div>
																				<div className="comments__body">
																					<div className="comments__header">
																						<h4 className="text-17 fw-500 lh-15">
																							{instReply.user_first_name +
																								" " +
																								instReply.user_last_name}{" "}
																							<span className="text-13 text-light-1 fw-400">
																								(
																								{moment(
																									instReply.created_at
																								).format("MMM Do YYYY")}
																								)
																							</span>
																						</h4>
																					</div>
																					<div className="comments__text">
																						<p>{instReply.description}</p>
																					</div>
																				</div>
																			</div>
																		</li>
																	)
															  )
															: null}
													</ul>

													{/* Response Section */}
													<div
														className="response-section mt-15 review-response-section"
														style={{
															display: "flex",
															alignItems: "center",
															paddingLeft: elm?.instructor_review?.length
																? "90px"
																: "0px",
														}}>
														<textarea
															id={`response-textarea-${elm.id}`}
															className="response-textarea review-response-section-textarea"
															placeholder="Write your response here..."
															style={{
																flex: "1",
																marginRight: "10px",
																border: "2px solid #e5f0fd",
															}}
															value={instructorReplies[elm?.id] ?? ""}
															onChange={(event) =>
																setInstructorReplies({
																	...instructorReplies,
																	[elm.id]: event.target.value,
																})
															}
														/>
														<Tooltip
															title={
																!instructorReplies[elm?.id]?.trim().length
																	? "Enter Response First"
																	: ""
															}>
															<button
																className="button text-13 -sm -light-7 -dark-button-dark-2 text-purple-1 md:mt-15 review-response-section-button"
																disabled={
																	loading ||
																	!instructorReplies[elm?.id]?.trim().length
																}
																onClick={() =>
																	handleSubmitInstructorReview(elm)
																}>
																Respond
															</button>
														</Tooltip>
													</div>
													{/* Response Section */}
												</div>
											</div>
										</li>
									))
								) : (
									<Empty description={"Reviews not available yet"} />
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className="row justify-center pt-30 pb-20">
					<div className="col-auto">
						<Pagination
							count={meta_data?.total_pages ?? 0}
							page={meta_data?.current ?? 1}
							onChange={(event, value) => handlePageChange(value)}
							size="large"
							sx={{
								ul: {
									"& .Mui-selected": {
										background: "#6440FB",
										color: "white",
									},
								},
							}}
							disabled={isLoading}
						/>
					</div>
				</div>
			</div>
			<LoggedInFooter />
		</div>
	);
}
