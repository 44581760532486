import { Box, Typography } from "@mui/material";
import React from "react";
import noDataImage from '../../images/common/no_data_found.svg'

export const CustomNoDataComponent = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
				width: "100%",
				flexDirection: "column",
				textAlign: "center",
				overflow: "hidden",
				padding: "10px",
			}}>
			<img
				src={noDataImage}
				alt="no-data"
				style={{
					maxHeight: "70px",
					maxWidth: "100%",
					objectFit: "contain",
				}}
			/>
			<Typography className="text-15 mt-20" style={{ color: "#4f547b" }}>
				No Data Available
			</Typography>
		</Box>
	);
};
