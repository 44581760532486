import React, { useEffect, useState } from "react";
import {
	Avatar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddSuperCategoryAction, getSuperCategories, updateSuperCourseCategoryAction } from "../../../../redux/action/superAdminActions/superCategoryActions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { SUPER_CATEGORIES_IMAGE_LOCATION } from "../../../../config/config";
import { getMediaUploadLink } from "../../../../redux/action/myDashboardActions/coursesActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const AddSuperCategory = (props) => {
	const { open, setOpen, type, selectedCategory,bodyForGetApi } = props;
	const [loading, setLoading] = useState(false);
	const [imageUploadLoading, setImageUploadLoading] = useState(false);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [previewImage, setPreviewImage] = useState("");
	const [selectedImage, setSelectedImage] = useState("");

	const {
		register,
		handleSubmit,
        setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			category_name: "",
		},
	});

    useEffect(() => {
		setValue("category_name", selectedCategory?.name ?? "");
        setPreviewImage(selectedCategory?.category_icon ?? "");
	}, [selectedCategory, setValue]);

	const isImagePresent = previewImage.includes("amazonaws");

	const handleImageChange = (event) => {
		if (event.target.files[0]) {
			setSelectedImage(event.target.files[0]);
			const imageUrl = URL.createObjectURL(event.target.files[0]);
			setPreviewImage(imageUrl);
		}
	};

	const handleAddData = (body) => {
		dispatch(
			AddSuperCategoryAction(body, {
				onLoading: () => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					setImageUploadLoading(false)
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					setImageUploadLoading(false)
					enqueueSnackbar(success.message[0], { variant: "success" });
					let bodyForGet = bodyForGetApi?.()
					dispatch(getSuperCategories(bodyForGet));
					handleClose();
				},
			})
		);
	};

    const handleUpdateData = (body) => {
        let updatedBody = {
            ...body,
            id : selectedCategory?.id ?? ''
        }
        dispatch(
			updateSuperCourseCategoryAction(updatedBody, {
				onLoading: () => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					setImageUploadLoading(false)
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					setImageUploadLoading(false)
					enqueueSnackbar(success.message[0], { variant: "success" });
					let bodyForGet = bodyForGetApi?.()
					dispatch(getSuperCategories(bodyForGet));
					handleClose();
				},
			})
		);
    }

	// Function to generate a random string
	function generateRandomString(length) {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	// Function to generate a unique filename
	function generateUniqueFilename() {
		const timestamp = new Date().getTime(); // Current timestamp
		const randomString = generateRandomString(8); // Generate a random string of length 8
		return `${timestamp}_${randomString}`;
	}

	const uploadFilesOnStorage = async (url, file, fileName, pre_body) => {
		if (file) {
			setImageUploadLoading(true);
			const renamedFile = new File([file], fileName, { type: file.type });

			try {
				await fetch(url, {
					method: "PUT",
					body: renamedFile,
				});
				let body = {
					...pre_body,
					category_icon: `${SUPER_CATEGORIES_IMAGE_LOCATION}${fileName}`,
				};
                if(type === "EDIT"){
                    handleUpdateData(body)
                }else{
                    handleAddData(body);
                }
			} catch (error) {
				enqueueSnackbar(error, { variant: "error" });
				setImageUploadLoading(false);
			}
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitBasicDetails = (data) => {
		setLoading(true);
		const { category_name } = data;

		let body = {
			name: category_name,
		};

		if (isImagePresent) {
			// update only category_name
            handleUpdateData(body)
		} else {
			if (selectedImage) {
				setImageUploadLoading(true);
				const ImageExtension = selectedImage.name.split(".").pop();
				const imageName = generateUniqueFilename() + "." + ImageExtension;

				dispatch(
					getMediaUploadLink(`${SUPER_CATEGORIES_IMAGE_LOCATION}${imageName}`, {
						onLoading: (loading) => {
							setImageUploadLoading(true);
						},
						onError: (error) => {
							setImageUploadLoading(false);
							let errorMessage = String(
								error?.message ?? "Something went wrong"
							);
							enqueueSnackbar(errorMessage, { variant: "error" });
						},
						onSuccess: (success) => {
							uploadFilesOnStorage(
								success?.data?.signed_url,
								selectedImage,
								imageName,
								body
							);
						},
					})
				);
			} else {
				enqueueSnackbar("Please add Category Image.", { variant: "error" });
                setLoading(false)
			}
		}
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			maxWidth={"sm"}
			fullWidth>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				{type === "EDIT" ? "Update Category" : "Add Category"}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<form
					onSubmit={handleSubmit(onSubmitBasicDetails)}
					className="contact-form row y-gap-30">
					<div className="row y-gap-20 x-gap-20 items-center">
						<label
							className="col-auto"
							htmlFor="imageUpload"
							style={
								previewImage
									? {}
									: {
											backgroundColor: "#f2f3f4",
											width: "150px",
											height: "90px",
											objectFit: "contain",
											marginLeft: "10px",
									  }
							}>
							{previewImage && (
								<Avatar
									sx={{
										width: "150px",
										height: "90px",
										objectFit: "contain",
									}}
									src={previewImage}
									alt={previewImage ? "image" : ""}
									variant="square"
								/>
							)}
						</label>

						<div className="col-auto" >
							<div className="text-16 fw-500 text-dark-1">Category Image</div>
							<div className="text-14 lh-1 mt-10">
								Please provide an image of your category.
							</div>

							<div className="d-flex x-gap-10 y-gap-10 flex-wrap pt-15">
								<div>
									<div className="d-flex justify-center items-center size-40 rounded-8 bg-light-3">
										<label
											style={{ cursor: "pointer" }}
											htmlFor="licenseImage1"
											className="icon-cloud text-16"></label>
										<input
											required
											id="licenseImage1"
											type="file"
											accept="image/*"
											onChange={handleImageChange}
											style={{ display: "none" }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Category
						</label>
						<input
							{...register("category_name", {
								required: "Category is required",
							})}
							type="text"
							placeholder="Enter Category"
						/>
						{errors.category_name && (
							<span style={{ color: "red" }}>
								{errors.category_name.message}
							</span>
						)}
					</div>
				</form>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					className="button -sm -purple-1 text-white"
					variant={"contained"}
					loading={loading || imageUploadLoading}
					autoFocus
					type="submit"
					onClick={handleSubmit(onSubmitBasicDetails)}>
					{type === "EDIT" ? "Update" : "Save"}
				</LoadingButton>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default AddSuperCategory;
