import React from "react";
import {COMPANY_NAME} from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Instructors from "../../../components/common/Instructors";
import Footer from "../../../components/layout/footers/Footer";
import BecomeAnInstructorTabs from "./BecomeAnInstructorTabs";
import LearningJourneyColumns from "./LearningJourneyColumns";
import InstructorJoinToday from "./InstructorJoinToday";
import HereHowItWorks from "./HereHowItWorks";
import ReadyToGetStarted from "./ReadyToGetStarted";


const metadata = {
    title:
        `Educator | ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function BecomeAnInstructor() {
    return (
        <div className="main-content  ">
            <MetaComponent meta={metadata}/>
            <Preloader/>
            <Header/>
            <div className="content-wrapper  js-content-wrapper overflow-hidden">
                {/*Page Heading start*/}
                <section className="page-header -type-1">
                    <div className="container layout-pt-lg">
                        <div className="page-header__content">
                            <div className="row justify-center text-center">
                                <div className="col-auto">
                                    <div>
                                        <h1 className="page-header__title lh-14">Become an Educator</h1>
                                    </div>

                                    <div>
                                        <p className="page-header__text">
                                            The best platform for beauty professional educators to earn residual income for life!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Page Heading ends*/}
                <section className=" layout-pb-lg">
                    <div className="container">
                        <BecomeAnInstructorTabs/>
                        <LearningJourneyColumns/>
                    </div>
                </section>

                <HereHowItWorks/>

                <InstructorJoinToday/>

                <Instructors/>

                <ReadyToGetStarted/>

                <Footer/>
            </div>
        </div>
    );
}
