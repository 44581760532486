import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { GET_SUPER_COURSE_SETTINGS } from "../../action/commonActions/types";

const initialState = {
    data: {},
    isLoading: false,
    isSuccess: false,
    isError: false
};

const superCourseSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPER_COURSE_SETTINGS : {
            switch (action.status) {
                case LOADING: {
                    return {
                        ...state,
                        isLoading: true,
                    }
                }
                case SUCCESS: {
                    return {
                        ...state,
                        data: action?.payload?.data ?? [],
                        isSuccess: true,
                        isLoading: false
                    }
                }
                case ERROR: {
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isSuccess: false
                    }
                }

                default:
                    return state
            }
        }
        default :
            return state;
    }
};
export default superCourseSettingsReducer;
