import React, { useEffect, useState } from "react";
import { COMPANY_NAME } from "../../config/config";
import MetaComponent from "./MetaComponent";
import Preloader from "./Preloader";
import Header from "../layout/headers/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
	deleteCourseInCartAction,
	deleteCourseInLocalCartAction,
	getCourseInCartAction,
} from "../../redux/action/cartActions/cartActions";
import { Empty, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const metadata = {
	title: `Course-cart  | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function CourseCartPage() {
	const [totalPrice, setTotalPrice] = useState(0);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const { data: courseCart, isLoading: isCartLoading } = useSelector(
		(state) => state.cart.courseCart
	);

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const localStorageCart = localStorage.getItem("beauty_cart_items") ?? "[]";
	const localStorageCartItems = JSON.parse(localStorageCart);

	const handleRemoveCart = (course) => {
		if (parsedLoggedInData.id) {
			dispatch(
				deleteCourseInCartAction(course.id, {
					onLoading: (loading) => {
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						enqueueSnackbar(success.message[0], { variant: "success" });
						dispatch(getCourseInCartAction());
					},
				})
			);
		} else {
			dispatch(deleteCourseInLocalCartAction(course.id));
		}
		const removedLocalHostData = localStorageCartItems.length
			? localStorageCartItems.filter(
					(item) => String(item.id) !== String(course.id)
			  )
			: [];
		localStorage.setItem(
			"beauty_cart_items",
			JSON.stringify(removedLocalHostData)
		);
	};

	useEffect(() => {
		let totalSalePrice = 0;
		courseCart?.length &&
			courseCart.forEach((course) => {
				totalSalePrice += parseFloat(course.sale_price);
			});
		setTotalPrice(totalSalePrice);
	}, [courseCart]);

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<>
					<section className="page-header -type-1 mt-90">
						<div className="container">
							<div className="page-header__content">
								<div className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">Your Course Cart</h1>
										</div>

										<div>
											<p className="page-header__text">
												Invest in Your Beauty Education Success!
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="layout-pt-lg layout-pb-lg">
						<div className="container">
							<div className="row justify-end">
								<div className="col-12">
									<div className="px-30 pr-60 py-25 rounded-8 bg-light-6 md:d-none">
										<div className="row justify-between">
											<div className="col-md-6">
												<div className="fw-500 text-purple-1">Course</div>
											</div>
											<div className="col-md-2">
												<div className="fw-500 text-purple-1">Price</div>
											</div>
											{/* <div className="col-md-2">
												<div className="fw-500 text-purple-1">Quantity</div>
											</div>
											<div className="col-md-2">
												<div className="fw-500 text-purple-1">Subtotal</div>
											</div> */}
											<div className="col-md-1">
												<div className="d-flex justify-end">
													<div className="fw-500 text-purple-1">Remove</div>
												</div>
											</div>
										</div>
									</div>

									<div className="px-30 pr-60 md:px-0">
										<Spin spinning={isCartLoading}>
											{courseCart?.length ? (
												courseCart.map((elm, i) => (
													<div
														key={i}
														className="row y-gap-20 justify-between items-center pt-30 pb-30 border-bottom-light">
														<div className="col-md-6">
															<div className="d-flex items-center">
																<div className="">
																	<div
																		className="size-100 bg-image rounded-8 js-lazy"
																		style={{
																			backgroundImage: `url(${elm.course_thumbnail_image})`,
																		}}></div>
																</div>
																<div className="fw-500 text-dark-1 ml-30">
																	<Link
																		className="linkCustom"
																		to={`/courses/${elm.slug_name}`}>
																		{elm.title}{" "}
																	</Link>
																</div>
															</div>
														</div>

														<div className="col-md-2 col-8 md:mt-15">
															<div className="">
																<div className="shopCart-products__title d-none md:d-block mb-10">
																	Price
																</div>
																<p>
																	{!elm.is_course_free
																		? `$${elm?.course_price ?? "00"}`
																		: "Free"}
																</p>
															</div>
														</div>

														{/* <div className="col-md-2">
															<div className="">
																<div className="shopCart-products__title d-none md:d-block mb-10">
																	Quantity
																</div>

																<div className="input-counter md:mt-20 js-input-counter">
																	1
																</div>
															</div>
														</div>

														<div className="col-md-2">
															<div className="">
																<div className="shopCart-products__title d-none md:d-block mb-10">
																	Subtotal
																</div>

																<p>${elm?.sale_price ?? "00"}</p>
															</div>
														</div> */}

														<div className="col-md-1 col-2">
															<div
																// className="md:d-none d-flex justify-end"
																className="d-flex justify-end"
																style={{ cursor: "pointer" }}
																onClick={() => handleRemoveCart(elm)}>
																<FontAwesomeIcon icon={faX} />
															</div>
														</div>
													</div>
												))
											) : (
												<Empty />
											)}
										</Spin>
									</div>

									{/* <div className="shopCart-footer px-16 mt-30">
										{cartCourses.length > 0 ? (
											<div className="row justify-between y-gap-30">
												<div className="col-xl-5">
													<form className="" onSubmit={handleSubmit}>
														<div className="d-flex justify-between border-dark">
															<input
																required
																className="rounded-8 px-25 py-20"
																type="text"
																placeholder="Coupon Code"
															/>
															<button
																className="text-black fw-500"
																type="submit">
																Apply coupon
															</button>
														</div>
													</form>
												</div>

												<div className="col-auto">
													<div className="shopCart-footer__item">
														<button className="button -md -purple-3 text-purple-1">
															Update cart
														</button>
													</div>
												</div>
											</div>
										) : (
											<div className="row justify-center pt-60 lg:pt-40">
												<div className="col-auto">
													<Link
														to="/courses-list-1"
														className="button -md -outline-purple-1 text-purple-1">
														Buy Course
													</Link>
												</div>
											</div>
										)}
									</div> */}
								</div>

								<div className="col-xl-4 col-lg-5 layout-pt-lg">
									<div className="py-30 bg-light-4 rounded-8 border-light">
										<h5 className="px-30 text-20 fw-500">Cart Totals</h5>

										{/* <div className="d-flex justify-between px-30 item mt-25">
											<div className="py-15 fw-500 text-dark-1">Subtotal</div>
											<div className="py-15 fw-500 text-dark-1">
												${totalPrice.toFixed(2)}
											</div>
										</div> */}

										<div className="d-flex justify-between px-30 item border-top-dark">
											<div className="pt-15 fw-500 text-dark-1">Total</div>
											<div className="pt-15 fw-500 text-dark-1">
												${totalPrice.toFixed(2)}
											</div>
										</div>
									</div>

									{!parsedLoggedInData?.id ? (
										<Link
											to="/login"
											className="button -md -purple-1 text-white col-12 mt-30">
											Login to checkout
										</Link>
									) : courseCart?.length ? (
										<Link
											to="/course-checkout"
											className="button -md -purple-1 text-white col-12 mt-30">
											Proceed to checkout
										</Link>
									) : (
										<Link
											to="/courses"
											className="button -md -purple-1 text-white col-12 mt-30">
											Continue Buying
										</Link>
									)}
								</div>
							</div>
						</div>
					</section>
				</>
			</div>
		</div>
	);
}
