import React from "react";
import {Link} from "react-router-dom";

export const footerLinks = [
    {
        title: "Important Links",
        links: [
            {href: "/about-us", label: "About Us"},
            {href: "/instructor-become", label: "Careers"},
            // {href: "/membership-plans", label: "Membership Plans"},
            {href: "/contact-us", label: "Contact Us"},
        ],
    },
    {
        title: "SUPPORT",
        links: [
            {href: "/terms", label: "Terms and Conditions"},
            {href: "/privacy-policy", label: "Privacy Policy"},
            {href: "/help-center", label: "Help and Support"},
            // {href: "/contact-us", label: "Contact Us"},
        ],
    },
];

export default function FooterLinks({allClasses}) {
    return (
        <>
            {footerLinks.map((elm, i) => (
                <div key={i} className="col-xl-2 col-lg-4 col-md-6">
                    <div className={`${allClasses ? allClasses : ""}`}>{elm.title}</div>
                    <div className="d-flex y-gap-10 flex-column">
                        {elm.links.map((itm, index) => (
                            <Link key={index} to={itm.href}>
                                {itm.label}
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
}
