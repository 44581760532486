import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CourseOverview(props) {
	const { data } = props;
	// const [showMore, setShowMore] = useState(false);

	const divideAndRound = (number) => {
		if (number % 2 === 0) {
			// If number is even, divide it by 2 and round
			return Math.round(number / 2);
		} else {
			// If number is odd, divide it by 2 and round up
			return Math.ceil(number / 2);
		}
	};

	const halfOfWhatStudentLearnLength = divideAndRound(
		data?.what_student_learn?.length ?? 0
	);

	const halfOfMaterialIncludedLength = divideAndRound(
		data?.material_included?.length ?? 0
	);

    // Check if htmlText contains any non-empty tags
    // const containsContent = /<[^/][^>]*>[\s\S]+?<\/[^>]+>/g.test(data?.requirements);

	return (
		<div id="overview" className="pt-60 lg:pt-40 to-over">
			<h4 className="text-18 fw-500">About Course</h4>

			{data?.description ? (
				<div
					// className={`show-more  mt-30 js-show-more ${
					// 	showMore ? "is-active" : ""
					// } `}
					className={`show-more  mt-30 js-show-more is-active`}>
					<div
						className="show-more__content"
						style={{maxHeight: "350px",overflowY:'auto'}}
						// style={showMore ? { maxHeight: "370px" } : {}}
					>
						<p className="">{data.description}</p>
					</div>

					{/* <button
						onClick={() => setShowMore((pre) => !pre)}
						className="show-more__button text-purple-1 fw-500 underline mt-30">
						{showMore ? "Show more" : "Show less"}
					</button> */}
				</div>
			) : (
				<div className="text-center pt-10">
					Description is not available yet...
				</div>
			)}

			<div className="mt-60">
				<h4 className="text-20 mb-30">What you'll learn</h4>
				{data?.what_student_learn?.length ? (
					<div className="row x-gap-100 justfiy-between">
						<div className="col-md-6">
							<div className="y-gap-20">
								{data.what_student_learn
									.slice(0, halfOfWhatStudentLearnLength)
									.map((elm, i) => (
										<div key={i} className="d-flex items-center">
											<div className="d-flex justify-center items-center border-light rounded-full size-20 mr-10">
												<FontAwesomeIcon
													icon={faCheck}
													style={{ transform: "scale(0.7)", color: "#6440FB" }}
												/>
											</div>
											<p>{elm ? elm : "Not available yet"}</p>
										</div>
									))}
							</div>
						</div>

						{data?.what_student_learn.length > 1 ? (
							<div className="col-md-6">
								<div className="y-gap-20">
									{data.what_student_learn
										.slice(
											data.what_student_learn.length -
												halfOfWhatStudentLearnLength
										)
										.map((elm, i) => (
											<div key={i} className="d-flex items-center">
												<div className="d-flex justify-center items-center border-light rounded-full size-20 mr-10">
													<FontAwesomeIcon
														icon={faCheck}
														style={{
															transform: "scale(0.7)",
															color: "#6440FB",
														}}
													/>
												</div>
												<p>{elm}</p>
											</div>
										))}
								</div>
							</div>
						) : null}
					</div>
				) : (
					<div className="row x-gap-100 justify-between ml-0">
						Nothing avilable yet...
					</div>
				)}
			</div>

			<div className="mt-60">
				<h4 className="text-20 mb-30">Material Included</h4>
				{data?.material_included?.length ? (
					<div className="row x-gap-100 justfiy-between">
						<div className="col-md-6">
							<div className="y-gap-20">
								{data.material_included
									.slice(0, halfOfMaterialIncludedLength)
									.map((elm, i) => (
										<div key={i} className="d-flex items-center">
											<div className="d-flex justify-center items-center border-light rounded-full size-20 mr-10">
												<FontAwesomeIcon
													icon={faCheck}
													style={{ transform: "scale(0.7)", color: "#6440FB" }}
												/>
											</div>
											<p>{elm ? elm : "Not available yet"}</p>
										</div>
									))}
							</div>
						</div>

						{data?.material_included.length > 1 ? (
							<div className="col-md-6">
								<div className="y-gap-20">
									{data.material_included
										.slice(
											data.material_included.length -
												halfOfMaterialIncludedLength
										)
										.map((elm, i) => (
											<div key={i} className="d-flex items-center">
												<div className="d-flex justify-center items-center border-light rounded-full size-20 mr-10">
													<FontAwesomeIcon
														icon={faCheck}
														style={{
															transform: "scale(0.7)",
															color: "#6440FB",
														}}
													/>
												</div>
												<p>{elm}</p>
											</div>
										))}
								</div>
							</div>
						) : null}
					</div>
				) : (
					<div className="row x-gap-100 justify-between ml-0">
						Nothing avilable yet...
					</div>
				)}
			</div>

			{/* <div className="mt-60">
				<h4 className="text-20">Requirements</h4>
				<div className="pt-20">
					{containsContent ? (
						<div dangerouslySetInnerHTML={{ __html: data?.requirements }} />
					) : (
						<div className="text-center pt-10">Not available yet...</div>
					)}
				</div>
			</div> */}
		</div>
	);
}
