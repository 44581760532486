import React, { useEffect, useState } from "react";
import {
	commonDashboardHeading,
	commonDashboardSubHeading,
} from "../../../../components/common/DashboardElements";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { useDispatch, useSelector } from "react-redux";
import { getSuperCourseSettings, updateSuperCourseSettingsAction } from "../../../../redux/action/superAdminActions/superCourseSettings";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

export default function SuperCourseSettings() {
	const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	const {
		data: CourseSettings,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.superAdmin.superCourseSettings);

	// Initialize form with useForm hook
	const { register, handleSubmit,reset } = useForm({
		defaultValues: {
			new_course_duration: CourseSettings?.new_course_duration ?? "",
			purchased_course_to_become_best_seller:
				CourseSettings?.purchased_course_to_become_best_seller ?? "",
			views_to_become_popular: CourseSettings?.views_to_become_popular ?? "",
		},
	});

	// Prefill the form with CourseSettings data
	useEffect(() => {
		if (isSuccess && CourseSettings) {
			reset({
				new_course_duration: CourseSettings?.new_course_duration || "",
				purchased_course_to_become_best_seller: CourseSettings?.purchased_course_to_become_best_seller || "",
				views_to_become_popular: CourseSettings?.views_to_become_popular || "",
			});
		}
	}, [isSuccess, CourseSettings, reset]);

	useEffect(() => {
		!isSuccess && dispatch(getSuperCourseSettings());
	}, [dispatch, isSuccess]);

	const onSubmitBasicDetails = (e) => {
        dispatch(updateSuperCourseSettingsAction(e,{
            onSuccess: (success) => {
                setLoading(false);
                enqueueSnackbar(success.message[0], { variant: "success" });
                dispatch(getSuperCourseSettings());
            },
            onError: (error) => {
                setLoading(false);
                let errorMessage = String(error?.message ?? 'Something went wrong');
                enqueueSnackbar(errorMessage, { variant: "error" });
            },
        }))
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50">
					<div className="col-auto">
						{commonDashboardHeading("Course Settings")}
						{commonDashboardSubHeading("Welcome to the Course Settings Page.")}
					</div>
				</div>

				<div className={`tabs__pane -tab-item-2 is-active`}>
					<form
						onSubmit={handleSubmit(onSubmitBasicDetails)}
						className="contact-form row y-gap-30">
						<div className="col-md-7">
							<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
								New Course Duration (in days)
							</label>

							<input
								{...register("new_course_duration")}
								required
								type="text"
								name="new_course_duration"
								placeholder="Enter Course Duration."
							/>
						</div>

						<div className="col-md-7">
							<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
								Minimum Course Purchase to become Best Seller.
							</label>

							<input
								{...register("purchased_course_to_become_best_seller")}
								required
								type="text"
								name="purchased_course_to_become_best_seller"
								placeholder="Enter minimum course purchase for best seller."
							/>
						</div>

						<div className="col-md-7">
							<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
								Minimum views to become Popular Course.
							</label>

							<input
								{...register("views_to_become_popular")}
								required
								type="text"
								name="views_to_become_popular"
								placeholder="Enter minimum views to become popular course."
							/>
						</div>

						<div className="col-12">
							<button
								disabled={(loading || isLoading)}
								className="button -md -purple-1 text-white">
								{(loading || isLoading) ? "Loading..." : "Update"}
							</button>
						</div>
					</form>
				</div>
			</div>

			<LoggedInFooter />
		</div>
	);
}
