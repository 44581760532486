import React, { useState } from "react";
import { COMPANY_NAME } from "../../../config/config";
import Socials from "../../common/Socials";
import FooterLinks from "../components/FooterLinks";
import { useDispatch } from "react-redux";
import { addNewsLetterActions } from "../../../redux/action/homePageActions/homePageActions";
import { useSnackbar } from "notistack";
import logo1 from '../../../images/logos/logo_icon.svg'
import { Link } from "react-router-dom";

export default function Footer() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();
		// Accessing the form elements using event.target
		const formData = new FormData(e.target);
		const emailValue = formData.get("user_email");

		if (emailValue) {
			dispatch(
				addNewsLetterActions(emailValue, {
					onLoading: (loading) => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						// Reset the form
						e.target.reset();
					},
				})
			);
		}
	};
	return (
		<footer className="footer -type-1 bg-dark-1 -green-links">
			<div className="container">
				<div className="footer-header">
					<div className="row y-gap-20 justify-between items-center">
						<div className="col-auto">
							<div className="footer-header__logo">
							<Link to="/">
								<img
									src={logo1}
									alt="logo"
								/>
								</Link>
							</div>
						</div>
						<div className="col-auto">
							<div className="footer-header-socials">
								<div className="footer-header-socials__title text-white">
									Follow us on social media
								</div>
								<div className="footer-header-socials__list">
									<Socials />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-columns">
					<div className="row y-gap-30 justify-content-between">
						<FooterLinks
							allClasses={"text-17 fw-500 text-white uppercase mb-25"}
						/>

						<div className="col-xl-4 col-lg-4 col-md-6">
							<div className="text-17 fw-500 text-white uppercase mb-25">
								GET IN TOUCH
							</div>
							<div className="footer-columns-form">
								<div>We don’t send spam so don’t worry.</div>
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<input
											required
											name="user_email"
											type="email"
											placeholder="Email..."
										/>
										<button type="submit" disabled={loading}>
											{loading ? "Loading..." : "Submit"}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="py-30 border-top-light-15">
					<div className="row justify-between items-center y-gap-20">
						<div className="col-auto">
							<div className="d-flex items-center h-100 text-white">
								© {new Date().getFullYear()} {COMPANY_NAME}. All Right Reserved.
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
