// --------LIBRARIES--------
import { combineReducers } from "redux";
import superCategoriesReducer from "./superCategories";
import superSubCategoriesReducer from "./superSubCategories";
import superTestimonialsReducer from "./superTestimonials";
import superInstructorsReducer from './superInstructors';
import superCoursesReducer from "./superCourses";
import superStudentsReducer from "./superStudents";
import superPaymentReducer from "./superPayments";
import superNewsLetterReducer from "./superNewsLetter";
import superCourseLanguageReducer from "./superCourseLanguage";
import superContactUsListReducer from "./superContactUsList";
import superCourseSettingsReducer from "./superCourseSettings";
import superDashboardReducer from "./superDashboard";
import superAdminDetailReducer from "./superAdminDetail";

export default combineReducers({
    superCategories: superCategoriesReducer,
    superSubCategories: superSubCategoriesReducer,
    superTestimonials: superTestimonialsReducer,
    superInstructors: superInstructorsReducer,
    superCourses: superCoursesReducer,
    superStudents: superStudentsReducer,
    superPayments: superPaymentReducer,
    superNewsLetter: superNewsLetterReducer,
    superCourseLanguage: superCourseLanguageReducer,
    superContactUsList: superContactUsListReducer,
    superCourseSettings: superCourseSettingsReducer,
    superDashboard: superDashboardReducer,
    superAdminDetail: superAdminDetailReducer
});