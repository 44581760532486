import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";
import { GET_SUPER_DASHBOARD_DATA } from "../commonActions/types";

export function getSuperDashboardData(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_DASHBOARD_DATA, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}users/getAdminDashboard`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_SUPER_DASHBOARD_DATA,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_DASHBOARD_DATA,
                status: ERROR,
                payload: error,
            });
        }
    };
}