import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	Skeleton,
	styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { getSuperCategories } from "../../../../redux/action/superAdminActions/superCategoryActions";
import {
	AddSuperSubCategoryAction,
	getSuperSubCategories,
	updateSuperSubCourseCategoryAction,
} from "../../../../redux/action/superAdminActions/superSubCatergoryActions";
import { useForm } from "react-hook-form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const AddSuperSubCategory = (props) => {
	const { open, setOpen, type, selectedSubCategory, bodyForGetApi } = props;
	const { data: Categories, isLoading } = useSelector(
		(state) => state.superAdmin.superCategories
	);

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			category: "",
			subCategory: "",
		},
	});

	useEffect(() => {
		setValue("subCategory", selectedSubCategory?.name ?? "");
	}, [selectedSubCategory, setValue]);

	const [loading, setLoading] = useState(false);
	const [categoryLoading, setCategoryLoading] = useState(isLoading);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	
	useEffect(() => {
		dispatch(
			getSuperCategories({},{
				onLoading: (loading) => {
					setCategoryLoading(true);
				},
				onError: (error) => {
					setCategoryLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (response) => {
					setCategoryLoading(false);
					if (response.data.length) {
						if(type === "EDIT"){
							setValue("category", String(selectedSubCategory.category_id));
						}else{
							setValue("category", response.data[0].value);
						}
					} else {
						setValue("category", "");
					}
				},
			})
		);
	}, [dispatch, enqueueSnackbar, selectedSubCategory?.category_id, setValue, type]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitBasicDetails = (data) => {
		if (type === "EDIT") {
			let updatedBody = {
				id : selectedSubCategory?.id ?? '',
				name: data.subCategory,
				category: data.category
			}
			dispatch(
				updateSuperSubCourseCategoryAction(updatedBody, {
					onLoading: () => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						let body = bodyForGetApi?.()
						dispatch(getSuperSubCategories(body));
						handleClose();
					},
				})
			);
		} else {
			dispatch(
				AddSuperSubCategoryAction(data.subCategory, data.category, {
					onLoading: (loading) => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						let body = bodyForGetApi?.()
						dispatch(getSuperSubCategories(body));
						handleClose();
					},
				})
			);
		}
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			maxWidth={"sm"}
			fullWidth>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				Add Sub Category
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<form
					onSubmit={handleSubmit(onSubmitBasicDetails)}
					className="contact-form row y-gap-30">
					<div className="col-md-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Category
						</label>
						<br />
						{categoryLoading ? (
							<Skeleton variant="rectangular" width={"100%"} height={40} />
						) : (
							<>
								<Select
									{...register("category", {
										required: "Category is required",
									})}
									value={watch("category")}
									onChange={(event) => {
										setValue("category", event.target.value);
									}}
									fullWidth
									placeholder="Category">
									{Categories.map((item) => (
										<MenuItem value={item.value} key={item.value}>
											{item.name}
										</MenuItem>
									))}
								</Select>
								{errors.category && (
									<span style={{ color: "red" }}>
										{errors.category.message}
									</span>
								)}
							</>
						)}
					</div>
					<div className="col-md-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Sub Category
						</label>
						<input
							{...register("subCategory", {
								required: "Sub-Category is required",
							})}
							type="text"
							placeholder="Enter Sub-Category"
						/>
						{errors.subCategory && (
							<span style={{ color: "red" }}>{errors.subCategory.message}</span>
						)}
					</div>
				</form>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					className="button -sm -purple-1 text-white"
					variant={"contained"}
					loading={loading}
					autoFocus
					type="submit"
					onClick={handleSubmit(onSubmitBasicDetails)}>
					{type === "EDIT" ? "Update" : "Save"}
				</LoadingButton>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default AddSuperSubCategory;
