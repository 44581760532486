import React, { useState } from "react";
import { IMAGE_FILE_URL } from "../../../config/config";
import { useNavigate } from "react-router";

const learnerItems = [
	{
		id: 1,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/1.svg`,
		title: "Getting Started",
		description: "Learn How Pro Beauty Works and How to Start Learning.",
		link: "trainer_getting_started",
	},
	{
		id: 2,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/2.svg`,
		title: "Account / Profile",
		description: "Manage Your Account Settings.",
		link: "trainer_account_profile",
	},
	{
		id: 3,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/3.svg`,
		title: "Learning Experience",
		description: "Everything About the Pro Beauty Port Learning Experience.",
		link: "trainer_learning_experience",
	},
];

const instructorItems = [
	{
		id: 7,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/1.svg`,
		title: "Getting Started",
		description:
			"Learn How Pro Beauty Works and How to Start Teaching on Pro Beauty Port.",
		link: "instructor_getting_started"
	},
	{
		id: 4,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/4.svg`,
		title: "Course Building",
		description: "Learn How to Create High-Quality Courses.",
		link: "instructor_course_building"
	},
	{
		id: 5,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/5.svg`,
		title: "Account/Profile",
		description: "Manage Your Educator Account Settings.",
		link:"instructor_account_profile"
	},
	{
		id: 6,
		icon: `${IMAGE_FILE_URL}/assets/img/help-center/6.svg`,
		title: "Educator Payments",
		description: "Understand the Revenue Sharing and How to Receive Payment.",
		link:"instructor_payment"
	},
];

export const helpAndSupportTabs = ["Student", "Educator"];

export default function HelpCenter() {
	const [tab, setTab] = useState("Student");
	const navigate = useNavigate()
	const [tabItems, setTabItems] = useState(learnerItems);
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<>
			<section className="page-header -type-1 mt-90">
				<div className="container">
					<div className="page-header__content">
						<div className="row justify-center text-center">
							<div className="col-auto">
								<div>
									<h1 className="page-header__title lh-14">How can we help you?</h1>
								</div>

								<div>
									<p className="page-header__text">
										Your Comprehensive Guide to Learning and Teaching on Pro
										Beauty Port.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="layout-pb-lg">
				<div className="container">
					<div className="row justify-center text-center">
						<div className="col-xl-6 col-lg-8">
							<form className="form-single-field -help" onSubmit={handleSubmit}>
								<input
									required
									type="text"
									placeholder="Enter a question, topic or keyword"
								/>
								<button className="button -purple-1 text-white" type="submit">
									<i className="icon-search text-20 mr-15"></i>
									Search
								</button>
							</form>
						</div>
					</div>

					<div className="tabs__controls flex-wrap  pt-50 d-flex justify-center x-gap-20 js-tabs-controls text-18">
						{helpAndSupportTabs.map((elm, i) => (
							<div
								onClick={() => {
									elm === "Student"
										? setTabItems(learnerItems)
										: setTabItems(instructorItems);
									setTab(elm);
								}}
								key={i}>
								<button
									className={`tabs__button px-25 py-12 rounded-8 js-tabs-button ${
										tab === elm ? "tabActive" : ""
									} `}
									data-tab-target=".-tab-item-2"
									type="button">
									{elm}
								</button>
							</div>
						))}
					</div>

					<div className="row y-gap-30 pt-90 lg:pt-50">
						{tabItems.map((elm, i) => (
							<div key={i} className="col-lg-4 col-md-6 d-flex">
								<div className="py-40 px-45 rounded-16 bg-light-4 w-1/1">
									<div className="d-flex justify-center items-center size-70 rounded-full bg-white">
										<img src={elm.icon} alt="help-center" />
									</div>
									<h4
										className="text-20 lh-11 fw-500 mt-25"
										style={
											elm?.link ? { cursor: "pointer", color: "#6440FB" } : {}
										}
										onClick={()=> elm?.link ? navigate(`/faq/${elm.link}`) : null}
										>
										{elm.title}
									</h4>
									<p className="mt-10">{elm.description}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
}
