import React from "react";
import { Link } from "react-router-dom";
export default function ReadyToGetStarted() {
	return (
		<section className="layout-pt-md layout-pb-md bg-purple-1">
			<div className="container">
				<div className="row y-gap-20 d-flex items-center">
					<div className="col-auto text-center w-1/1">
						<h2 className="text-30 lh-15 text-green-1">
							Ready to Get Started?
						</h2>
						<p className="text-white">Transform Your Passion into Profit</p>
						<div className="col-md-4 col-lg-3 pt-30" style={{margin:'auto'}}>
							<Link
								to="/trainer-sign-up"
								className="button -md -green-1 text-dark-1">
								Get Started
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
