import React, {useCallback, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Pagination, Typography, useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import { deleteSuperCourseLanguageAction, getSuperCourseLanguageData } from './../../../../redux/action/superAdminActions/superCourseLanguageActions';
import { commonDashboardHeading, commonDashboardSubHeading } from "../../../../components/common/DashboardElements";
import { debounce } from "lodash";
import { Add } from "@mui/icons-material";
import AddSuperCourseLanguage from "./AddSuperCourseLanguage";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperCourseLanguage() {

    const dispatch = useDispatch()

	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState([]);
	const [openAddForm, setOpenAddForm] = useState(false)
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedCourseLanguage, setSelectedCourseLanguage] = useState({});

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	const { enqueueSnackbar } = useSnackbar()

    const fetchCourseLanguage = useCallback(() => {
		dispatch(getSuperCourseLanguageData());
	}, [dispatch]);

	useEffect(() => {
		fetchCourseLanguage();
	}, [fetchCourseLanguage]);

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const {
		data: CourseLanguages,
		isLoading,
        meta_data
	} = useSelector((state) => state.superAdmin.superCourseLanguage);

    const handlePageChange = (value) => {
		const body = {
			page: value,
			search: search,
			ordering: sortBy[0]
		};
		dispatch(getSuperCourseLanguageData(body));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value,sortBy) => {
			let body = {
				search: value,
				page: 1,
				ordering: sortBy[0],
			};
			dispatch(getSuperCourseLanguageData(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value, sortBy);
	};

	const handleDelete = (languageId) => {
		dispatch(
			deleteSuperCourseLanguageAction(languageId, {
				onSuccess: (success) => {
					enqueueSnackbar("Language Deleted Successfully...", {
						variant: "success",
					});
					let body = {
						search: search,
						page: meta_data.current ?? 1,
						ordering: sortBy[0],
					};
					dispatch(getSuperCourseLanguageData(body));
				},
			})
		);
	};

    const fullColumns = [
		{
			field: "name",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Name"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
		},
        {
			field: "created_at",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Created At"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{moment(params?.row?.created_at).format("DD-MM-YYYY") ?? '--'}
					</div>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedCourseLanguage(params.row)
								setOpenEditForm(true)
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "name",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Name"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedCourseLanguage(params.row)
								setOpenEditForm(true)
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForAddForm = {
		open : openAddForm,
		setOpen : setOpenAddForm,
		type : "ADD"
	}

    const propsForEditForm = {
		open : openEditForm,
		setOpen : setOpenEditForm,
		type : "EDIT",
		selectedCourseLanguage
	}

    return (
		<div className="dashboard__main">
			{openAddForm && <AddSuperCourseLanguage {...propsForAddForm}/>}
            {openEditForm && <AddSuperCourseLanguage {...propsForEditForm}/>}
			<div className="dashboard__content bg-light-4" style={{paddingBottom:'0px'}}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("Course Language")}
						{commonDashboardSubHeading("Welcome to the Course language Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
					<div className="col-auto">
					<Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
                            startIcon={<Add/>}
                            onClick={() => setOpenAddForm(true)}
                        >
                            Add Language
                        </Button>
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(97vh - 260px)",
					}}>
					<DataGrid
						rows={CourseLanguages}
						columns={columns}
                        rowHeight={100}
                        showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter/>
		</div>
	);
}
