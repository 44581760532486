import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Spin, Empty } from "antd";
import { getSuperCourseDetailsAction } from "../../../../redux/action/superAdminActions/superCoursesActions";
import LessonItems from './../../../../pages/myDashboard/dashboard/pages/customer/loggedInCourses/LessonItems';
import { LessonOverview } from './../../../../pages/myDashboard/dashboard/pages/customer/loggedInCourses/LessonOverview';
import CourseOverview from "../../../../pages/myDashboard/dashboard/pages/seller/courses/CourseOverview";
import StudentReview from "../../../../pages/myDashboard/dashboard/pages/seller/courses/StudentReview";
import { CustomVideoPlayer } from '../../../../components/common/CustomVideoPlayer'

export default function SuperCourseDetail() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [videoObject, setVideoObject] = useState({
		image: "",
		video: "",
		autoPlay: false,
		isLesson: false,
		lesson: null,
	});
	const slug_name = location.pathname.substring(
		location.pathname.lastIndexOf("/") + 1
	);

	const { data, isLoading } = useSelector(
		(state) => state.superAdmin.superCourses.singleCourseDetail
	);

	useEffect(() => {
		if (data.id) {
			setVideoObject({
				image: data?.course_thumbnail_image ?? "",
				video: data?.course_thumbnail_video ?? "",
				autoPlay: false,
				isLesson: false,
				lesson: null,
			});
		}
	}, [data?.course_thumbnail_image, data?.course_thumbnail_video, data.id]);

	const getData = useCallback(
		(slug) => {
			dispatch(getSuperCourseDetailsAction(slug));
		},
		[dispatch]
	);

	useEffect(() => {
		getData(slug_name);
	}, [slug_name, getData]);

    const fetchDataAgain = ()=>{
        getData(slug_name)
    }

	return (
		<div className="dashboard__main">
			<Spin spinning={isLoading}>
				{data?.id ? (
					<div className="content-wrapper  js-content-wrapper overflow-hidden" style={{padding:'20px'}}>
						<div>
							{data?.chapters ? (
								<LessonItems
									rightPosition
									chapters={data?.chapters}
									setVideoObject={setVideoObject}
									videoObject={videoObject}
									data={data}
                                    showCourseProgress={false}
                                    allowChangeLessonStatus={false}
                                    allow_publish_unPublish={true}
                                    fetchDataAgain={fetchDataAgain}
                                    show_course_views={true}
								/>
							) : null}
						</div>
						<div className="col-auto  pb-50">
							<div className="col-xxl-8 col-xl-7 col-lg-8">
								{videoObject?.video ? (
									<div style={{ marginTop: "70px" }}>
										<CustomVideoPlayer
											{...videoObject}
											key={videoObject?.video}
											seller_email={data?.seller ?? ""}
										/>
									</div>
								) : null}
								{Object.keys(data).length && videoObject.isLesson ? (
									<LessonOverview lesson={videoObject.lesson} />
								) : null}
								{Object.keys(data).length && !videoObject.isLesson ? (
									<CourseOverview data={data} />
								) : null}
								{Object.keys(data).length && !videoObject.isLesson ? (
									<StudentReview
										item={data?.ratings_obj}
										course={data}
										type="trainer"
										isStudentEnrolled
									/>
								) : null}
							</div>
						</div>
					</div>
				) : (
					<div className="content-wrapper  js-content-wrapper overflow-hidden mt-90">
						<Empty description={"Getting Information..."} />
					</div>
				)}
			</Spin>
		</div>
	);
}
