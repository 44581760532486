import React, { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
import {
	Player,
	ControlBar,
	ReplayControl,
	ForwardControl,
	CurrentTimeDisplay,
	TimeDivider,
	PlaybackRateMenuButton,
	VolumeMenuButton,
	BigPlayButton,
	LoadingSpinner,
} from "video-react";
import "video-react/dist/video-react.css";

export const CustomVideoPlayer = (props) => {
	const [videoError, setVideoError] = useState("");
	const videoRef = useRef(null);

	// const {sideBarToggle} = useSelector(state => state.myDashboard.dashboard)

	// const [videoWidth, setVideoWidth] = useState(250);
	// const [videoHeight, setVideoHeight] = useState(300);

	// Function to update video dimensions based on container size
	// const updateVideoSize = () => {
	// 	if (videoRef.current) {
	// 	  const parentWidth = videoRef.current.parentNode.offsetWidth;
	// 	  const parentHeight = parentWidth * (9 / 16); // Aspect ratio 16:9
	// 	  setVideoWidth(parentWidth);
	// 	  setVideoHeight(parentHeight);
	// 	}
	//   };

	//   const updateVideoSizeAccordingToSidebar = () => {
	// 	if (videoRef.current) {
	// 	  const parentWidth = videoRef.current.parentNode.offsetWidth - 300;
	// 	  const parentHeight = parentWidth * (9 / 16); // Aspect ratio 16:9
	// 	  setVideoWidth(parentWidth);
	// 	  setVideoHeight(parentHeight);
	// 	}
	//   };

	// useEffect(() => {
	// 	if(sideBarToggle){
	// 		updateVideoSizeAccordingToSidebar();
	// 		window.addEventListener("resize", updateVideoSizeAccordingToSidebar);
	// 	}else{
	// 		updateVideoSize();
	// 		window.addEventListener("resize", updateVideoSize);
	// 	}
	// 	return ()=>{
	// 		window.removeEventListener("resize", updateVideoSizeAccordingToSidebar);
	// 		window.removeEventListener("resize", updateVideoSize);
	// 	}
	//   }, [sideBarToggle]);
	
	function checkVideoUrl(url) {
		return fetch(url)
			.then((response) => {
				if (response.ok) {
					return true; // URL is accessible
				} else {
					return false; // URL is not accessible
				}
			})
			.catch((error) => {
				return false; // URL is not accessible
			});
	}

	
	useEffect(() => {
		checkVideoUrl(props?.video ?? "").then((isAccessible) => {
		  if (isAccessible) {
			setVideoError("");
		  } else {
			setVideoError(
			  `Video got corrupted. Please inform the Educator ( ${props?.seller_email ?? 'Email not available'} ) to replace it.`
			);
		  }
		});
	  }, [props?.seller_email, props?.video]);

	return (
		<div ref={videoRef}>
			<Player
				autoPlay={props?.autoPlay ?? false}
				poster={props?.image ?? ""}
				src={props?.video ?? ""}
				fluid={true}
				// width={videoWidth} // set fluid false to achieve this...
				// height={videoHeight}
				>
				<BigPlayButton position="center" />
				<LoadingSpinner />
				<ControlBar>
					<ReplayControl seconds={10} order={1.1} />
					<ForwardControl seconds={10} order={1.2} />
					<CurrentTimeDisplay order={4.1} />
					<TimeDivider order={4.2} />
					<PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
					<VolumeMenuButton />
				</ControlBar>
			</Player>
			<span style={{ color: "red" }}>{videoError}</span>
		</div>
	);
};
