import Axios from "axios";
import { GET_MOST_POPULAR_COURSES } from "../commonActions/types";
import { BASE_URL } from "../../../config/config";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";

export function getMostPopularCoursesActions(actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_MOST_POPULAR_COURSES, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: false,
				fields:
					"id,title,seller,level,course_thumbnail_image,is_course_free,course_price,sale_price,seller_obj,seller_obj__user_first_name,seller_obj__user_last_name,seller_obj__user_profile_image,seller_obj__slug_name,slug_name,chapters_count,lesson_count,ratings_obj,enrolled_user_count,course_duration,is_popular_badge,is_new_badge,is_best_seller_badge,course_views,category_obj,category_obj__name",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(
				`${BASE_URL}courses/getPopularCourseList`,
				Config
			);

			onSuccess?.(response?.data);
			dispatch({
				type: GET_MOST_POPULAR_COURSES,
				status: SUCCESS,
				payload: response?.data,
			});
		} catch (error) {
			onError?.(error);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_MOST_POPULAR_COURSES,
				status: ERROR,
				payload: error,
			});
		}
	};
}
