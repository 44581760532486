import React from "react";
import { useDispatch } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { getCourseFromLocalCartAction } from "../../../../../redux/action/cartActions/cartActions";

const sidebarItems = [
    {
        id: 1,
        key: "my-dashboard",
        iconClass: "text-20 icon-discovery",
        text: "My Dashboard",
    },
    {
        id: 2,
        key: 'course-dashboard',
        iconClass: "text-20 icon-play-button",
        text: "My Courses",
    },
    {
        id: 3,
        key: 'user-bookmark-courses',
        iconClass: "text-20 icon-bookmark",
        text: "Bookmarked Courses",
    },
    {
        id: 4,
        key: 'user-settings',
        iconClass: "text-20 icon-person-2",
        text: "Settings",
    },
    {
        id: 8,
        iconClass: "text-20 icon-power",
        key: "logout",
        text: "Logout",
    },
];

export default function CustomerSidebar() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleCloseSideBarForMobile = () => {
        if (window.innerWidth < 600) {
            document
                .getElementById("dashboardOpenClose")
                .classList.add("-is-sidebar-hidden");
        }
    };

    let pathSegments = pathname.split("/");
    let lastPathValue = pathSegments[pathSegments.length - 1];


    const handleSideBarClicked = (key) => {
        switch (key) {
            case "logout" : {
                localStorage.removeItem('beauty_key');
                localStorage.removeItem('beauty_cart_items');
                localStorage.removeItem('beauty_seller');
				localStorage.removeItem('beauty_buyer');
				localStorage.removeItem('loggedIn_user');
                dispatch(getCourseFromLocalCartAction())
                window.location.replace('/')
                break;
            }
            case "my-dashboard" : {
                navigate('')
                handleCloseSideBarForMobile()
                break;
            }
            case "course-dashboard" :
            case "user-bookmark-courses":
            case "user-settings":{
                navigate(key)
                handleCloseSideBarForMobile()
                break;
            }
            // case "courses": {
            //     navigate('courses')
            //     break;
            // }
            default:
                return
        }
    }
    return (
        <div className="sidebar -dashboard">
            {sidebarItems.map((elm, i) => (
                <div
                    key={i}
                    className={`sidebar__item   ${
                        lastPathValue === elm.key ? "-is-active" : ""
                    } `}
                    onClick={() => handleSideBarClicked(elm.key)}
                    style={{cursor: 'pointer'}}
                >
                    <i className={`${elm.iconClass} mr-15`}></i>
                    {elm.text}
                </div>
            ))}
        </div>
    );
}
