import gsap from "gsap";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import ShapeRendering from "../../../svg/shape-rendering";
import heroImage1 from '../../../images/homepage/heroImages/heroImage1.png'
import heroImage2 from '../../../images/homepage/heroImages/heroImage2.png'
import heroImage3 from '../../../images/homepage/heroImages/heroImage3.png'
import heroIcon1 from '../../../images/homepage/heroImages/heroIcon1.png'
import heroIcon2 from '../../../images/homepage/heroImages/heroIcon2.png'

const masthead_info = [
	{
		id: 1,
		icon: "https://educrat-react.vercel.app/assets/img/masthead/icons/1.svg",
		text: "Over 10,000 students",
	},
	{
		id: 2,
		icon: "https://educrat-react.vercel.app/assets/img/masthead/icons/2.svg",
		text: "100+ Categories",
	},
	{
		id: 3,
		icon: "https://educrat-react.vercel.app/assets/img/masthead/icons/3.svg",
		text: "Learn anytime, anywhere.",
	},
];

const hero_content = {
	// title: "Learn New Skills Online with Top",
	// text_underline: "Educators",
	title: "All The Beauty Education, Community and Events In",
	text_underline: "One Place",
	info_hero: (
		<>
			{/* Build skills with courses, certificates, and degrees online from
            <br/> world-class universities and companies. */}
			Experience the transformative power of our platform, providing
			unparalleled access to top-tier education and unparalleled opportunities
			for growth and advancement in the beauty industry.
		</>
	),
	starts: [
		"icon-star text-yellow-1 text-11",
		"icon-star text-yellow-1 text-11",
		"icon-star text-yellow-1 text-11",
		"icon-star text-yellow-1 text-11",
		"icon-star text-yellow-1 text-11",
	],
};
const { title, text_underline, info_hero } = hero_content;

const HomePageHero = () => {
	useEffect(() => {
		const parallaxIt = () => {
			const target = document.querySelectorAll(".js-mouse-move-container");

			target.forEach((container) => {
				const targets = container.querySelectorAll(".js-mouse-move");

				targets.forEach((el) => {
					const movement = el.getAttribute("data-move");

					document.addEventListener("mousemove", (e) => {
						const relX = e.pageX - container.offsetLeft;
						const relY = e.pageY - container.offsetTop;

						gsap.to(el, {
							x:
								((relX - container.offsetWidth / 2) / container.offsetWidth) *
								Number(movement),
							y:
								((relY - container.offsetHeight / 2) / container.offsetHeight) *
								Number(movement),
							duration: 0.2,
						});
					});
				});
			});
		};

		parallaxIt();
	}, []);

	return (
		<>
			<section className="masthead -type-1 js-mouse-move-container">
				<div className="masthead__bg">
					<img
						alt={"hero-pic"}
						src={
							"https://educrat-react.vercel.app/assets/img/home-1/hero/bg.png"
						}
					/>
				</div>

				<div className="container">
					<div className="row y-gap-30 justify-between items-end">
						<div className="col-xl-6 col-lg-6 col-sm-10">
							<div
								className="masthead__content"
								data-aos="fade-up"
								data-aos-delay="500">
								<h1 className="masthead__title lh-14">
									{title}{" "}
									<span className="text-green-1 underline">
										{text_underline}
									</span>
								</h1>
								<p
									data-aos="fade-up"
									data-aos-duration="100"
									className="masthead__text">
									{info_hero}
								</p>
								<div
									data-aos="fade-up"
									data-aos-duration="200"
									className="masthead__buttons row x-gap-10 y-gap-10">
									<div className="col-12 col-sm-auto">
										<Link
											data-barba
											to="/signup"
											className="button -md -purple-1 text-white">
											Start Learning
										</Link>
									</div>
									<div className="col-12 col-sm-auto">
										<Link
											data-barba
											to="/trainer-sign-up"
											className="button -md -outline-green-1 text-green-1">
											Become an Educator
										</Link>
									</div>
								</div>
								<div
									data-aos="fade-up"
									data-aos-duration="300"
									className="masthead-info row y-gap-15 sm:d-none">
									{masthead_info.map((item, i) => (
										<div
											key={i}
											className="masthead-info__item d-flex items-center text-white">
											<div className="masthead-info__icon mr-10">
												<img alt="icon" src={item.icon} />
											</div>
											<div className="masthead-info__title lh-1">
												{item.text}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>

						<div
							className="col-xl-6 col-lg-6"
							data-aos="fade-up"
							data-aos-delay="700">
							<div className="masthead-image">
								<div className="masthead-image__el1">
									<img
										alt="masthead-first"
										className="js-mouse-move"
										data-move="40"
										style={{ objectFit: "cover" }}
										// src={
										// 	"https://educrat-react.vercel.app/assets/img/masthead/1.png"
										// }
										src={heroImage3}
									/>
									<div
										data-move="30"
										className="lg:d-none img-el -w-250 px-30 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
										<div className="size-60 d-flex justify-center items-center bg-red-2 rounded-full">
											<img
												alt="masthead_icon"
												// src={
												// 	"https://educrat-react.vercel.app/assets/img/masthead/1.svg"
												// }
												src={heroIcon2}
												height={40}
												width={40}
											/>
										</div>
										<div className="ml-20">
											<div className="text-orange-1 text-16 fw-500 lh-1">
												1000+ Courses
											</div>
											<div className="mt-3 lh-13" style={{ color: "black" }}>
												Explore Limitless Learning Options.
											</div>
										</div>
									</div>
								</div>

								<div className="masthead-image__el2">
									<img
										alt="masthead-second"
										className="js-mouse-move"
										data-move="70"
										// src={
										// 	"https://educrat-react.vercel.app/assets/img/masthead/2.png"
										// }
										src={heroImage1}
										style={{ objectFit: "cover" }}
									/>
									<div
										data-move="60"
										className="lg:d-none img-el -w-320 px-20 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
										<img
											// src={
											// 	"https://educrat-react.vercel.app/assets/img/masthead/4.png"
											// }
											src={heroIcon1}
											alt="icon"
										/>
										<div className="ml-20">
											<div className="text-dark-1 text-16 fw-500 lh-1">
												Jessica
											</div>
											<div className="mt-0" style={{ fontSize: "10px" }}>
												Just Now
											</div>
											<div className="x-gap-5 mt-3 lh-13" style={{color:'black'}}>
												Hey <span className="text-purple-4">@Sophia</span>, thanks for the wonderful course!
											</div>
										</div>
									</div>
								</div>

								<div className="masthead-image__el3">
									<img
										className="js-mouse-move"
										data-move="40"
										// src={
										// 	"https://educrat-react.vercel.app/assets/img/masthead/3.png"
										// }
										src={heroImage2}
										style={{ objectFit: "cover" }}
										alt="masthead-third"
									/>
									<div
										data-move="30"
										className="shadow-4 img-el -w-320 px-30 py-20 d-flex items-center bg-white rounded-8 js-mouse-move">
										<div className="img-el__side">
											<div className="size-50 d-flex justify-center items-center bg-purple-1 rounded-full">
												<img
													style={{ objectFit: "cover" }}
													src={
														"https://educrat-react.vercel.app/assets/img/masthead/2.svg"
													}
													alt="icon"
												/>
											</div>
										</div>
										<div className="">
											<div className="text-purple-1 text-16 fw-500 lh-1">
												Congratulations!
											</div>
											<div className="mt-3 lh-13" style={{ color: "black" }}>
												Your success story starts now with your certificate.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* animated shape start */}
				<ShapeRendering />
				{/* animated shape end */}
			</section>
		</>
	);
};

export default HomePageHero;
