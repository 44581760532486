import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getTrainerDetailsAction, updateTrainerDetailsAction } from "../../../../../../redux/action/trainerActions/trainerActions";
import { useForm } from "react-hook-form";
import { getMediaUploadLink } from "../../../../../../redux/action/myDashboardActions/coursesActions";
import { PROFILE_AWS_STARTING_PATH } from "../../../../../../config/config";
import { Avatar } from '@mui/material';

export default function EditSellerProfile({ activeTab }) {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [previewImage, setPreviewImage] = useState("");
	const [selectedImage, setSelectedImage] = useState("");
	const [imageUploadLoading, setImageUploadLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const { data: instructorDetails, isLoading } = useSelector(
		(state) => state.trainers.singleTrainer
	);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			first_name: "",
			last_name: "",
			phone: "",
			email: "",
			profile_image: "",
			gender: "",
			dob: "",
			description: "",
		},
	});

	useEffect(() => {
		setValue("first_name", instructorDetails?.user_first_name ?? "");
		setValue("last_name", instructorDetails?.user_last_name ?? "");
		setValue("phone", instructorDetails?.user_phone ?? "");
		setValue("email", instructorDetails?.user_email ?? "");
		setValue("gender", instructorDetails?.user_gender ?? "");
		setValue("dob", instructorDetails?.user_dob ?? "");
		setValue("description", instructorDetails?.description ?? "");
		setPreviewImage(instructorDetails?.user_profile_image ?? "");
	}, [instructorDetails, setValue]);

	const isImagePresent = previewImage.includes("amazonaws");

	const handleImageChange = (event) => {
		if (event.target.files[0]) {
			setSelectedImage(event.target.files[0]);
			const imageUrl = URL.createObjectURL(event.target.files[0]);
			setPreviewImage(imageUrl);
		}
	};

	const updateData = (body) => {
		dispatch(
			updateTrainerDetailsAction(body, instructorDetails.slug_name, {
				onSuccess: (success) => {
					setLoading(false);
					setImageUploadLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					let newData ={
						...instructorDetails,
						...success.data.user,
						slug_name : success.data.slug_name
					}
					localStorage.setItem('loggedIn_user',JSON.stringify(newData));
					window.dispatchEvent(new Event("storage"));
					dispatch(getTrainerDetailsAction(success.data.slug_name))
				},
				onError: (error) => {
					setLoading(false);
					setImageUploadLoading(false);
					let errorMessage = String(error?.message ?? 'Something went wrong');
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
			})
		);
	};

	// Function to generate a random string
	function generateRandomString(length) {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	// Function to generate a unique filename
	function generateUniqueFilename() {
		const timestamp = new Date().getTime(); // Current timestamp
		const randomString = generateRandomString(8); // Generate a random string of length 8
		return `${timestamp}_${randomString}`;
	}

	const uploadFilesOnStorage = async (url, file, fileName, pre_body) => {
		if (file) {
			setImageUploadLoading(true);
			const renamedFile = new File([file], fileName, { type: file.type });

			try {
				await fetch(url, {
					method: "PUT",
					body: renamedFile,
				});
				let body = {
					...pre_body,
					profile_image: `${PROFILE_AWS_STARTING_PATH}${fileName}`,
				};
				updateData(body);
			} catch (error) {
				enqueueSnackbar(error, { variant: "error" });
				setImageUploadLoading(false);
			}
		}
	};

	const onSubmitBasicDetails = (e) => {
		let body = {
			first_name: e?.first_name ? e.first_name : null,
			last_name: e?.last_name ? e.last_name : null,
			phone: e?.phone ? e.phone : null,
			email: e?.email ? e.email : null,
			gender: e?.gender ? e.gender : null,
			dob: e?.dob ? e.dob : null,
			description: e?.description ? e.description : null,
		};
		setLoading(true);
		if (isImagePresent) {
			updateData(body);
		} else {
			if (selectedImage) {
				setImageUploadLoading(true);
				const ImageExtension = selectedImage.name.split(".").pop();
				const imageName = generateUniqueFilename() + "." + ImageExtension;

				dispatch(
					getMediaUploadLink(`${PROFILE_AWS_STARTING_PATH}${imageName}`, {
						onLoading: (loading) => {
							setImageUploadLoading(true);
						},
						onError: (error) => {
							setImageUploadLoading(false);
							let errorMessage = String(error?.message ?? 'Something went wrong');
							enqueueSnackbar(errorMessage, { variant: "error" });
						},
						onSuccess: (success) => {
							uploadFilesOnStorage(
								success?.data?.signed_url,
								selectedImage,
								imageName,
								body
							);
						},
					})
				);
			} else {
				updateData(body);
			}
		}
	};

	const handleRemoveProfile = ()=>{
		setPreviewImage('');
		setSelectedImage('')
		document.getElementById("sellerProfile1").value = "";
		const body ={
			profile_image : null
		}
		dispatch(
			updateTrainerDetailsAction(body, instructorDetails.slug_name, {
				onSuccess: (success) => {
					setLoading(false);
					setImageUploadLoading(false);
					enqueueSnackbar('Image Removed Successfully...', { variant: "success" });
					let newData ={
						...instructorDetails,
						...success.data.user,
						slug_name : success.data.slug_name
					}
					localStorage.setItem('loggedIn_user',JSON.stringify(newData));
					window.dispatchEvent(new Event("storage"));
					dispatch(getTrainerDetailsAction(success.data.slug_name))
				},
				onError: (error) => {
					setLoading(false);
					setImageUploadLoading(false);
					let errorMessage = String(error?.message ?? 'Something went wrong');
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
			})
		);
	}

	return (
		<div
			className={`tabs__pane -tab-item-1 ${
				activeTab === 1 ? "is-active" : ""
			} `}>
			<div className="row y-gap-20 x-gap-20 items-center">
				<label
					className="col-auto"
					htmlFor="imageUpload"
					style={
						previewImage
							? {}
							: { backgroundColor: "#f2f3f4", width: 100, height: 100, borderRadius:'50%' }
					}>
					{previewImage && (
						<Avatar
							// className="size-100"
							sx={{ width: 100, height: 100 }}
							src={previewImage}
							alt={previewImage ? "image" : ""}
						/>
					)}
				</label>

				<div className="col-auto">
					<div className="text-16 fw-500 text-dark-1">Your avatar</div>
					<div className="text-14 lh-1 mt-10">
						PNG or JPG no bigger than 800px wide and tall.
					</div>

					<div className="d-flex x-gap-10 y-gap-10 flex-wrap pt-15">
						<div>
							<div className="d-flex justify-center items-center size-40 rounded-8 bg-light-3">
								<label
									style={{ cursor: "pointer" }}
									htmlFor="sellerProfile1"
									className="icon-cloud text-16"></label>
								<input
									required
									id="sellerProfile1"
									type="file"
									accept="image/*"
									onChange={handleImageChange}
									style={{ display: "none" }}
								/>
							</div>
						</div>
						<div>
							<div
								style={{ cursor: "pointer" }}
								onClick={() => handleRemoveProfile()}
								className="d-flex justify-center items-center size-40 rounded-8 bg-light-3">
								<div className="icon-bin text-16"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="border-top-light pt-30 mt-30">
				<form
					onSubmit={handleSubmit(onSubmitBasicDetails)}
					className="contact-form row y-gap-30">
					<div className="col-md-6">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							First Name
						</label>

						<input
							{...register("first_name", { required: true })}
							type="text"
							placeholder="First Name"
						/>
						{errors.first_name && (
							<span className="error-message">First Name is required.</span>
						)}
					</div>

					<div className="col-md-6">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Last Name
						</label>

						<input
							{...register("last_name", { required: true })}
							type="text"
							placeholder="Last Name"
						/>
						{errors.last_name && (
							<span className="error-message">Last Name is required.</span>
						)}
					</div>

					<div className="col-md-6">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Phone
						</label>

						<input {...register("phone")} type="text" placeholder="Phone" />
					</div>

					<div className="col-md-6">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Email{" "}
							<span className="text-14 lh-1 fw-500 text-light-1 mb-10">
								( Readonly )
							</span>
						</label>
						<input
							{...register("email")}
							required
							type="email"
							placeholder="Email"
							readOnly
						/>
					</div>

					{/* <div className="col-md-6">
						<label
							{...register("dob")}
							className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Date of Birth
						</label>

						<input required type="text" placeholder="Date Of Birth" />
					</div> */}

					{/* <div className="col-md-6">
						<label
							{...register("gender")}
							className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Gender
						</label>

						<input required type="text" placeholder="Gender" />
					</div> */}

					<div className="col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Personal info
						</label>

						<textarea
							{...register("description")}
							required
							placeholder="Text..."
							rows="7"></textarea>
					</div>

					<div className="col-12">
						<button
							className="button -md -purple-1 text-white"
							type="submit"
							onClick={handleSubmit(onSubmitBasicDetails)}
							disabled={isLoading || imageUploadLoading || loading}>
							{isLoading || imageUploadLoading || loading
								? "Loading..."
								: "Update Profile"}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
