import React, { useEffect } from 'react';
import {Route, Routes} from "react-router-dom";
import PageNotFound from '../others/PageNotFound';
import Dashboard from './dashboard/Dashboard';

function MyDashboard() {
    useEffect(()=>{
        if(!localStorage.getItem('beauty_key')){
            window.location.replace('/')
        }
    },[])
    return (
        <>
            <Routes>
                <Route path="/*" element={<Dashboard />} />
                {/*Page Not Found*/}
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </>
    )
}

export default MyDashboard