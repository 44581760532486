import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Spin } from "antd";
import { getTrainerList } from "../../redux/action/trainerActions/trainerActions";
import { Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Instructors(props) {
	const { backgroundColor } = props;
	const dispatch = useDispatch();
	const imageRefs = useRef([]);

	const ImageMaxHeight = "330px";

	const {
		data: trainers,
		isSuccess,
		isLoading,
	} = useSelector((state) => state.trainers.trainers);

	useEffect(() => {
		!isSuccess && dispatch(getTrainerList());
	}, [dispatch, isSuccess]);

	useEffect(() => {
		function adjustImageHeight() {
			imageRefs?.current?.forEach((ref) => {
				const image = ref;
				if (image) {
					const width = image.offsetWidth;
					const height = (width / 300) * 330;
					image.style.height = `${height}px`;
				}
			});
		}
		if (trainers.length > 0) {
			adjustImageHeight();
			const resizeListener = () => adjustImageHeight();
			window.addEventListener("resize", resizeListener);
			return () => {
				window.removeEventListener("resize", resizeListener);
			};
		}
	}, [trainers]);

	return (
		<section
			className={`layout-pt-lg layout-pb-lg ${
				backgroundColor ? backgroundColor : ""
			}`}>
			<div className="container">
				<div className="row y-gap-20 justify-between items-center">
					<div className="col-lg-6">
						<div className="sectionTitle ">
							<h2 className="sectionTitle__title_2 lh-14" data-aos="fade-left">
								Learn from top-notch educators
							</h2>

							<p className="sectionTitle__text " data-aos="fade-left">
								Pro Beauty Port classes are led by industry leaders eager to
								share their tools, techniques, and professional journeys with
								you.
							</p>
						</div>
					</div>

					{trainers.length ? (
						<div className="col-auto" data-aos="fade-left">
							<Link
								to="/instructors"
								className="button -icon -purple-3 text-purple-1">
								View All Educators.
								<i className="icon-arrow-top-right text-13 ml-10"></i>
							</Link>
						</div>
					) : null}
				</div>

				<Spin spinning={isLoading}>
					<div className="row y-gap-30 pt-50">
						{trainers.length ? (
							trainers.slice(0, 4).map((elm, i) => (
								<div
									key={i}
									className="col-lg-3 col-sm-6"
									data-aos="fade-left"
									data-aos-duration={(i + 1) * 500}>
									<div className="teamCard -type-1 -teamCard-hover">
										<div className="teamCard__image">
											<Avatar
												variant="square"
												style={{
													maxHeight: ImageMaxHeight,
													width: "100%",
													objectFit: "cover",
													borderRadius: "5px",
												}}
												src={elm?.image ?? ""}
												alt="team card"
												ref={(el) => (imageRefs.current[i] = el)}
											/>
											<div className="teamCard__socials">
												<div className="d-flex x-gap-20 y-gap-10 justify-center items-center h-100">
													{elm.socialProfile?.map((itm, i) =>
														itm.url ? (
															<Link
																key={i}
																to={itm.url ? itm.url : "#"}
																target="_blank">
																{itm.replaceIcon ? (
																	<FontAwesomeIcon
																		icon={faXTwitter}
																		style={{ color: "white" }}
																	/>
																) : (
																	<i className={`${itm.icon} text-white`}></i>
																)}
															</Link>
														) : null
													)}
												</div>
											</div>
										</div>
										<div className="teamCard__content">
											<h4 className="teamCard__title">
												<Link
													className="linkCustom"
													to={`/instructors/${elm.slug_name}`}>
													{elm.name}
												</Link>
											</h4>
											<p className="teamCard__text">{elm.role}</p>

											<div className="row items-center y-gap-10 x-gap-10 pt-10">
												<div className="col-auto">
													<div className="d-flex items-center">
														<div className="icon-star text-yellow-1 text-11 mr-5"></div>
														<div className="text-14 lh-12 text-yellow-1 fw-500">
															{elm?.ratings?.total_rating ?? 0}
														</div>
													</div>
												</div>

												<div className="col-auto">
													<div className="d-flex items-center">
														<div className="icon-online-learning text-light-1 text-11 mr-5"></div>
														<div className="text-14 lh-12">
															{elm?.student_count ?? 0} Students
														</div>
													</div>
												</div>

												<div className="col-auto">
													<div className="d-flex items-center">
														<div className="icon-play text-light-1 text-11 mr-5"></div>
														<div className="text-14 lh-12">
															{elm?.courses_count ?? 0} Course
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<Empty description={<h5>Educators are not available yet</h5>} />
						)}
					</div>
				</Spin>
				<div className="row justify-center text-center pt-60 lg:pt-40">
					<div className="col-auto">
						<p className="lh-1">
							Want to help people learn, grow and achieve more in life?&nbsp;
							<Link className="text-purple-1 underline" to="/trainer-sign-up">
								Become an Educator.
							</Link>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
