import React from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import HeaderAuth from "../../../components/layout/headers/HeaderAuth";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";

const metadata = {
	title: `Verify Email Message - ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

const ShowVerifyMessagePage = () => {
	const { encrytedEmail } = useParams();
	let decodedEmail;

	try {
		decodedEmail = window.atob(encrytedEmail);
	} catch (e) {
		decodedEmail = "your email address";
	}

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<HeaderAuth />
			<div className="content-wrapper js-content-wrapper">
				<section className="form-page">
					<div
						className="centered-form lg:pt-90"
						style={{
							width: "100%",
							// maxHeight: "80vh",
							display: "flex",
							justifyContent: "center",
							overflow: "scroll",
						}}>
						<div className="form-page__content">
							<div className="container">
								<div className="row justify-center items-center">
									<div className="col-xl-8 col-lg-10">
										<div
											// className="px-50 py-50 md:px-25 md:py-25 bg-white special-box-shadow rounded-16"
											className="px-50 pt-50 md:px-25 md:pt-25 text-center">
											<h4 className="text-24 lh-13">
												Welcome to {COMPANY_NAME}
											</h4>
											<div className="text-center">
												<FontAwesomeIcon
													className="text-purple-1 pt-80 pb-50"
													icon={faEnvelopeOpenText}
													fontSize={"100px"}
												/>
											</div>
											<div className="pt-30">
												<p
													className="text-20 lh-13"
													style={{ fontWeight: 500 }}>
													Verify Your Email Address
												</p>
												<div style={{textAlign:'justify'}}>
												<p className="pt-20">
													Thank you for signing up with {COMPANY_NAME}! To get
													started and access your account, please verify your
													email address.
												</p>
												<p className="py-5">
													We've sent a verification email to {decodedEmail}.
													Please check your inbox (including spam folders) and
													click the verification link to activate your account.
												</p>
												</div>
											</div>

											<div className="lh-12 text-dark-1 fw-500 text-center mt-20">
												<Link to="/login" className="text-purple-1">
													Try Login Again
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default ShowVerifyMessagePage;
