import Axios from "axios";
import {GET_TRAINERS_LIST, GET_TRAINER_COURSES_REVIEWS, GET_TRAINER_DETAIL, UPDATE_TRAINER_DETAIL} from "../commonActions/types";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";

export function getTrainerList(filters={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_TRAINERS_LIST, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: '',
                search: filters?.search ?? "",
				page: filters?.page !== undefined ? filters.page : 1,
                rating : filters?.rating ?? ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}users/getSellerList`, Config);
            const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_TRAINERS_LIST,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_TRAINERS_LIST,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getTrainerDetailsAction(trainerSlug,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_TRAINER_DETAIL, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}users/getSellerDetails/${trainerSlug}/`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_TRAINER_DETAIL,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_TRAINER_DETAIL,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateTrainerDetailsAction(body,trainerSlug, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_TRAINER_DETAIL, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/updateSellerDetails/${trainerSlug}/`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_TRAINER_DETAIL,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: UPDATE_TRAINER_DETAIL,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getTrainerCoursesRatingsAction(body = {}, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_TRAINER_COURSES_REVIEWS, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
				search: body?.search ?? "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(
				`${BASE_URL}courses/getMyReview`,
				Config
			);
			const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
			onSuccess?.(modifiedData);
			dispatch({
				type: GET_TRAINER_COURSES_REVIEWS,
				status: SUCCESS,
				payload: modifiedData,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_TRAINER_COURSES_REVIEWS,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}