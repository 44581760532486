import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// const onRequest = (config) => {
//   return Promise.resolve(config);
// };

// const onRequestError = (error) => {
//   let status = error?.response?.status;
//   if (status === 401 || status === 403) {
//     localStorage.clear();
//     sessionStorage.clear();
//     history.push("/");
//     window.location.reload();
//   }
//   return Promise.reject(error);
// };

// const onResponse = (response) => {
//   return Promise.resolve(response);
// };

export const onResponseError = (error,type = "user") => {
  let status = error?.status_code;
  if (status === 401 || status === 403) {
    localStorage.clear();
    sessionStorage.clear();
    if(type === "super_admin"){
      history.push("/super_admin/login")
    }else{
      history.push("/");
    }
    window.location.reload();
  }
  return;
  // return Promise.reject(error);
};

// export function setupInterceptorsTo(axiosInstance) {
//   axiosInstance.interceptors.request.use(onRequest, onRequestError);
//   axiosInstance.interceptors.response.use(onResponse, onResponseError);
//   return axiosInstance;
// }
