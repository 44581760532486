import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCourseAction,
	getSellerCourseListAction,
} from "../../../../../../redux/action/myDashboardActions/coursesActions";
import { Avatar, Chip, Pagination, useMediaQuery } from "@mui/material";
import CourseDetail from "./CourseDetail";

import { Popconfirm, Spin, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function CourseList() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isMobile = useMediaQuery("(max-width:600px)");
	const isDesktop = useMediaQuery("(min-width:601px)");
	const isTablet = useMediaQuery("(max-width:690px)")

	const {
		data: SellerCourses,
		isLoading,
		meta_data,
	} = useSelector((state) => state.myDashboard.sellerCourses);
	const [selectedCourse, setSelectedCourse] = useState({
		id: 0,
	});
	const [openDetailPage, setOpenDetailPage] = useState(false);
	const [search, setSearch] = useState("");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		let body = {
			page: 1,
		};
		dispatch(getSellerCourseListAction(body));
	}, [dispatch]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value) => {
			let body = {
				search: value,
				page: 1,
			};
			dispatch(getSellerCourseListAction(body));
		}, 500),
		[debounce]
	);

	const handleChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value);
	};

	const getStatusChip = (status) => {
		let chipColor;
		switch (status) {
			case "DRAFT":
				chipColor = "error";
				break;
			case "PUBLISHED":
				chipColor = "success";
				break;
			case "UN_PUBLISHED":
				chipColor = "warning";
				break;
			default:
				return null;
		}
		return (
			<Chip size="small" label={status} variant="outlined" color={chipColor} />
		);
	};

	const handleCourseClicked = (course) => {
		setOpenDetailPage(true);
		setSelectedCourse(course);
	};

	const handleDeleteCourse = (course) => {
		dispatch(
			deleteCourseAction(course.id, {
				onSuccess: (success) => {
					let body = {
						page: meta_data.current,
					};
					dispatch(getSellerCourseListAction(body));
				},
			})
		);
	};

	const handlePageChange = (value) => {
		let body = {
			page: value,
		};
		dispatch(getSellerCourseListAction(body));
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-30 lh-12 fw-700">Courses</h1>
						<div className="mt-10">Welcome to the Courses Section.</div>
					</div>
					<div className={`col-auto ${(isMobile || isTablet) ? 'me-auto' : 'ms-auto'}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
				</div>
				{openDetailPage && (
					<CourseDetail
						open={openDetailPage}
						setOpen={setOpenDetailPage}
						course={selectedCourse}
						type={"trainer"}
					/>
				)}
				{/*Table Starts here */}
				<div className="px-30 py-10 bg-light-7 -dark-bg-dark-2 rounded-8">
					<div className="row x-gap-10">
						{isMobile && (
							<div className="col-lg-12">
								<div className="text-purple-1 text-left">Course Name</div>
							</div>
						)}
						{isDesktop && (
							<div className="d-flex" style={{justifyContent:'space-between'}}>
								<div className="col-5 col-md-5 col-lg-5">
									<div className="text-purple-1 text-left">Course Name</div>
								</div>
								<div className="col-3 col-md-3 col-lg-3">
									<div className="text-purple-1 text-left">
										Category / Sub Category
									</div>
								</div>
								<div className="col-2 col-md-2 col-lg-2">
									<div className="text-purple-1 text-left">Price</div>
								</div>
								<div className="col-1 col-md-1 col-lg-1">
									<div className="text-purple-1 text-left">Status</div>
								</div>
								<div className="col-1 col-md-1 col-lg-1">
									<div className="text-purple-1 text-right">Actions</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<Spin spinning={isLoading}>
					<div
						style={{
							width: "100%",
							overflowY: "scroll",
							height: "calc(100vh - 370px)",
						}}>
						{SellerCourses?.length ? (
							SellerCourses.map((elm, i) => (
								<div key={i} className="px-30 border-bottom-light">
									<div className="row x-gap-10 items-center py-15">
										{isMobile && (
											<>
												<div className="col-lg-11">
													<div className="d-flex items-center">
														<Avatar
															variant="square"
															sx={{ width: 70, height: 50, borderRadius:'5px' }}
															alt="course"
															src={elm.course_thumbnail_image}
														/>
														<div className="ml-10">
															<div
																className="text-purple-1 lh-12 fw-500"
																style={{ cursor: "pointer" }}
																onClick={() => handleCourseClicked(elm)}>
																{elm.title}
															</div>
															<div className="text-14 lh-12 mt-5">
																Chapters : {elm?.chapters_count ?? 0}
															</div>
															<div className="text-14 lh-12 mt-5">
																Lessons : {elm?.lesson_count ?? 0}
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-1">
													<div className="d-flex justify-content-end">
														<div
															className="icon-edit"
															style={{ cursor: "pointer" }}
															onClick={() => {
																if (elm.course_status === "PUBLISHED") {
																	enqueueSnackbar(
																		"To edit this item, it must first be unpublished. Once unpublished, you can make your modifications.",
																		{ variant: "info" }
																	);
																} else {
																	navigate("update-course", {
																		state: elm,
																	});
																}
															}}></div>
														&emsp;
														<Popconfirm
															title="Delete Course"
															description="Are you sure to delete this course?"
															okText="Yes"
															cancelText="No"
															onConfirm={() => handleDeleteCourse(elm)}>
															<div
																className="icon-bin"
																style={{ cursor: "pointer" }}></div>
														</Popconfirm>
													</div>
												</div>
											</>
										)}
										{isDesktop && (
											<div className="d-flex" style={{justifyContent:'space-between'}}>
												<div className="col-5 col-md-5 col-lg-5">
													<div className="d-flex items-center">
														<Avatar
															variant="square"
															sx={{ width: 70, height: 50, borderRadius:'5px' }}
															alt="course"
															src={elm.course_thumbnail_image}
														/>
														<div className="ml-10">
															<div
																className="text-purple-1 lh-1 fw-500"
																style={{ cursor: "pointer" }}
																onClick={() => handleCourseClicked(elm)}>
																{elm.title}
															</div>
															<div className="text-14 lh-1 mt-5">
																Chapters : {elm?.chapters_count ?? 0}
															</div>
															<div className="text-14 lh-1 mt-5">
																Lessons : {elm?.lesson_count ?? 0}
															</div>
														</div>
													</div>
												</div>

												<div className="col-3 col-md-3 col-lg-3">
													<div className="d-flex items-center">
														<div className="ml-10">
															<div>{elm?.category_obj?.name ?? "--"}</div>
															<div className="text-14 lh-12 mt-5">
																{elm?.sub_category_obj?.name ?? "--"}
															</div>
														</div>
													</div>
												</div>

												<div className="col-2 col-md-2 col-lg-2">
													{!elm.is_course_free ? (
														<>
															<div className="text-18 lh-1 text-dark-1 fw-500">
																${elm.sale_price ?? 0}
															</div>
															<div className="lh-1 text-14 line-through">
																${elm.course_price ?? 0}
															</div>
														</>
													) : (
														<>
															<div className="text-24 lh-1 text-dark-1 fw-500">
																Free
															</div>
															<div></div>
														</>
													)}
												</div>
												<div className="col-1 col-md-1 col-lg-1">
													{getStatusChip(elm.course_status)}
												</div>
												<div className="col-1 col-md-1 col-lg-1">
													<div className="d-flex justify-content-end">
														<div
															className="icon-edit"
															style={{ cursor: "pointer" }}
															onClick={() => {
																if (elm.course_status === "PUBLISHED") {
																	enqueueSnackbar(
																		"To edit this item, it must first be unpublished. Once unpublished, you can make your modifications.",
																		{ variant: "info" }
																	);
																} else {
																	navigate("update-course", {
																		state: elm,
																	});
																}
															}}></div>
														&emsp;
														<Popconfirm
															title="Delete Course"
															description="Are you sure to delete this course?"
															okText="Yes"
															cancelText="No"
															onConfirm={() => handleDeleteCourse(elm)}>
															<div
																className="icon-bin"
																style={{ cursor: "pointer" }}></div>
														</Popconfirm>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							))
						) : (
							<div className="mt-50">
								<Empty description={"Courses not added yet..."} />
							</div>
						)}
					</div>
				</Spin>

				{/* Table ends here  */}
			</div>
			<div className="pagination -buttons d-flex justify-content-center mb-30">
				{SellerCourses?.length ? (
					<Pagination
						count={meta_data?.total_pages ?? 0}
						page={meta_data?.current ?? 1}
						onChange={(event, value) => handlePageChange(value)}
						size="large"
						sx={{
							ul: {
								"& .Mui-selected": {
									background: "#6440FB",
									color: "white",
								},
							},
						}}
						disabled={isLoading}
					/>
				) : null}
			</div>

			<LoggedInFooter/>
		</div>
	);
}
