import React, {useCallback, useEffect, useState} from "react";
import {Button, Pagination, Typography, useMediaQuery} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import {Add} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import { deleteSuperSubCourseCategoryAction, getSuperSubCategories } from "../../../../redux/action/superAdminActions/superSubCatergoryActions";
import AddSuperSubCategory from "./AddSuperSubCategory";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";
import { commonDashboardHeading, commonDashboardSubHeading } from "../../../../components/common/DashboardElements";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { getSuperCategories } from "../../../../redux/action/superAdminActions/superCategoryActions";
import SuperSubCategoriesFilterAndSortingArea from "./SuperSubCategoriesFilterAndSortingArea";
import { debounce } from "lodash";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperSubCategories() {
    const dispatch = useDispatch()
    const [openAddForm, setOpenAddForm] = useState(false)
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState([]);
    const [category, setCategory] = useState(0)

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

    const { enqueueSnackbar } = useSnackbar();

    const {data: SubCategories, isLoading, isSuccess, meta_data } = useSelector(state => state.superAdmin.superSubCategories)

    const {
		data: Categories,
		isLoading: categoryLoading,
	} = useSelector((state) => state.superAdmin.superCategories);

    const bodyForGetApi = () => {
		let body = {
			page: meta_data?.current ?? 1,
			search: search,
			ordering: sortBy[0],
            category: category
		};
		return body;
	};

    useEffect(() => {
        let body = bodyForGetApi()
        !isSuccess && dispatch(getSuperSubCategories(body))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSuccess])

    useEffect(() => {
		dispatch(getSuperCategories());
	}, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const handleDelete = (categoryId) => {
		dispatch(
			deleteSuperSubCourseCategoryAction(categoryId, {
				onSuccess: (success) => {
					enqueueSnackbar("Sub-Category Deleted Successfully...", {
						variant: "success",
					});
                    let body = bodyForGetApi()
					dispatch(getSuperSubCategories(body));
				},
			})
		);
	};

    // eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value, sortBy, category) => {
			let body = {
				search: value,
				page: 1,
				ordering: sortBy[0],
                category
			};
			dispatch(getSuperSubCategories(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value, sortBy, category);
	};

    const handleApplyFilters = () => { 
        let tempBody = bodyForGetApi()
		const body = {
            ...tempBody,
			page: 1,
		};

		dispatch(getSuperSubCategories(body));
	};

	const handleResetFilters = () => {
		setCategory(0);
		let updatedBody = {
			page: 1,
			search: search,
			ordering: sortBy[0],
		};
		dispatch(getSuperSubCategories(updatedBody));
	};

	const handleSorting = (item) => {
        let tempBody = bodyForGetApi()
		const body = {
            ...tempBody,
			ordering: item,
		};
		dispatch(getSuperSubCategories(body));
	};

    const handlePageChange = (value) => {
		let tempBody = bodyForGetApi()
		let body = {
			...tempBody,
			page: value,
		};
		dispatch(getSuperSubCategories(body));
	};

    const fullColumns = [
        {
            field: 'name',
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {'Sub Category '}
                </Typography>
            ),
            renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
        },
        {
            field: 'category_name',
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {'Category Name'}
                </Typography>
            ),
        },
        {
			field: "number_of_course",
			width: 50,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Total Courses"}</Typography>,
            renderCell: (params) => {
				return (
					<div>
						{params?.row?.available_course_count?? 0}
					</div>
				);
			},
		},
        {
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedSubCategory(params.row)
								setOpenEditForm(true)
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this Sub-category?"
							description={
								<div>
									<p>All the related details will be deleted.</p>
									{/* <span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
									<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b> */}
								</div>
							}
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
    ];

    // Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
            field: 'name',
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {'Sub Category '}
                </Typography>
            ),
            renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
        },
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedSubCategory(params.row)
								setOpenEditForm(true)
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this Sub-category?"
							description={
								<div>
									<p>All the related details will be deleted.</p>
									{/* <span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
									<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b> */}
								</div>
							}
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

    const propsForAddForm = {
		open : openAddForm,
		setOpen : setOpenAddForm,
		type : "ADD",
        bodyForGetApi
	}

    const propsForEditForm = {
		open : openEditForm,
		setOpen : setOpenEditForm,
		type : "EDIT",
		selectedSubCategory,
        bodyForGetApi
	}

    const propsForFilterAndSortingComponent = {
		loading: isLoading || categoryLoading,
		sortBy,
		setSortBy,
        handleApplyFilters,
		handleResetFilters,
		handleSorting,
        CategoryList: Categories,
        filterCategory: category,
		setFilterCategory: setCategory,
	};

    return (
        <div className="dashboard__main">
            {openAddForm && <AddSuperSubCategory {...propsForAddForm}/>}
            {openEditForm && <AddSuperSubCategory {...propsForEditForm}/>}
            <div className="dashboard__content bg-light-4" style={{paddingBottom:'0px'}}>
                <div className="row pb-30">
                    <div className="col-auto">
                    {commonDashboardHeading("Sub-Categories")}
                    {commonDashboardSubHeading("Welcome to the Sub-Categories Section.")}
                    </div>
                    <div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
                    <div className="col-auto">
                        <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
                            startIcon={<Add/>}
                            onClick={() => setOpenAddForm(true)}
                        >
                            Add Sub-Category
                        </Button>
                    </div>
                </div>

                <SuperSubCategoriesFilterAndSortingArea
					{...propsForFilterAndSortingComponent}
				/>

                {/*Table Starts here */}
                <div style={{
							width: "100%",
							overflowY: "scroll",
							height: "calc(98vh - 300px)",
						}}>
                    <DataGrid
                        rows={SubCategories}
                        columns={columns}
                        showCellVerticalBorder
                        disableColumnMenu
                        disableRowSelectionOnClick={true}
                        disableColumnSelector
                        disableFocus
                        loading={isLoading}
                        sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
                        hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
                    />
                </div>
                {/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
            </div>
            <LoggedInFooter/>
        </div>
    );
}
