import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { GET_MOST_POPULAR_COURSES } from "../../action/commonActions/types";

const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    isError: false
};

// {
//     id: 1,
//     imageSrc: "https://educrat-react.vercel.app/assets/img/coursesCards/6.png",
//     authorImageSrc: "https://educrat-react.vercel.app/assets/img/general/avatar-1.png",
//     title: "Learn Figma - UI/UX Design Essential Training",
//     rating: 4.3,
//     ratingCount: 1991,
//     lessonCount: 6,
//     duration: 1320,
//     level: "Beginner",
//     originalPrice: 199,
//     discountedPrice: 79,
//     paid: true,
//     category: "Design",
//     state: "Popular",
//     languange: "French",
//     authorName: "Jane Cooper",
//     viewStatus: "Good",
//     difficulty: "Easy",
//     desc: "Introductory course on web hosting, domain registration, and how you can easily publish and edit your website online.",
// },
const popularCoursesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MOST_POPULAR_COURSES : {
            switch (action.status) {
                case LOADING: {
                    return {
                        ...state,
                        isLoading: true,
                    }
                }
                case SUCCESS: {
                    return {
                        ...state,
                        data: action?.payload?.data ?? [],
                        isSuccess: true,
                        isLoading: false,
                    }
                }
                case ERROR: {
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isSuccess: false
                    }
                }

                default:
                    return state
            }
        }
        default :
            return state;
    }
};
export default popularCoursesReducer;
