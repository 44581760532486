/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import logo1 from '../../images/logos/logo_icon.svg';
import logo2 from '../../images/logos/logo_full.svg';
import super_profile_logo from '../../images/super_admin/profile/super_admin_profile_image.svg'

const sidebarItems = [
    {
        id: 1,
        href: "/dashboard",
        key: 'dashboard',
        disabled: false,
        iconClass: "text-20 icon-discovery",
        text: "Dashboard",
    },
    // {
    //     id: 2,
    //     href: "/dshb-courses",
    //
    //     iconClass: "text-20 icon-play-button",
    //     text: "My Courses",
    // },
    // {
    //     id: 3,
    //     href: "/dshb-bookmarks",
    //     iconClass: "text-20 icon-bookmark",
    //     text: "Bookmarks",
    // },
    // {
    //     id: 4,
    //     href: "/dshb-messages",
    //     iconClass: "text-20 icon-message",
    //     text: "Messages",
    // },
    // {
    //     id: 5,
    //     href: "/dshb-listing",
    //     iconClass: "text-20 icon-list",
    //     text: "Create Course",
    // },
    // {
    //     id: 6,
    //     href: "/dshb-reviews",
    //     iconClass: "text-20 icon-comment",
    //     text: "Reviews",
    // },
    // {
    //     id: 7,
    //     href: "/dshb-settings",
    //     key: 'settings',
    //     disabled:true,
    //     iconClass: "text-20 icon-setting",
    //     text: "Settings",
    // },
    {
        id: 8,
        href: "/",
        key: 'logout',
        disabled: false,
        iconClass: "text-20 icon-power",
        text: "Logout",
    },
];

export default function SuperHeader() {
    // const [messageOpen, setMessageOpen] = useState(false);

    // const [isOnNotification, setIsOnNotification] = useState(false);
    const [isOnProfile, setIsOnProfile] = useState(false);

    // const [documentElement, setDocumentElement] = useState();
    //
    // useEffect(() => {
    //     setDocumentElement(document.documentElement);
    // }, []);

    useEffect(() => {
        if (window.innerWidth < 990) {
            document
                .getElementById("dashboardOpenClose")
                .classList.add("-is-sidebar-hidden");
        }
        const handleResize = () => {
            if (window.innerWidth < 990) {
                document
                    .getElementById("dashboardOpenClose")
                    .classList.add("-is-sidebar-hidden");
            }
        };

        // Add event listener to window resize event
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSideItemsClicked = (key) => {
        switch (key) {
            case "logout": {
                localStorage.removeItem('beauty_super_key');
                window.location.replace('/super_admin/login');
                break;
            }
            default :
                return
        }
    }
    return (
        <>
            <header className="header -dashboard -dark-bg-dark-1 js-header">
                <div className="header__container py-20 px-30">
                    <div className="row justify-between items-center">
                        <div className="col-auto">
                            <div className="d-flex items-center">
                                <div className="header__explore text-dark-1">
                                    <button
                                        onClick={() => {
                                            document
                                                .getElementById("dashboardOpenClose")
                                                .classList.toggle("-is-sidebar-hidden");
                                        }}
                                        className="d-flex items-center js-dashboard-home-9-sidebar-toggle"
                                    >
                                        <i className="icon -dark-text-white icon-explore"></i>
                                    </button>
                                </div>

                                <div className="header__logo ml-30 md:ml-20">
                                    <Link data-barba to="/">
                                        <img
                                            className="-light-d-none"
                                            src={logo1}
                                            alt="logo-1"
                                        />
                                        <img
                                            className="-dark-d-none"
                                            src={logo2}
                                            alt="logo-2"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="d-flex items-center">
                                <div className="text-white d-flex items-center lg:d-none mr-15">
                                    {/*<div className="dropdown bg-transparent px-0 py-0">*/}
                                    {/*    <div className="d-flex items-center text-14 text-dark-1">*/}
                                    {/*        All Pages*/}
                                    {/*        <i className="text-9 icon-chevron-down ml-10"></i>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="dropdown__item -dark-bg-dark-2 -dark-border-white-10">*/}
                                    {/*        <div className="text-14 y-gap-15">*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="#"*/}
                                    {/*                    className="d-block text-dark-1">*/}
                                    {/*                    Dashboard*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="dshb-courses"*/}
                                    {/*                    className="d-block text-dark-1"*/}
                                    {/*                >*/}
                                    {/*                    My Courses*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="dshb-bookmarks"*/}
                                    {/*                    className="d-block text-dark-1"*/}
                                    {/*                >*/}
                                    {/*                    Bookmarks*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="dshb-listing"*/}
                                    {/*                    className="d-block text-dark-1"*/}
                                    {/*                >*/}
                                    {/*                    Add Listing*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="dshb-reviews"*/}
                                    {/*                    className="d-block text-dark-1"*/}
                                    {/*                >*/}
                                    {/*                    Reviews*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*            <div>*/}
                                    {/*                <Link*/}
                                    {/*                    to="dshb-settings"*/}
                                    {/*                    className="d-block text-dark-1"*/}
                                    {/*                >*/}
                                    {/*                    Settings*/}
                                    {/*                </Link>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<MyCourses />*/}
                                </div>

                                <div className="d-flex items-center sm:d-none">

                                    {/*<div*/}
                                    {/*    className="relative"*/}
                                    {/*    onClick={() => setMessageOpen(true)}*/}
                                    {/*>*/}
                                    {/*    <a*/}
                                    {/*        href="#"*/}
                                    {/*        className="d-flex items-center text-light-1 justify-center size-50 rounded-16 -hover-dshb-header-light"*/}
                                    {/*        data-el-toggle=".js-msg-toggle"*/}
                                    {/*    >*/}
                                    {/*        <i className="text-24 icon icon-email"></i>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}

                                    {/* <div
                                        className="relative"
                                        onClick={() => setIsOnNotification((pre) => !pre)}
                                    >
                                        <a
                                            href="#"
                                            className="d-flex items-center text-light-1 justify-center size-50 rounded-16 -hover-dshb-header-light"
                                            data-el-toggle=".js-notif-toggle"
                                        >
                                            <i className="text-24 icon icon-notification"></i>
                                        </a>

                                        <div
                                            className={`toggle-element js-notif-toggle  ${
                                                isOnNotification ? "-is-el-visible" : ""
                                            } -`}
                                        >
                                            <div
                                                className="toggle-bottom -notifications bg-white shadow-4 border-light rounded-8 mt-10">
                                                <div className="py-30 px-30">
                                                    <div className="y-gap-40">
                                                        Working on it...
                                                        {notifications.map((elm, i) => (
                                                            <div
                                                                key={i}
                                                                className={`d-flex items-center  ${
                                                                    i !== 0
                                                                        ? "border-top-light -dark-border-top-light-5"
                                                                        : ""
                                                                } `}
                                                            >
                                                                <div className="shrink-0">
                                                                    <img src={elm.imageSrc} alt="image" />
                                                                </div>
                                                                <div className="ml-12">
                                                                    <h4 className="text-15 lh-1 fw-500 -dark-text-dark-1">
                                                                        {elm.heading}
                                                                    </h4>
                                                                    <div className="text-13 lh-1 mt-10">
                                                                        {elm.time} Hours Ago
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div
                                    className="relative d-flex items-center ml-10 sm:d-none"
                                    onClick={() => setIsOnProfile((pre) => !pre)}
                                >
                                    <a href="#" data-el-toggle=".js-profile-toggle">
                                        <img
                                            className="size-50"
                                            src={super_profile_logo}
                                            alt="profile"
                                        />
                                    </a>

                                    <div
                                        className={`toggle-element js-profile-toggle ${
                                            isOnProfile ? "-is-el-visible" : ""
                                        } -`}
                                    >
                                        <div
                                            className="toggle-bottom -profile bg-white shadow-4 border-light rounded-8 mt-10">
                                            <div className="px-30 py-30">
                                                <div className="sidebar -dashboard">
                                                    {sidebarItems.map((elm, i) => (
                                                        <div
                                                            key={i}
                                                            className={`sidebar__item ${
                                                                elm.id === 1 ? "-is-active -dark-bg-dark-2" : ""
                                                            }`}
                                                        >
                                                            <Link
                                                                to={''}
                                                                onClick={() => handleSideItemsClicked(elm.key)}
                                                                // href={elm.href}
                                                                className="d-flex items-center text-17 lh-1 fw-500 "
                                                            >
                                                                <i className={elm.iconClass}></i>
                                                                {elm.text}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Messages setMessageOpen={setMessageOpen} messageOpen={messageOpen} />*/}
            </header>
        </>
    );
}
