import {Button} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddChapters from "./AddChapters";
import { useDispatch, useSelector } from "react-redux";
import { deleteChapterAction, getChaptersListAction } from "../../../../../../redux/action/myDashboardActions/chapterActions";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";
import Lessons from "./Lessons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCoursePreviewAction } from "../../../../../../redux/action/myDashboardActions/coursesActions";

export default function Curriculum(props) {
	const { courseId,course_slug_name } = props;
	const dispatch = useDispatch();
	const [openAddForm, setOpenAddForm] = useState(false);
	const [activeItemId, setActiveItemId] = useState(0);
	const [openEditChapterForm, setOpenEditChapterForm] = useState(false);
	const [selectedChapter, setSelectedChapter] = useState({
		id: "",
	});

	const {enqueueSnackbar} = useSnackbar();

	const { data: SellerChapters } = useSelector(
		(state) => state.myDashboard.sellerChapters
	);

	useEffect(() => {
		dispatch(getChaptersListAction(courseId));
	}, [courseId, dispatch]);

	const handleDeleteChapter =(item)=>{
        dispatch(deleteChapterAction(item.id, {
            onLoading: (loading) => {
                enqueueSnackbar('Loading...', {variant: 'info'});
            },
            onError: (error) => {
                let errorMessage = String(error?.message);
                enqueueSnackbar(errorMessage, {variant: 'error'});
            },
            onSuccess: (success) => {
                enqueueSnackbar(success.message[0], {variant: 'success'});
                dispatch(getChaptersListAction(courseId))
				props?.handleShowAutoText?.()
				course_slug_name && dispatch(getCoursePreviewAction(course_slug_name))
            }
        }))
    }

	return (
		<div className="col-12">
			{openAddForm && (
				<AddChapters
					open={openAddForm}
					setOpen={setOpenAddForm}
					courseId={courseId}
					course_slug_name={course_slug_name}
					handleShowAutoText={props?.handleShowAutoText}
				/>
			)}
			{openEditChapterForm && selectedChapter.id && (
					<AddChapters
						open={openEditChapterForm}
						setOpen={setOpenEditChapterForm}
						courseId={selectedChapter.course}
						type={"update"}
						updateChapter={selectedChapter}
						course_slug_name={course_slug_name}
						handleShowAutoText={props?.handleShowAutoText}
					/>
				)}
			<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
				<div className="d-flex items-center py-20 px-30 border-bottom-light">
					<h2 className="text-17 lh-1 fw-500">Chapters</h2>
					<div className="col-auto ms-auto">
						<Button
							variant="outlined"
							size="large"
							className="text-purple-1 fw-500 mt-10"
							onClick={() => setOpenAddForm(true)}>
							+ Add Chapter
						</Button>
					</div>
				</div>
				<div className="py-10 px-30">
					<div className="row  y-gap-20">
						{SellerChapters?.length ? (
							<div className="accordion -block-2 text-left js-accordion">
								{SellerChapters.map((elm, i) => (
									<div
										key={i}
										className={`accordion__item ${
											String(activeItemId) === String(elm.id) ? "is-active" : ""
										} `}>
										<div
											onClick={() =>
												setActiveItemId((pre) => (pre === elm.id ? 0 : elm.id))
											}
											className="accordion__button py-20 px-30 bg-light-4">
											<div className="d-flex items-center">
												<div className="accordion__icon">
													<div className="icon">
														<FontAwesomeIcon icon={faChevronDown} />
													</div>
													<div className="icon">
														<FontAwesomeIcon icon={faChevronUp} />
													</div>
												</div>
												<span className="text-17 fw-500 text-dark-1">
													{elm.title}
												</span>
											</div>

											<div>
												<div className="d-flex justify-content-end">
													<div
														className="icon-edit"
														style={{ cursor: "pointer" }}
														onClick={() => {
															setSelectedChapter(elm);
															setOpenEditChapterForm(true);
														}}></div>
													&emsp;
													<Popconfirm
														title="Delete Chapter"
														description="Are you sure to delete this chapter?"
														okText="Yes"
														cancelText="No"
														onConfirm={() => handleDeleteChapter(elm)}>
														<div
															className="icon-bin"
															style={{ cursor: "pointer" }}></div>
													</Popconfirm>
												</div>
											</div>
										</div>

										<div
											className="accordion__content"
											style={
												String(activeItemId) === String(elm.id)
													? { maxHeight: "700px" }
													: {}
											}>
												<Lessons row={elm} courseId={courseId} course_slug_name={course_slug_name} handleShowAutoText={props?.handleShowAutoText}/>
										</div>
									</div>
								))}
							</div>
						) : (
							<div className="text-center">Chapters not available yet.</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
