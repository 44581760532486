import { GET_BOOKMARK_COURSES } from "../../action/commonActions/types";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";

const initialState = {
	data: [],
	isLoading: false,
	isSuccess: false,
	isError: false,
	meta_data: {
		current: 1,
	},
};

const bookmarkCoursesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_BOOKMARK_COURSES: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						isLoading: true,
					};
				}
				case SUCCESS: {
					return {
						...state,
						data: action?.payload?.data ?? [],
						isSuccess: true,
						isLoading: false,
						meta_data: action?.payload?.meta_data ?? {},
					};
				}
				case ERROR: {
					return {
						...state,
						isError: true,
						isLoading: false,
						isSuccess: false,
					};
				}

				default:
					return state;
			}
		}
		default:
			return state;
	}
};
export default bookmarkCoursesReducer;
