import React, { useEffect, useRef, useState } from "react";
import { allCoursesSortingOptions } from "../../../../objectTemplates/objectTemplates";
import {
	Box,
	Button,
	DialogTitle,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	Link,
	Rating,
} from "@mui/material";
import { PRIMARY_COLOR } from "../../../../config/config";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Empty } from "antd";

export default function SuperAllCoursesFilterAndSortingArea(props) {
	const {
		loading,
		handleApplyFilters,
		handleResetFilters,
		sortBy,
		setSortBy,
		handleSorting,
		filterCategory,
		setFilterCategory,
		filterSubCategory,
		setFilterSubCategory,
		filterRating,
		setFilterRating,
		filterInstructor,
		setFilterInstructor,
		filterLevel,
		setFilterLevel,
		filterLanguage,
		setFilterLanguage,
		filterDuration,
		setFilterDuration,
		subCategoryList,
		setSubCategoryList,
	} = props;

	const [filterOpen, setFilterOpen] = useState(false);
	const [categoryOpen, setCategoryOpen] = useState(true);
	const [subCategoryOpen, setSubCategoryOpen] = useState(true);
	const [ratingOpen, setRatingOpen] = useState(false);
	const [instructorOpen, setInstructorOpen] = useState(false);
	const [levelOpen, setLevelOpen] = useState(false);
	const [openLanguage, setOpenLanguage] = useState(false);
	const [durationOpen, setDurationOpen] = useState(false);

	const subCategoryFilterRef = useRef(null);
	const categoryFilterRef = useRef(null);
	const instructorFilterRef = useRef(null);

	const maxFilterHeight = "350px";
	const maxFilterHeightCompare = 350;
	const rightFilterPadding = "2px";

	const {
		data: {
			category: Categories,
			rating: ratingListWithAll,
			seller: InstructorList,
			level: levels,
			audio_language: audioLanguageArray,
			duration: durations,
		},
		isLoading,
	} = useSelector(
		(state) => state.superAdmin.superCourses.allCourses.filtersList
	);

	const findObjectsInAWithValuesFromB = (arrA, arrB) => {
		let result = [];
		arrA?.forEach((objA) => {
			if (arrB.includes(objA.value.toString())) {
				result.push(objA);
			}
		});
		return result;
	};

	const combineSubcategories = (categoryObject) => {
		let combinedSubcategories = [];
		categoryObject.forEach((obj) => {
			if (obj.subcategory) {
				combinedSubcategories = combinedSubcategories.concat(obj.subcategory);
			}
		});
		return combinedSubcategories;
	};

	const valuesPresentInA = (a, b) => {
		let result = [];
		if (b[0] === "0") {
			return ["0"];
		} else {
			b?.forEach((element) => {
				let found = a.some((obj) => obj.value.toString() === element);
				if (found) {
					result.push(element);
				}
			});
			return result;
		}
	};

	useEffect(() => {
		if (Categories?.length) {
			if (filterCategory[0] === "0") {
				setSubCategoryList([]);
			} else {
				let selectedCategories = findObjectsInAWithValuesFromB(
					Categories,
					filterCategory
				);
				let selectedSubCategories = combineSubcategories(selectedCategories);
				setSubCategoryList(selectedSubCategories);
				let remainingSubCategory = valuesPresentInA(
					selectedSubCategories,
					filterSubCategory
				);
				setFilterSubCategory(
					remainingSubCategory.length ? remainingSubCategory : "0"
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Categories, filterCategory]);

	useEffect(() => {
		if (subCategoryFilterRef.current) {
			const contentHeight = subCategoryFilterRef.current.scrollHeight;
			if (contentHeight > maxFilterHeightCompare) {
				subCategoryFilterRef.current.style.overflowY = "auto";
			} else {
				subCategoryFilterRef.current.style.overflowY = "hidden";
			}
		}
	}, [subCategoryOpen, subCategoryList?.length]);

	useEffect(() => {
		if (categoryFilterRef.current) {
			const contentHeight = categoryFilterRef.current.scrollHeight;
			if (contentHeight > maxFilterHeightCompare) {
				categoryFilterRef.current.style.overflowY = "auto";
			} else {
				categoryFilterRef.current.style.overflowY = "hidden";
			}
		}
	}, [categoryOpen, Categories?.length]);

	useEffect(() => {
		if (instructorFilterRef.current) {
			const contentHeight = instructorFilterRef.current.scrollHeight;
			if (contentHeight > maxFilterHeightCompare) {
				instructorFilterRef.current.style.overflowY = "auto";
			} else {
				instructorFilterRef.current.style.overflowY = "hidden";
			}
		}
	}, [instructorOpen, InstructorList?.length]);

	// const handleFilterRating = (item) => {
	//     setFilterRating(item)
	// 	document.getElementById("dd53button").classList.toggle("-is-dd-active");
	// 	document.getElementById("dd53content").classList.toggle("-is-el-visible");
	// };
	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const handleFilterCategories = (item) => {
		if (item !== "0") {
			if (filterCategory.includes(item)) {
				const filtered = filterCategory.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterCategory([...filtered]);
			} else {
				setFilterCategory((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterCategory(["0"]);
			setFilterSubCategory(["0"]);
		}
	};

	const handleFilterSubCategories = (item) => {
		if (item !== "0") {
			if (filterSubCategory.includes(item)) {
				const filtered = filterSubCategory.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterSubCategory([...filtered]);
			} else {
				setFilterSubCategory((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterSubCategory(["0"]);
		}
	};

	const handleFilterInstructors = (item) => {
		if (item !== "0") {
			if (filterInstructor.includes(item)) {
				const filtered = filterInstructor.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterInstructor([...filtered]);
			} else {
				setFilterInstructor((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterInstructor(["0"]);
		}
	};

	const handleFilterLevels = (item) => {
		if (item !== "0") {
			if (filterLevel.includes(item)) {
				const filtered = filterLevel.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterLevel([...filtered]);
			} else {
				setFilterLevel((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterLevel(["0"]);
		}
	};

	const handleFilterLanguages = (item) => {
		if (item !== "0") {
			if (filterLanguage.includes(item)) {
				const filtered = filterLanguage.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterLanguage([...filtered]);
			} else {
				setFilterLanguage((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterLanguage(["0"]);
		}
	};

	const handleFilterDuration = (item) => {
		if (item !== "0") {
			if (filterDuration.includes(item)) {
				const filtered = filterDuration.filter(
					(elm) => String(elm) !== String(item)
				);
				setFilterDuration([...filtered]);
			} else {
				setFilterDuration((pre) =>
					[...pre, item].filter((elm) => String(elm) !== "0")
				);
			}
		} else {
			setFilterDuration(["0"]);
		}
	};

	const handleSortChange = (item) => {
		if (sortBy.includes(item)) {
			setSortBy([]);
			handleSorting("");
		} else {
			setSortBy([item]);
			handleSorting(item);
		}
		document.getElementById("dd61button").classList.toggle("-is-dd-active");
		document.getElementById("dd61content").classList.toggle("-is-el-visible");
	};

	const handleOpenFilters = () => {
		setFilterOpen(true);
	};

    const sideFilterArea = (
		<div className="sidebar -courses">
			{/* Category Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							categoryOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setCategoryOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Category</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							ref={categoryFilterRef}
							style={categoryOpen ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{Categories?.length ? (
										Categories.map((elm, i) => (
											<div
												key={i}
												onClick={() =>
													handleFilterCategories(String(elm.value))
												}
												className="sidebar-checkbox__item cursor">
												<div className="form-checkbox">
													<input
														type="checkbox"
														readOnly
														checked={
															filterCategory.includes(String(elm.value))
																? true
																: false
														}
													/>
													<div className="form-checkbox__mark">
														<div className="form-checkbox__icon icon-check"></div>
													</div>
												</div>

												<div className="sidebar-checkbox__title">
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm?.count ?? 0})
												</div>
											</div>
										))
									) : (
										<Empty description="No Data Found" />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Category Filter Ends */}

			{/* Sub Category Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							subCategoryOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setSubCategoryOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Sub Category</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							ref={subCategoryFilterRef}
							style={subCategoryOpen ? { maxHeight: maxFilterHeight,paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									<div
										className="sidebar-checkbox__item"
										onClick={() => handleFilterSubCategories("0")}>
										<div className="form-checkbox">
											<input
												type="checkbox"
												readOnly
												checked={filterSubCategory[0] === "0"}
											/>
											<div className="form-checkbox__mark">
												<div className="form-checkbox__icon icon-check"></div>
											</div>
										</div>

										<div className="sidebar-checkbox__title">All</div>
										<div className="sidebar-checkbox__count"></div>
									</div>
									{subCategoryList?.length
										? subCategoryList.map((elm, i) => (
												<div
													key={i}
													className="sidebar-checkbox__item cursor"
													onClick={() =>
														handleFilterSubCategories(String(elm.value))
													}>
													<div className="form-checkbox">
														<input
															type="checkbox"
															readOnly
															checked={
																filterSubCategory.includes(String(elm.value))
																	? true
																	: false
															}
														/>
														<div className="form-checkbox__mark">
															<div className="form-checkbox__icon icon-check"></div>
														</div>
													</div>

													<div className="sidebar-checkbox__title">
														{elm.label}
													</div>
													<div className="sidebar-checkbox__count">
														({elm?.count ?? 0})
													</div>
												</div>
										  ))
										: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Sub Category Filter Ends */}

			{/* Rating Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							ratingOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setRatingOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Ratings</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							style={ratingOpen ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{ratingListWithAll?.length ? (
										ratingListWithAll.map((elm, i) => (
											<div
												key={i}
												onClick={() => setFilterRating(elm.value)}
												className="sidebar-checkbox__item cursor">
												<div className="form-radio mr-10">
													<div className="radio">
														<input
															type="radio"
															readOnly
															checked={
																String(elm.value) === String(filterRating)
															}
														/>
														<div className="radio__mark">
															<div className="radio__icon"></div>
														</div>
													</div>
												</div>
												<div className="sidebar-checkbox__title d-flex items-center">
													<div className="d-flex x-gap-5 pr-10">
														{getRatingStars(5)}
													</div>
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm.count})
												</div>
											</div>
										))
									) : (
										<Empty />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Rating Filter Ends */}

			{/* Instructor Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							instructorOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setInstructorOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Educators</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							ref={instructorFilterRef}
							style={instructorOpen ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{InstructorList?.length ? (
										InstructorList.map((elm, i) => (
											<div
												key={i}
												onClick={() =>
													handleFilterInstructors(String(elm.value))
												}
												className="sidebar-checkbox__item cursor">
												<div className="form-checkbox">
													<input
														type="checkbox"
														readOnly
														checked={
															filterInstructor.includes(String(elm.value))
																? true
																: false
														}
													/>
													<div className="form-checkbox__mark">
														<div className="form-checkbox__icon icon-check"></div>
													</div>
												</div>

												<div className="sidebar-checkbox__title">
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm?.count ?? 0})
												</div>
											</div>
										))
									) : (
										<Empty description="No Data Found" />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Instructor Filter Ends */}

			{/* Price Filter Starts */}
			{/* <div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							priceOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setPriceOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Price</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							style={priceOpen ? { maxHeight: "350px" } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{prices.map((elm, i) => (
										<div
											key={i}
											className="sidebar-checkbox__item cursor"
											onClick={() => setFilterPrice(elm.value)}>
											<div className="form-radio mr-10">
												<div className="radio">
													<input
														type="radio"
														readOnly
														checked={String(filterPrice) === String(elm.value)}
													/>
													<div className="radio__mark">
														<div className="radio__icon"></div>
													</div>
												</div>
											</div>
											<div className="sidebar-checkbox__title">{elm.label}</div>
											<div className="sidebar-checkbox__count">(0)</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* Price Filter Ends */}

			{/* Level Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							levelOpen ? "is-active" : ""
						}  `}>
						<div
							className="accordion__button items-center"
							onClick={() => setLevelOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Level</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>
						<div
							className="accordion__content"
							style={levelOpen ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{levels?.length ? (
										levels.map((elm, i) => (
											<div
												key={i}
												className="sidebar-checkbox__item cursor"
												onClick={() => handleFilterLevels(String(elm.value))}>
												<div className="form-checkbox">
													<input
														type="checkbox"
														readOnly
														checked={
															filterLevel.includes(String(elm.value))
																? true
																: false
														}
													/>
													<div className="form-checkbox__mark">
														<div className="form-checkbox__icon icon-check"></div>
													</div>
												</div>

												<div className="sidebar-checkbox__title">
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm.count})
												</div>
											</div>
										))
									) : (
										<Empty />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Level Filter Ends */}

			{/* Language Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							openLanguage ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setOpenLanguage((pre) => !pre)}>
							<h5 className="sidebar__title">Languange</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>
						<div
							className="accordion__content"
							style={openLanguage ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{audioLanguageArray?.length ? (
										audioLanguageArray.map((elm, i) => (
											<div
												key={i}
												className="sidebar-checkbox__item cursor"
												onClick={() =>
													handleFilterLanguages(String(elm.value))
												}>
												<div className="form-checkbox">
													<input
														type="checkbox"
														readOnly
														checked={
															filterLanguage.includes(String(elm.value))
																? true
																: false
														}
													/>
													<div className="form-checkbox__mark">
														<div className="form-checkbox__icon icon-check"></div>
													</div>
												</div>
												<div className="sidebar-checkbox__title">
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm.count})
												</div>
											</div>
										))
									) : (
										<Empty />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Language Filter Ends */}

			{/* Duration Filter Starts */}
			<div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							durationOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setDurationOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Duration</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							style={durationOpen ? { maxHeight: maxFilterHeight, paddingRight: rightFilterPadding } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{durations?.length ? (
										durations.map((elm, i) => (
											<div
												key={i}
												className="sidebar-checkbox__item cursor"
												onClick={() => handleFilterDuration(String(elm.value))}>
												<div className="form-checkbox">
													<input
														type="checkbox"
														readOnly
														checked={
															filterDuration.includes(String(elm.value))
																? true
																: false
														}
													/>
													<div className="form-checkbox__mark">
														<div className="form-checkbox__icon icon-check"></div>
													</div>
												</div>
												<div className="sidebar-checkbox__title">
													{elm.label}
												</div>
												<div className="sidebar-checkbox__count">
													({elm.count})
												</div>
											</div>
										))
									) : (
										<Empty />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Duration Filter Ends */}
		</div>
	);

	return (
		<>
			<div className="row y-gap-20 items-center justify-between pb-30">
				<div className="col-auto">
					<div className="row x-gap-20 y-gap-20">
						{/*Filter Section Starts */}
						{filterOpen && (
							<Drawer
								anchor="right"
								open={filterOpen}
								onClose={() => setFilterOpen(false)}
								sx={{
									"& .MuiDrawer-paper": {
										width: "90%",
										maxWidth: "350px",
									},
								}}
								SlideProps={{
									translate: "yes",
									timeout: 1000,
								}}>
								<Box>
									<DialogTitle
										sx={{
											m: 0,
											p: 2,
											display: "flex",
											justifyContent: "space-between",
										}}
										id="customized-dialog-title">
										Filters
										<Link
											className="h-40 px-25 text-purple-1 mr-15"
											onClick={() => handleApplyFilters()}>
											{isLoading ? "Loading..." : "Apply Filters"}
										</Link>
									</DialogTitle>

									<IconButton
										aria-label="close"
										onClick={() => setFilterOpen(false)}
										sx={{
											position: "absolute",
											right: 8,
											top: 10,
											color: (theme) => theme.palette.grey[500],
										}}>
										<Close />
									</IconButton>
									{isLoading && <LinearProgress />}
									<Divider />
									<div className="pl-20 pr-20 pt-20 pb-30">{!isLoading && sideFilterArea}</div>
								</Box>
							</Drawer>
						)}
						{/* Filter Section Ends */}
					</div>
				</div>
				<div className="col-auto d-flex" style={{ justifyContent: "flex-end" }}>
					<div className="row x-gap-20 y-gap-20">
						<div className="col-auto d-flex">
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12 text-white"
								style={{ background: PRIMARY_COLOR }}
								disabled={loading}
								onClick={() => handleOpenFilters()}>
								{loading ? "Loading Data..." : "Open Filters"}
							</Button>
							&emsp;
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12"
								style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
								variant="outlined"
								disabled={loading}
								onClick={() => handleResetFilters()}>
								{loading ? "Loading..." : "Reset Filters"}
							</Button>
						</div>
						<div className="col-auto">
							<div className="d-flex items-center">
								<div className="text-14 lh-12 fw-500 text-dark-1 mr-20">
									Sort by:
								</div>

								<div
									id="dd61button"
									className="dropdown js-dropdown js-category-active">
									<div
										className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
										onClick={() => {
											document
												.getElementById("dd61button")
												.classList.toggle("-is-dd-active");
											document
												.getElementById("dd61content")
												.classList.toggle("-is-el-visible");
										}}
										data-el-toggle=".js-category-toggle"
										data-el-toggle-active=".js-category-active">
										<span className="js-dropdown-title">
											{sortBy?.length
												? allCoursesSortingOptions.find(
														(x) => x.id === sortBy[0]
												  ).title
												: "Sort By"}
										</span>
										<i className="icon text-9 ml-40 icon-chevron-down"></i>
									</div>

									<div
										id="dd61content"
										className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
										<div className="text-14 y-gap-15 js-dropdown-list">
											<div
												onClick={() => {
													setSortBy([]);
													handleSorting("");
													document
														.getElementById("dd61button")
														.classList.toggle("-is-dd-active");
													document
														.getElementById("dd61content")
														.classList.toggle("-is-el-visible");
												}}>
												<span
													className={`d-block js-dropdown-link cursor ${
														sortBy?.length === 0 ? "activeMenu" : ""
													} `}>
													Default
												</span>
											</div>
											{allCoursesSortingOptions.map((elm, i) => (
												<div key={i} onClick={() => handleSortChange(elm.id)}>
													<span
														className={`d-block js-dropdown-link cursor ${
															sortBy?.[0] === elm.id ? "activeMenu" : ""
														} `}>
														{elm.title}
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
