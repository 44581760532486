import React from "react";
import { Link } from "react-router-dom";
import { COMPANY_NAME } from "../../../config/config";

export const LoggedInFooter = () => {
	return (
		<footer className="footer -dashboard py-30">
			<div className="row items-center justify-between">
				<div className="col-auto">
					<div className="text-13 lh-1">
						© {new Date().getFullYear()} {COMPANY_NAME}. All Right Reserved.
					</div>
				</div>

				<div className="col-auto md:mt-15">
					<div className="d-flex items-center">
						<div className="d-flex items-center flex-wrap x-gap-20">
							<div>
								<Link to="/help-center" className="text-13 lh-1">
									Help and Support
								</Link>
							</div>
							<div>
								<Link to="/terms" className="text-13 lh-1">
									Terms and Conditions
								</Link>
							</div>
							<div>
								<Link to="/contact-us" className="text-13 lh-1">
									Contact Us
								</Link>
							</div>
							<div>
								<Link to="/about-us" className="text-13 lh-1">
									About Us
								</Link>
							</div>
							<div>
								<Link to="/instructor-become" className="text-13 lh-1">
									Careers
								</Link>
							</div>
						</div>

					</div>
				</div>
			</div>
		</footer>
	);
};
