import Axios from "axios";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import {GET_PAYMENT_HISTORY,GET_SELLER_COURSE_TRANSACTION_HISTORY } from "../commonActions/types";
import { BASE_URL } from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";


export function getSellerPaymentHistory(body={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_PAYMENT_HISTORY, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: '',
                page :body?.page !== undefined ? body.page : 1,
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}courses/getSellerTransactionHistory`, Config);
            const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_PAYMENT_HISTORY,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_PAYMENT_HISTORY,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function getSellerCoursePaymentHistory(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SELLER_COURSE_TRANSACTION_HISTORY, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}courses/getSellerCoursePurchasedTransaction`, Config);
            const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SELLER_COURSE_TRANSACTION_HISTORY,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_SELLER_COURSE_TRANSACTION_HISTORY,
                status: ERROR,
                payload: error,
            });
        }
    };
}
