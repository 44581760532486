import React, { useEffect } from "react";
import CourseCard from "../../../components/others/courseCards/CourseCard";
import { useDispatch, useSelector } from "react-redux";
import { getMostPopularCoursesActions } from "../../../redux/action/courseActions/mostPopularCoursesActions";
import { Empty, Spin } from "antd";

export default function MostPopularCourses() {
	const dispatch = useDispatch();
	const {
		data: topCourses,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.courses.popularCourses);

	useEffect(() => {
		!isSuccess && dispatch(getMostPopularCoursesActions());
	}, [dispatch, isSuccess]);

	return (
		<section className="layout-pt-lg layout-pb-lg">
			<div className="row justify-center text-center">
				<div className="col-auto">
					<div className="sectionTitle ">
						<h2 className="sectionTitle__title_2 lh-14">
							Our Most Popular Courses
						</h2>

						<p className="sectionTitle__text ">
							Experience our premium selection of bestselling courses.
						</p>
					</div>
				</div>
			</div>

			<Spin spinning={isLoading}>
				<div
					className="pt-60 m-auto row y-gap-30 container pl-0 pr-0"
					data-aos="fade-right"
					data-aos-offset="80"
					data-aos-duration={800}>
					{topCourses?.length ?
						topCourses.slice(0,8).map((elm, index) => (
							<CourseCard
								key={index}
								data={elm}
								index={index}
								data-aos="fade-right"
								data-aos-duration={(index + 1) * 300}
							/>
						)) : <Empty description={'Popular Courses are not available yet.'}/>}
				</div>
			</Spin>
		</section>
	);
}
