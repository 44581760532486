/* eslint-disable jsx-a11y/anchor-is-valid */
import { Empty, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getEnrolledMyCoursesAction,
	updateCourseBookmarkAction,
} from "../../../../../../redux/action/courseActions/customerCoursesActions";
import CoursesCardDashboard from "./CoursesCardDashboard";
import { Pagination } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function UserCourses() {
	const [activeTab, setActiveTab] = useState(1);
	const [search, setSearch] = useState("");
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const location = useLocation();

	useEffect(() => {
		let body = {
			page: 1,
			search: "",
		};
		if (location?.state?.tab === "COMPLETED") {
			setActiveTab(2);
			body.status = "COMPLETED";
			dispatch(getEnrolledMyCoursesAction(body));
		} else if (location?.state?.tab === "INCOMPLETED") {
			setActiveTab(3);
			body.status = "INCOMPLETED";
			dispatch(getEnrolledMyCoursesAction(body));
		} else {
			setActiveTab(1);
			dispatch(getEnrolledMyCoursesAction(body));
		}
	}, [dispatch, location?.state]);

	const {
		data: myCourses,
		meta_data,
		isLoading: isMyCoursesLoading,
	} = useSelector((state) => state.courses.enrolledMyCourses);

	// useEffect(() => {
	// 	let body = {
	// 		page: 1,
	// 		search: "",
	// 	};
	// 	dispatch(getEnrolledMyCoursesAction(body));
	// }, [dispatch]);

	const handlePageChange = (value) => {
		let body = {
			page: value,
			search: search,
		};
		dispatch(getEnrolledMyCoursesAction(body));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value) => {
			let body = {
				search: value,
				page: 1,
			};
			dispatch(getEnrolledMyCoursesAction(body));
		}, 500),
		[debounce]
	);

	const handleTabChange = (value) => {
		setActiveTab(value);
		setSearch("");
		let body = {
			page: 1,
			search: "",
		};
		if (value === 1) {
			dispatch(getEnrolledMyCoursesAction(body));
		} else if (value === 2) {
			body.status = "COMPLETED";
			dispatch(getEnrolledMyCoursesAction(body));
		} else if (value === 3) {
			body.status = "INCOMPLETED";
			dispatch(getEnrolledMyCoursesAction(body));
		} else {
			dispatch(getEnrolledMyCoursesAction(body));
		}
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value);
	};

	const updateCourseBookmark = (item) => {
		let body = {
			course: item?.id ?? 0,
		};
		dispatch(
			updateCourseBookmarkAction(body, {
				onLoading: (loading) => {
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					enqueueSnackbar(success.message[0], { variant: "success" });
					let body = {
						search: search,
						page: meta_data?.current ?? 1,
					};
					dispatch(getEnrolledMyCoursesAction(body));
				},
			})
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">My Courses</h1>
						<div className="mt-10">Welcome to the courses Section.</div>
					</div>
				</div>

				<div className="row y-gap-10">
					<div className="col-12">
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="tabs -active-purple-2 js-tabs">
								<div className="tabs__controls d-flex items-center pt-10 px-30 border-bottom-light js-tabs-controls">
									<button
										className={`text-light-1 lh-12 tabs__button js-tabs-button ${
											activeTab === 1 ? "is-active" : ""
										} `}
										data-tab-target=".-tab-item-1"
										type="button"
										onClick={() => handleTabChange(1)}>
										All Courses
									</button>
									<button
										className={`text-light-1 lh-12 tabs__button js-tabs-button ml-30 ${
											activeTab === 2 ? "is-active" : ""
										} `}
										data-tab-target=".-tab-item-2"
										type="button"
										onClick={() => handleTabChange(2)}>
										Completed Courses
									</button>
									<button
										className={`text-light-1 lh-12 tabs__button js-tabs-button ml-30 ${
											activeTab === 3 ? "is-active" : ""
										} `}
										data-tab-target=".-tab-item-3"
										type="button"
										onClick={() => handleTabChange(3)}>
										In Progress Courses
									</button>
								</div>

								<div className="tabs__content pt-20 px-30 js-tabs-content">
									<div className="tabs__pane -tab-item-1 is-active">
										<div className="row y-gap-10 justify-between">
											<div className="col-auto">
												<form className="search-field border-light rounded-8 h-50">
													<input
														required
														className="bg-white -dark-bg-dark-2 pr-50"
														type="text"
														placeholder="Search Courses"
														value={search}
														onChange={(e) => handleSearch(e)}
													/>
													<button className="" type="submit">
														<i className="icon-search text-light-1 text-20"></i>
													</button>
												</form>
											</div>

											<div className="col-auto">
												<div className="d-flex flex-wrap y-gap-10 x-gap-20">
													<div>
														<div
															id="dd14button"
															onClick={() => {
																document
																	.getElementById("dd14button")
																	.classList.toggle("-is-dd-active");
																document
																	.getElementById("dd14content")
																	.classList.toggle("-is-el-visible");
															}}
															className="dropdown js-dropdown js-category-active">
															{/* <div
																className="dropdown__button d-flex items-center text-14 bg-white -dark-bg-dark-2 border-light rounded-8 px-20 py-10 text-14 lh-12"
																data-el-toggle=".js-category-toggle"
																data-el-toggle-active=".js-category-active">
																<span className="js-dropdown-title">
																	{currentCategory != "All Categories"
																		? currentCategory
																		: "Categories"}
																</span>
																<i className="icon text-9 ml-40 icon-chevron-down"></i>
															</div> */}

															{/* <div
																id="dd14content"
																className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
																<div className="text-14 y-gap-15 js-dropdown-list">
																	{ddItems.map((item, ind) => (
																		<div
																			onClick={() =>
																				setCurrentCategory(item.label)
																			}
																			key={ind}
																			className={`d-block js-dropdown-link cursor ${
																				currentCategory == item.label
																					? "activeMenu"
																					: ""
																			} `}>
																			<span
																				style={{ cursor: "pointer" }}
																				className="d-block js-dropdown-link">
																				{item.label}
																			</span>
																		</div>
																	))}
																</div>
															</div> */}
														</div>
													</div>
													{/* <div>
														<div
															id="dd15button"
															onClick={() => {
																document
																	.getElementById("dd15button")
																	.classList.toggle("-is-dd-active");
																document
																	.getElementById("dd15content")
																	.classList.toggle("-is-el-visible");
															}}
															className="dropdown js-dropdown js-review-active">
															<div
																className="dropdown__button d-flex items-center text-14 bg-white -dark-bg-dark-2 border-light rounded-8 px-20 py-10 text-14 lh-12"
																data-el-toggle=".js-review-toggle"
																data-el-toggle-active=".js-review-active">
																<span className="js-dropdown-title">
																	Old Review
																</span>
																<i className="icon text-9 ml-40 icon-chevron-down"></i>
															</div>

															<div
																id="dd15content"
																className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-review-toggle">
																<div className="text-14 y-gap-15 js-dropdown-list">
																	<div>
																		<a
																			// href="#"
																			className="d-block js-dropdown-link">
																			Animation
																		</a>
																	</div>
																</div>
															</div>

														</div>
													</div> */}
												</div>
											</div>
										</div>
										<Spin spinning={isMyCoursesLoading}>
											{myCourses?.length ? (
												<div className="row y-gap-30 pt-30">
													{myCourses.map((data, i) => (
														<CoursesCardDashboard
															data={data}
															key={i}
															updateCourseBookmark={updateCourseBookmark}
														/>
													))}
												</div>
											) : (
												<Empty description={"Courses are not added yet..."} />
											)}
										</Spin>

										<div className="row justify-center pt-30 pb-20">
											<div className="col-auto">
												<Pagination
													count={meta_data?.total_pages ?? 0}
													page={meta_data?.current ?? 1}
													onChange={(event, value) => handlePageChange(value)}
													size="large"
													sx={{
														ul: {
															"& .Mui-selected": {
																background: "#6440FB",
																color: "white",
															},
														},
													}}
													disabled={isMyCoursesLoading}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Outside of box  */}
					</div>
				</div>
			</div>

			<LoggedInFooter/>
		</div>
	);
}
