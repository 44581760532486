import Axios from "axios";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { ADD_COURSE_RATING } from "../commonActions/types";
import { BASE_URL } from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function addCourseRatingAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_COURSE_RATING, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };
            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.post(`${BASE_URL}courses/addCourseRating`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_COURSE_RATING,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: ADD_COURSE_RATING,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}