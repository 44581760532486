import { faChevronDown, faChevronUp, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Empty, Tooltip } from "antd";
import React, { useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	getEnrolledCourseDetailsAction,
	updateLessonProgressAction,
} from "../../../../../../redux/action/courseActions/customerCoursesActions";
import { useSnackbar } from "notistack";
import { getCourseCertificate } from "../../../../../../redux/action/myDashboardActions/coursesActions";
import { updateSuperCourseAction } from "../../../../../../redux/action/superAdminActions/superCoursesActions";

export default function LessonItems(props) {
	const {
		rightPosition,
		chapters = [],
		showCourseProgress = true,
		allowChangeLessonStatus = true,
		allow_publish_unPublish = false,
		show_course_views = false
	} = props;
	const [activeItemId, setActiveItemId] = useState(0);
	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const handleDownloadCertificate = () => {
		dispatch(
			getCourseCertificate(
				props?.data?.enrolled_course_id
					? props.data.enrolled_course_id
					: props?.data?.id
			)
		);
	};

	const handleLessonStatus = (lesson) => {
		let body = {
			lesson: lesson.id,
			status: !Boolean(lesson.progress),
		};
		dispatch(
			updateLessonProgressAction(body, {
				onLoading: (loading) => {
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					enqueueSnackbar(success.message[0], { variant: "success" });
					props?.data?.slug_name &&
						dispatch(getEnrolledCourseDetailsAction(props.data.slug_name));
				},
			})
		);
	};

	const tooltipStatus = (lesson) => {
		return {
			title:
				lesson.progress === 100 ? "Mark as InComplete" : "Mark as Complete",
			color: lesson.progress === 100 ? "red" : "purple",
		};
	};

	const handleCourseLive = (status) => {
		let body = {
			course_status: status,
		};
		setLoading(true);
		dispatch(
			updateSuperCourseAction(body, props?.data.id, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading. Please wait...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar("Course Updated Successfully...", {
						variant: "success",
					});
					props?.fetchDataAgain?.()
				},
			})
		);
	};

	return (
		<div>
			<aside
				className={`lesson-sidebar  ${
					rightPosition ? "-type-2 lg:order-2 bg-light-3" : "bg-light-3"
				} `}>
				<div className="px-30 pb-60 sm:px-20">
					{allow_publish_unPublish ? (
						<div
							className="d-flex py-20 px-30 bg-light-4"
							style={{ width: "100%", flexDirection: "column" }}>
							<div
								className="d-flex justify-between"
								style={{ flexDirection: "column", gap: 10 }}>
								<div className="text-17 fw-500 text-dark-1">Course Status</div>
								{props?.data.course_status === "DRAFT" ||
								props?.data.course_status === "UN_PUBLISHED" ? (
									<span className="error-message mr-35">
										Fill all the details to publish course
									</span>
								) : null}
								{props?.data.is_available_for_published &&
								(props?.data.course_status === "DRAFT" ||
									props?.data.course_status === "UN_PUBLISHED") ? (
									<Button
										component="label"
										className="button h-40 px-25 -dark-1 -dark-button-white text-white mr-35"
										onClick={() => handleCourseLive("PUBLISHED")}
									>
										{loading ? "Loading..." : "Publish it"}
									</Button>
								) : (
									props?.data.course_status === "PUBLISHED" && (
										<Button
											component="label"
											className="button h-40 px-25 -dark-1 -dark-button-white text-white mr-35"
											onClick={() => handleCourseLive("UN_PUBLISHED")}
										>
											{loading ? "Loading..." : "Un-Publish"}
										</Button>
									)
								)}
							</div>{" "}
						</div>
					) : null}
					{/* Course Progress Starts */}
					{showCourseProgress ? (
						<div className={`accordion__item mt-10`} key={"course-progress"}>
							<div
								className="d-flex py-20 px-30 bg-light-4"
								style={{ width: "100%", flexDirection: "column" }}>
								<div className="d-flex justify-between items-center">
									<div className="text-17 fw-500 text-dark-1">
										Course Progress
									</div>
									{props?.data?.progress === 100 && (
										<Link
											onClick={() => handleDownloadCertificate()}
											className="text-14 text-purple-1">
											Certificate &nbsp;
											<i className={`text-10 icon-document text-purple-1`}></i>
										</Link>
									)}
								</div>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Box sx={{ width: "100%", mr: 1 }}>
										<LinearProgress
											variant="determinate"
											value={props?.data?.progress ?? 0}
											{...props}
										/>
									</Box>
									<Box sx={{ minWidth: 35 }}>
										<Typography
											variant="body2"
											color="text.secondary">{`${Math.round(
											props?.data?.progress ?? 0
										)}%`}</Typography>
									</Box>
								</Box>
							</div>
						</div>
					) : null}
					{/* Course Progress Ends */}

					{/* Course Overview Starts */}
					<div className={`accordion__item mt-20`} key={"course-overview"}>
						<div className="py-20 px-30 bg-light-4">
							<div
								className="d-flex items-center"
								onClick={() => {
									props?.setVideoObject({
										image: props?.data?.course_thumbnail_image ?? "",
										video: props?.data?.course_thumbnail_video ?? "",
										autoPlay: false,
										isLesson: false,
										lesson: null,
									});
								}}>
								<span
									className="text-17 fw-500 text-purple-1"
									style={{ cursor: "pointer" }}>
									Course Overview
								</span>
							</div>
							{show_course_views ?<div>
								<FontAwesomeIcon icon={faEye} className="mr-15"/>{props?.data?.course_views ?? 0} views
							</div>: null}
						</div>
					</div>
					{/* Course Overview Ends */}

					<div className="lesson-sidebar-search pl-20 mt-20 d-flex justify-content">
						<Typography variant="h6">
							Chapters ( {chapters?.length ?? 0} )
						</Typography>
					</div>

					{/* Chapters listing Starts */}
					<div className="accordion -block-2 text-left js-accordion mt-10">
						{chapters?.length ? (
							chapters.map((item, index) => (
								<div
									className={`accordion__item ${
										String(activeItemId) === String(item.id) ? "is-active" : ""
									} `}
									key={index}>
									<div
										onClick={() =>
											setActiveItemId((pre) =>
												String(pre) === String(item.id) ? 0 : String(item.id)
											)
										}
										className="accordion__button py-20 px-30 bg-light-4">
										<div className="d-flex items-center">
											<div className="accordion__icon">
												<div className="icon" data-feather="chevron-up">
													<FontAwesomeIcon icon={faChevronDown} />
												</div>
												<div className="icon" data-feather="chevron-up">
													<FontAwesomeIcon icon={faChevronUp} />
												</div>
											</div>
											<span className="text-17 fw-500 text-dark-1">
												{item.title}
											</span>
										</div>
									</div>

									<div
										className="accordion__content"
										style={
											String(activeItemId) === String(item.id)
												? { maxHeight: "700px", overflowY: "auto" }
												: {}
										}>
										<div className="accordion__content__inner px-30 py-30">
											<div className="y-gap-30">
												{item.lessons.map((lesson, index) => (
													<div className="" key={index}>
														<div className="d-flex">
															<div
																className={`d-flex justify-center items-center size-30 rounded-full ${
																	String(props?.videoObject?.lesson?.id) ===
																	String(lesson.id)
																		? "text-white bg-purple-1"
																		: "bg-purple-3"
																} mr-10`}>
																{lesson?.video_obj?.key ? (
																	<div className="icon-play text-10"></div>
																) : (
																	<div className="icon-list text-10"></div>
																)}
															</div>
															<div style={{ width: "100%" }}>
																<div className="d-flex justify-between items-center pt-5">
																	<div
																		className={
																			String(props?.videoObject?.lesson?.id) ===
																			String(lesson.id)
																				? "text-purple-1"
																				: ""
																		}>
																		{lesson.title}
																	</div>
																	{allowChangeLessonStatus ? (
																		<Tooltip {...tooltipStatus(lesson)}>
																			<div className="form-checkbox">
																				<input
																					type="checkbox"
																					readOnly
																					onClick={() =>
																						handleLessonStatus(lesson)
																					}
																					checked={
																						lesson.progress === 100
																							? true
																							: false
																					}
																				/>
																				<div className="form-checkbox__mark">
																					<div className="form-checkbox__icon icon-check"></div>
																				</div>
																			</div>
																		</Tooltip>
																	) : null}
																</div>
																<div className="d-flex justify-between items-center pt-5">
																	<span
																		onClick={() => {
																			props?.setVideoObject({
																				...props?.videoObject,
																				video: lesson?.video_obj?.key ?? "",
																				image:
																					lesson?.video_obj?.image?.url ??
																					props?.data?.course_thumbnail_image,
																				autoPlay: true,
																				isLesson: true,
																				lesson: lesson,
																			});
																		}}
																		className="text-14 lh-1 text-purple-1 underline cursor">
																		Preview
																	</span>
																	{lesson?.video_obj?.key ? (
																		<span>Duration: {lesson.duration}</span>
																	) : null}
																	{/* {lesson?.video_obj?.key ?<span>Duration: {lesson.duration}</span> : <span>No Video Available</span>} */}
																</div>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<Empty description={"Lesson are not available yet"} />
						)}
					</div>
					{/* Chapters listing ends */}
				</div>
			</aside>
		</div>
	);
}
