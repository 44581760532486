import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../styles/videoModel.css";

export default function ModalVideoComponent({ isOpen, setIsOpen, url }) {
    if (!isOpen) return null;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <FontAwesomeIcon
                    icon={faTimes}
                    className="custom-modal-close"
                    onClick={() => setIsOpen(false)}
                />
                <video
                    controls
                    autoPlay
                    className="custom-modal-video"
                    preload="auto"
					controlsList="nodownload"
                >
                    <source src={url} type="video/mp4" />
                    <source src={url} type="video/quicktime" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

ModalVideoComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};
