import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Pagination, useMediaQuery } from "@mui/material";
import { Empty, Spin, Popover } from "antd";
import { getSellerCoursePaymentHistory } from "./../../../../../../redux/action/billingActions/billingActions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function SellerCoursePaymentHistory() {
	const dispatch = useDispatch();

	const isMobile = useMediaQuery("(max-width:600px)");
	const isDesktop = useMediaQuery("(min-width:601px)");

	const {
		data: SellerBilling,
		isLoading,
		meta_data,
	} = useSelector((state) => state.billing.sellerCoursePaymentHistory);

	useEffect(() => {
		let body = {
			page: 1,
		};
		dispatch(getSellerCoursePaymentHistory(body));
	}, [dispatch]);

	const getStatusChip = (status) => {
		let chipColor="info";
		let label;
		switch (status) {
			case "SUCCESS":
				chipColor = "success";
				label = "Success";
				break;
			case "FAILURE":
				chipColor = "error";
				label = "Failure";
				break;
			case "DIAGNOSE":
				chipColor = "error";
				label = "Report to Admin";
				break;
			default:
				return ;
		}
		return (
			<Chip size="small" label={label} variant="outlined" color={chipColor} />
		);
	};

	const handlePageChange = (value) => {
		let body = {
			page: value,
		};
		dispatch(getSellerCoursePaymentHistory(body));
	};

	const popOverContent = (paymentItem) => {
		return paymentItem?.course?.length ? (
			<ul>
				{paymentItem.course.map((item) => (
					<li key={item.course__id}>
							<FontAwesomeIcon className={`text-purple-5`} icon={faPlay} size="2xs"/>
							{" "}
						{item?.course__title ?? "--"}
					</li>
				))}
			</ul>
		) : (
			<>Course not purchased</>
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">Course Payment</h1>
						<div className="mt-10">
							Welcome to the Course Payment Section.
						</div>
					</div>
				</div>
				{/*Table Starts here */}
				<div className="px-30 py-10 bg-light-7 -dark-bg-dark-2 rounded-8">
					<div className="row x-gap-10">
						{isMobile && (
							<>
								<div className="col-8 col-lg-8">
									<div className="text-purple-1 text-left">Title</div>
								</div>
								<div className="col-4 col-lg-4">
									<div className="text-purple-1 text-left">Status</div>
								</div>
							</>
						)}
						{isDesktop && (
							<>
								<div className="col-4 col-lg-4">
									<div className="text-purple-1 text-left">
										Title ( Transaction ID )
									</div>
								</div>
								<div className="col-2 col-lg-2">
									<div className="text-purple-1 text-left">Date</div>
								</div>
								<div className="col-3 col-lg-3">
									<div className="text-purple-1 text-left">Student</div>
								</div>
								<div className="col-2 col-lg-2">
									<div className="text-purple-1 text-left">Amount</div>
								</div>
								<div className="col-1 col-lg-1">
									<div className="text-purple-1 text-left">Status</div>
								</div>
							</>
						)}
					</div>
				</div>

				<Spin spinning={isLoading}>
					<div
						style={{
							width: "100%",
							overflowY: "scroll",
							height: "calc(100vh - 370px)",
						}}>
						{SellerBilling?.length ? (
							SellerBilling.map((elm, i) => (
								<div
									key={i}
									className={`px-30 ${
										elm?.status === "DIAGNOSE"
											? "border-error"
											: "border-bottom-light"
									}`}>
									<div className="row x-gap-10 items-center py-15">
										{isMobile && (
											<>
												<div className="col-8 col-lg-8">
													<div className="d-flex items-center">
														<div className="ml-10">
															<div
																className={`${
																	elm?.event_type === "COURSE_PURCHASED"
																		? "text-dark-1"
																		: "text-error-2"
																} lh-12 fw-500`}>
																{elm?.event_type === "COURSE_PURCHASED"
																	? "Course Purchased"
																	: "Money Withdrawal"}
															</div>
														</div>
													</div>
												</div>
												<div className="col-4 col-lg-4">
													{getStatusChip(elm.status)}
												</div>
											</>
										)}
										{isDesktop && (
											<>
												<div className="col-4 col-lg-4">
													<div className="d-flex items-center">
														<div className="ml-10">
															<div
																className={`${
																	elm?.event_type === "COURSE_PURCHASED"
																		? "text-dark-1"
																		: "text-error-2"
																} lh-12 fw-500`}>
																{elm?.event_type === "COURSE_PURCHASED" ? (
																	<Popover
																		content={popOverContent(elm)}
																		placement="right"
																		trigger={"click"}
																		arrow={{ pointAtCenter: true }}>
																		<span
																			className="text-purple-1"
																			style={{ cursor: "pointer" }}>
																			Course Purchased
																		</span>
																	</Popover>
																) : (
																	"Money Withdrawal"
																)}
															</div>
															<div className="text-14 lh-12 mt-5"
															 style={{overflow:'hidden',textOverflow:'ellipsis'}}
															 >
																{elm?.transaction_id ?? 'Not Generated'}
															</div>
														</div>
													</div>
												</div>

												<div className="col-2 col-lg-2">
													<div className="d-flex items-center">
														<div className="ml-10">
															<div>
																{moment(elm?.created_at ?? "--").format(
																	"MMM Do YYYY"
																)}
															</div>
														</div>
													</div>
												</div>

												<div className="col-3 col-lg-3">
													<div className="text-18 lh-1 text-dark-1 fw-500">
														{elm?.user ? elm?.user?.user_first_name + ' ' + elm?.user?.user_last_name : 'Name not available'}
													</div>
													<div className="text-14 lh-12 mt-5">
																{elm?.user?.user_email ?? 'Email not available'}
													</div>
												</div>

												<div className="col-2 col-lg-2">
													<div className="text-18 lh-1 text-dark-1 fw-500">
														${elm?.amount ?? 0}
													</div>
												</div>
												<div className="col-1 col-lg-1">
													{getStatusChip(elm.status)}
												</div>
											</>
										)}
									</div>
								</div>
							))
						) : (
							<div style={{ marginTop: "10%" }}>
								<Empty description={"Payment not added yet..."} />
							</div>
						)}
					</div>
				</Spin>

				{/* Table ends here  */}
			</div>
			<div className="pagination -buttons d-flex justify-content-center mt-15">
				<Pagination
					count={meta_data?.total_pages ?? 0}
					page={meta_data?.current ?? 1}
					onChange={(event, value) => handlePageChange(value)}
					size="large"
					sx={{
						ul: {
							"& .Mui-selected": {
								background: "#6440FB",
								color: "white",
							},
						},
					}}
					disabled={isLoading}
				/>
			</div>
			<LoggedInFooter/>
		</div>
	);
}
