import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { GET_TRAINER_DETAIL } from "../../action/commonActions/types";
import { faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const initialState = {
	data: {},
	isLoading: false,
	isSuccess: false,
	isError: false,
};

const singleTrainerReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_TRAINER_DETAIL: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						isLoading: true,
						isSuccess: false,
					};
				}
				case SUCCESS: {
					const updatedTrainerDetails = action?.payload?.data
						? {
								...action.payload.data,
								name:
									action?.payload?.data?.user_first_name +
									" " +
									action?.payload?.data?.user_last_name,
								role: action?.payload?.data?.designation ?? "",
								image: action?.payload?.data?.user_profile_image ?? "",
								rating: action?.payload?.data?.ratings?.total_rating ?? "",
								reviews:
									action?.payload?.data?.ratings?.total_reviews_count ?? "",
								students: action?.payload?.data?.student_count ?? "",
								courses: action?.payload?.data?.courses_count ?? "",
								socialProfile: [
									{
										icon: "icon-facebook",
										url: action?.payload?.data?.facebook_link ?? "",
									},
									{
										icon: "icon-twitter",
										replaceIcon: <FontAwesomeIcon icon={faXTwitter} />,
										url: action?.payload?.data?.twitter_link ?? "",
									},
									{
										icon: "icon-instagram",
										url: action?.payload?.data?.instagram_link ?? "",
									},
									{
										icon: "icon-linkedin",
										url: action?.payload?.data?.linkedin_link ?? "",
									},
									{
										icon: "icon-youtube",
										replaceIcon : <FontAwesomeIcon icon={faYoutube} />,
										url: action?.payload?.data?.youtube_channel_link ?? "",
									},
									{
										icon: "icon-website",
										replaceIcon: <FontAwesomeIcon icon={faGlobe} />,
										url: action?.payload?.data?.website_link ?? "",
									},
								],
						  }
						: {};
					return {
						...state,
						data: updatedTrainerDetails ?? {},
						isSuccess: true,
						isLoading: false,
					};
				}
				case ERROR: {
					return {
						...state,
						isError: true,
						isLoading: false,
						isSuccess: false,
					};
				}

				default:
					return state;
			}
		}
		default:
			return state;
	}
};
export default singleTrainerReducer;
