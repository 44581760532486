import {ADD_SUPER_TESTIMONIALS, GET_SUPER_TESTIMONIALS, REMOVE_SUPER_TESTIMONIALS, UPDATE_SUPER_TESTIMONIALS} from "../commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function AddSuperTestimonialsAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_SUPER_TESTIMONIALS, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let updatedBody = {
                ...body
            }
            let response = await Axios.post(`${BASE_URL}common/addTestimonials`, updatedBody, config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_SUPER_TESTIMONIALS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: ADD_SUPER_TESTIMONIALS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getSuperTestimonials(body={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_TESTIMONIALS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: 'id,title,content,user,user_name,user_designation,user_profile_image,user_profile_image_key',
                search: body?.search ?? ""
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}common/getTestimonialsList`, Config);
            // const modifiedData = {
            //     ...response.data,
            //     data: response?.data?.data.map((item, index) => ({
            //         ...item,
            //         index
            //     }))
            // }
            const modifiedData = {
				meta_data: {
					count: response?.data.data?.[0]?.count || 0,
					current: response?.data.data?.[0]?.links?.current || 0,
					hasNext: Boolean(response?.data.data?.[0]?.links?.next),
					total_pages: response?.data.data?.[0]?.links?.total_pages || 0,
					hasPrevious: Boolean(response?.data.data?.[0]?.links?.previous),
				},
				data: response?.data.data?.[0]?.results || [],
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_TESTIMONIALS,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_TESTIMONIALS,
                status: ERROR,
                payload: error,
            });
        }
    };
}


export function deleteSuperTestimonialsAction(superTestimonialId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: REMOVE_SUPER_TESTIMONIALS, status: LOADING});
        onLoading?.(true)
        try {

            let Config = {}
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_super_key")}
            }
            let response = await Axios.delete(`${BASE_URL}common/deleteTestimonials/${superTestimonialId}`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: REMOVE_SUPER_TESTIMONIALS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: REMOVE_SUPER_TESTIMONIALS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateSuperTestimonialsAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_SUPER_TESTIMONIALS, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.patch(`${BASE_URL}common/updateTestimonials/${body.id}`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_SUPER_TESTIMONIALS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: UPDATE_SUPER_TESTIMONIALS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}