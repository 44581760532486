import React from "react";
import {
	categoriesSortingOptions,
} from "../../../../objectTemplates/objectTemplates";

export default function SuperCategoriesFilterAndSortingArea(props) {
	const {
		sortBy,
		setSortBy,
		handleSorting,
	} = props;

	const handleSortChange = (item) => {
		if (sortBy.includes(item)) {
			setSortBy([]);
			handleSorting("");
		} else {
			setSortBy([item]);
			handleSorting(item);
		}
		document.getElementById("dd61button").classList.toggle("-is-dd-active");
		document.getElementById("dd61content").classList.toggle("-is-el-visible");
	};

	return (
		<>
			<div className="row y-gap-20 items-center justify-between pb-30">
				<div className="col-auto">
					<div className="row x-gap-20 y-gap-20">
						{/*  Filer Starts */}
						
						{/*  Filer Ends */}
					</div>
				</div>
				<div className="col-auto d-flex" style={{ justifyContent: "flex-end" }}>
					<div className="row x-gap-20 y-gap-20">
						
						<div className="col-auto">
							<div className="d-flex items-center">
								<div className="text-14 lh-12 fw-500 text-dark-1 mr-20">
									Sort by:
								</div>

								<div
									id="dd61button"
									className="dropdown js-dropdown js-category-active">
									<div
										className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
										onClick={() => {
											document
												.getElementById("dd61button")
												.classList.toggle("-is-dd-active");
											document
												.getElementById("dd61content")
												.classList.toggle("-is-el-visible");
										}}
										data-el-toggle=".js-category-toggle"
										data-el-toggle-active=".js-category-active">
										<span className="js-dropdown-title">
											{sortBy?.length
												? categoriesSortingOptions.find((x) => x.id === sortBy[0])
														.title
												: "Sort By"}
										</span>
										<i className="icon text-9 ml-40 icon-chevron-down"></i>
									</div>

									<div
										id="dd61content"
										className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
										<div className="text-14 y-gap-15 js-dropdown-list">
											<div
												onClick={() => {
													setSortBy([]);
													handleSorting("");
													document
														.getElementById("dd61button")
														.classList.toggle("-is-dd-active");
													document
														.getElementById("dd61content")
														.classList.toggle("-is-el-visible");
												}}>
												<span
													className={`d-block js-dropdown-link cursor ${
														sortBy?.length === 0 ? "activeMenu" : ""
													} `}>
													Default
												</span>
											</div>
											{categoriesSortingOptions.map((elm, i) => (
												<div key={i} onClick={() => handleSortChange(elm.id)}>
													<span
														className={`d-block js-dropdown-link cursor ${
															sortBy?.[0] === elm.id ? "activeMenu" : ""
														} `}>
														{elm.title}
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
