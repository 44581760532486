import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { GET_TRAINERS_LIST } from "../../action/commonActions/types";
import { faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialState = {
	data: [],
	isLoading: false,
	isSuccess: false,
	isError: false,
	meta_data:{
        current: 1
    }
};

const trainersReducers = (state = initialState, action) => {
	switch (action.type) {
		case GET_TRAINERS_LIST: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						isLoading: true,
					};
				}
				case SUCCESS: {
					const updatedDataAccordingToTemplate = action?.payload?.data?.length
						? action.payload.data.map((item, index) => {
								return {
                                    ...item,
									id: item.id,
									name: item.user_first_name + ' ' + item.user_last_name,
									role: item?.designation ?? '--',
									image:item?.user_profile_image ?? '',
									rating: item?.ratings?.total_rating ?? 0,
									reviews: item?.ratings?.total_reviews_count ?? 0,
									students: item?.student_count ?? 0,
									courses: item?.courses_count ?? 0,
									socialProfile: [
										{
											icon: "icon-facebook",
											url: item?.facebook_link ?? '',
										},
										{
											icon: "icon-twitter",
											replaceIcon: <FontAwesomeIcon icon={faXTwitter} />,
											url: item?.twitter_link ?? '',
										},
										{
											icon: "icon-instagram",
											url: item?.instagram_link ?? '',
										},
										{
											icon: "icon-linkedin",
											url: item?.linkedin_link ?? '',
										},
										{
											icon: "icon-youtube",
											replaceIcon : <FontAwesomeIcon icon={faYoutube} />,
											url: action?.payload?.data?.youtube_channel_link ?? "",
										},
										{
											icon: "icon-website",
											replaceIcon: <FontAwesomeIcon icon={faWindowMaximize} />,
											url: action?.payload?.data?.website_link ?? "",
										},
									],
								};
						  })
						: [];
					return {
						...state,
						data: updatedDataAccordingToTemplate,
						isSuccess: true,
						isLoading: false,
						meta_data : action?.payload?.meta_data ?? {}
					};
				}
				case ERROR: {
					return {
						...state,
						isError: true,
						isLoading: false,
						isSuccess: false,
					};
				}

				default:
					return state;
			}
		}
		default:
			return state;
	}
};
export default trainersReducers;
