import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { getSuperAdminDetailData } from "../../../../redux/action/superAdminActions/superMyDetailsActions";
import SuperMyProfile from "./SuperMyProfile";
import SuperMyPassword from "./SuperMyPassword";

const buttons = [
	"Edit Profile",
	"Password",
];

export default function SuperMySettings() {
	const [activeTab, setActiveTab] = useState(1);

	const dispatch = useDispatch();

	const { data,isLoading } = useSelector((state) => state.superAdmin.superAdminDetail);

	useEffect(()=>{
		dispatch(getSuperAdminDetailData())
	},[dispatch])

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">My Settings</h1>
						<div className="mt-10">Welcome to your profile settings!.</div>
					</div>
				</div>

				<div className="row y-gap-30">
					<div className="col-12">
						{isLoading && <LinearProgress />}
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="tabs -active-purple-2 js-tabs pt-0">
								<div className="tabs__controls d-flex  x-gap-30 y-gap-20 flex-wrap items-center pt-10 px-30 border-bottom-light js-tabs-controls">
									{buttons.map((elm, i) => (
										<button
											key={i}
											onClick={() => setActiveTab(i + 1)}
											className={`tabs__button text-light-1 js-tabs-button ${
												activeTab === i + 1 ? "is-active" : ""
											} `}
											type="button">
											{elm}
										</button>
									))}
								</div>

								<div className="tabs__content py-30 px-30 js-tabs-content">
									<SuperMyProfile data={data} isLoading={isLoading} activeTab={activeTab}/>
									<SuperMyPassword activeTab={activeTab} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<LoggedInFooter />
		</div>
	);
}
