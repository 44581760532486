import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { LogIn } from "../../redux/action/userActions/userActions";
import { BUYER_ROLE_TYPE } from "../../config/config";
import { addCourseInCartAction } from "../../redux/action/cartActions/cartActions";

export const loginTabs = ["Student", "Educator"];

export default function CustomerLoginForm() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState(window.location.pathname === "/educator-login" ? "Educator" : "Student");

	const localStorageCart = localStorage.getItem("beauty_cart_items") ?? "[]";
	const localStorageCartItems = JSON.parse(localStorageCart);

	const handleSubmit = (event) => {
		event.preventDefault();

		// Accessing form values
		const email = event.target.elements.email.value;
		const password = event.target.elements.password.value;

		const passwordLengthChecked = password.trim().length < 3;
		if (passwordLengthChecked) {
			enqueueSnackbar("Password should be greater than 3 letters!", {
				variant: "warning",
			});
		} else {
			let body = {
				email,
				password,
				role: "USER",
			};
			dispatch(
				LogIn(body, {
					onLoading: (loading) => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						event.target.reset();
						localStorage.setItem("beauty_key", success.data.token.access);
						localStorage.setItem("loggedIn_user", JSON.stringify(success.data));
						if (
							success?.data?.role_permission?.role_type?.includes(
								BUYER_ROLE_TYPE
							)
						) {
							localStorage.setItem("beauty_seller", false);
							localStorage.setItem("beauty_buyer", true);
						} else {
							localStorage.setItem("beauty_seller", true);
							localStorage.setItem("beauty_buyer", false);
						}
						if (localStorageCartItems?.length) {
							let body = {
								course: localStorageCartItems.map((item) => item.id),
							};
							dispatch(addCourseInCartAction(body));
						}
						setTimeout(() => {
							window.location.replace("/");
						}, 1000);
					},
				})
			);
		}
	};
	return (
		<div className="form-page__content lg:py-50">
			<div className="container">
				<div className="tabs__controls flex-wrap  pt-50 d-flex justify-center x-gap-20 js-tabs-controls text-18">
					{loginTabs.map((elm, i) => (
						<div
							onClick={() => {
								setTab(elm);
							}}
							key={i}>
							<button
								className={`tabs__button px-25 py-12 rounded-8 js-tabs-button ${
									tab === elm ? "tabActive" : ""
								} `}
								data-tab-target=".-tab-item-2"
								type="button">
								{elm}
							</button>
						</div>
					))}
				</div>

				<div className="row justify-center items-center">
					<div className="col-xl-8 col-lg-10">
						<div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
							<h3 className="text-30 lh-13 text-center">
								{tab === "Student" ? "Student Login" : "Educator Login"}
							</h3>
							<p className="mt-10 text-center">
								{tab === "Student"
									? "Don't have a student account yet?"
									: "Don't have an educator account yet?"}{" "}
								&nbsp;
								<Link
									to={tab === "Student" ? "/signup" : "/trainer-sign-up"}
									className="text-purple-1">
									{tab === "Student"
									? "Student Sign up " : "Educator Sign up" }
								</Link>
							</p>

							<form
								className="contact-form respondForm__form row y-gap-20 pt-30"
								onSubmit={handleSubmit}>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Email
									</label>
									<input
										required
										type="text"
										autoComplete={"email"}
										name="email"
										placeholder="Enter your email here..."
									/>
								</div>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Password
									</label>
									<input
										required
										type="password"
										name="password"
										autoComplete="current-password"
										placeholder="Enter Password"
									/>
								</div>
								<div className="col-12">
									<button
										type="submit"
										name="submit"
										id="submit"
										className={`button -md ${
											loading ? "-blue-3" : "-green-1"
										} text-dark-1 fw-500 w-1/1`}
										disabled={loading}>
										{loading ? "Loading..." : "Login"}
									</button>
								</div>
							</form>

							<div className="lh-12 text-dark-1 fw-500 text-center mt-20">
								<Link to="/forgot-password" className="text-purple-1">
									Forgot password ?
								</Link>
							</div>

							{/*<div className="lh-12 text-dark-1 fw-500 text-center mt-20">*/}
							{/*    Or sign in using*/}
							{/*</div>*/}

							{/*<div className="d-flex x-gap-20 items-center justify-between pt-20">*/}
							{/*    <div>*/}
							{/*        <button className="button -sm px-24 py-20 -outline-blue-3 text-blue-3 text-14">*/}
							{/*            Log In via Facebook*/}
							{/*        </button>*/}
							{/*    </div>*/}
							{/*    <div>*/}
							{/*        <button className="button -sm px-24 py-20 -outline-red-3 text-red-3 text-14">*/}
							{/*            Log In via Google+*/}
							{/*        </button>*/}
							{/*    </div>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
