import React from "react";
import {
	instructorFilterRatingList,
	instructorSortingOptions,
} from "../../../../objectTemplates/objectTemplates";
import { Button } from "@mui/material";
import { PRIMARY_COLOR } from "../../../../config/config";

export default function SuperInstructorFilterAndSortingArea(props) {
	const {
		loading,
		handleApplyFilters,
		handleResetFilters,
		sortBy,
		setSortBy,
		handleSorting,
		filterRating,
		setFilterRating,
		getRatingStars,
	} = props;

	const handleFilterRating = (item) => {
        setFilterRating(item)
		document.getElementById("dd53button").classList.toggle("-is-dd-active");
		document.getElementById("dd53content").classList.toggle("-is-el-visible");
	};

	const handleSortChange = (item) => {
		if (sortBy.includes(item)) {
			setSortBy([]);
			handleSorting("");
		} else {
			setSortBy([item]);
			handleSorting(item);
		}
		document.getElementById("dd61button").classList.toggle("-is-dd-active");
		document.getElementById("dd61content").classList.toggle("-is-el-visible");
	};

	return (
		<>
			<div className="row y-gap-20 items-center justify-between pb-30">
				<div className="col-auto">
					<div className="row x-gap-20 y-gap-20">
						{/* Rating Filer Starts */}
						<div className="col-auto">
							<div
								id="dd53button"
								className="dropdown js-dropdown js-ratings-active">
								<div
									className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
									onClick={() => {
										document
											.getElementById("dd53button")
											.classList.toggle("-is-dd-active");
										document
											.getElementById("dd53content")
											.classList.toggle("-is-el-visible");
									}}
									data-el-toggle=".js-ratings-toggle"
									data-el-toggle-active=".js-ratings-active">
									<span className="js-dropdown-title">
										{filterRating ? filterRating : "Ratings"}
									</span>
									<i className="icon text-9 ml-40 icon-chevron-down"></i>
								</div>

								<div
									id="dd53content"
									className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-ratings-toggle">
									<div className="text-14 y-gap-15 js-dropdown-list">
										{instructorFilterRatingList.map((elm, i) => (
											<div
												key={i}
												onClick={() => handleFilterRating(elm.value)}
												className="sidebar-checkbox__item cursor d-flex">
												<div className="form-radio mr-10">
													<div className="radio">
														<input
															type="radio"
															readOnly
															checked={
																String(elm.value) === String(filterRating)
															}
														/>
														<div className="radio__mark">
															<div className="radio__icon"></div>
														</div>
													</div>
												</div>
												<div className="sidebar-checkbox__title d-flex items-center">
													<div className="d-flex pr-10">
														{getRatingStars(5)}
													</div>
													{elm.label}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						{/* Rating Filer Ends */}
					</div>
				</div>
				<div className="col-auto d-flex" style={{ justifyContent: "flex-end" }}>
					<div className="row x-gap-20 y-gap-20">
						<div className="col-auto d-flex">
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12 text-white"
								style={{ background: PRIMARY_COLOR }}
								disabled={loading}
								onClick={() => handleApplyFilters()}>
								{loading ? "Loading Data..." : "Apply Filters"}
							</Button>
							&emsp;
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12"
								style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
								variant="outlined"
								disabled={loading}
								onClick={() => handleResetFilters()}>
								{loading ? "Loading..." : "Reset Filters"}
							</Button>
						</div>
						<div className="col-auto">
							<div className="d-flex items-center">
								<div className="text-14 lh-12 fw-500 text-dark-1 mr-20">
									Sort by:
								</div>

								<div
									id="dd61button"
									className="dropdown js-dropdown js-category-active">
									<div
										className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
										onClick={() => {
											document
												.getElementById("dd61button")
												.classList.toggle("-is-dd-active");
											document
												.getElementById("dd61content")
												.classList.toggle("-is-el-visible");
										}}
										data-el-toggle=".js-category-toggle"
										data-el-toggle-active=".js-category-active">
										<span className="js-dropdown-title">
											{sortBy?.length
												? instructorSortingOptions.find((x) => x.id === sortBy[0])
														.title
												: "Sort By"}
										</span>
										<i className="icon text-9 ml-40 icon-chevron-down"></i>
									</div>

									<div
										id="dd61content"
										className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
										<div className="text-14 y-gap-15 js-dropdown-list">
											<div
												onClick={() => {
													setSortBy([]);
													handleSorting("");
													document
														.getElementById("dd61button")
														.classList.toggle("-is-dd-active");
													document
														.getElementById("dd61content")
														.classList.toggle("-is-el-visible");
												}}>
												<span
													className={`d-block js-dropdown-link cursor ${
														sortBy?.length === 0 ? "activeMenu" : ""
													} `}>
													Default
												</span>
											</div>
											{instructorSortingOptions.map((elm, i) => (
												<div key={i} onClick={() => handleSortChange(elm.id)}>
													<span
														className={`d-block js-dropdown-link cursor ${
															sortBy?.[0] === elm.id ? "activeMenu" : ""
														} `}>
														{elm.title}
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
