import MobileFooter from "./MobileFooter";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCourseFromLocalCartAction } from "../../../redux/action/cartActions/cartActions";

export default function HeaderMobileMenuArea(props) {
	const { setActiveMobileMenu, activeMobileMenu } = props;
	const [showMenu, setShowMenu] = useState(false);
	const [menuNesting, setMenuNesting] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const Categories = useSelector((state) => state.courses.categories.data);

	useEffect(() => {
		setShowMenu(true);
	}, []);

	const { pathname } = useLocation();

	const handleLogout = () => {
		localStorage.removeItem("beauty_key");
		localStorage.removeItem("beauty_cart_items");
		localStorage.removeItem("beauty_seller");
		localStorage.removeItem("beauty_buyer");
		localStorage.removeItem("loggedIn_user");
		dispatch(getCourseFromLocalCartAction())
	};

	return (
		<div
			className={`header-menu js-mobile-menu-toggle ${
				activeMobileMenu ? "-is-el-visible" : ""
			}`}>
			<div className="header-menu__content">
				<div className="mobile-bg js-mobile-bg"></div>

				<div className="d-none xl:d-flex items-center px-20 py-20 border-bottom-light">
					{parsedLoggedInData?.id ? (
						<Link
							to={"/my-dashboard"}
							className="button -md -purple-1 text-white col-12">
							Go To Dashboard
						</Link>
					) : (
						<>
							<Link
								to="/login"
								className={`text-dark-1 ${
									pathname === "/login" ? "activeMenu" : "inActiveMenu"
								} `}>
								Log in
							</Link>
							<Link
								to="/signup"
								className={`text-dark-1 ml-30 ${
									pathname === "/signup" ? "activeMenu" : "inActiveMenu"
								} `}>
								Sign Up
							</Link>
						</>
					)}
				</div>

				{showMenu && activeMobileMenu && (
					<div className="mobileMenu text-dark-1">
						{/*Courses start*/}
						<div key={"courses"} className="submenuOne">
							<div
								className="title"
								onClick={() => {
									setMenuNesting((pre) =>
										pre[0] === "courses" ? [] : ["courses"]
									);
									navigate(`/courses`);
								}}>
								<span
									className={
										pathname?.split("/")[1] === "courses" ? "activeMenu" : "inActiveMenu"
									}>
									Courses
								</span>
								<i
									className={
										menuNesting[0] === "courses"
											? "icon-chevron-right text-13 ml-10 active"
											: "icon-chevron-right text-13 ml-10"
									}></i>
							</div>
							{Categories.map((itm, index) => (
								<div
									key={index}
									className={
										menuNesting[0] === "courses" ? "toggle active" : "toggle"
									}>
									{itm.value && (
										<Link
											key={itm.value + "-" + index}
											className={
												pathname?.split("/")[1] === itm.value
													? "activeMenu link"
													: "link inActiveMenu"
											}
											to={{
												pathname: `/courses/category/${itm.id}`,
											}}>
											{itm.name}
										</Link>
									)}
								</div>
							))}
						</div>
						{/*Courses ends*/}

						{/*About us start*/}
						<div key={"about-us"} className="submenuOne">
							<div
								className="title"
								onClick={() =>
									setMenuNesting((pre) =>
										pre[0] === "about-us" ? [] : ["about-us"]
									)
								}>
								<span
									className={
										pathname === "about-us" ? "activeMenu" : "inActiveMenu"
									}>
									<Link
										to="/about-us"
										className={
											pathname === "/about-us"
												? "activeMenu"
												: "inActiveMenuTwo"
										}>
										About Us
									</Link>
								</span>
							</div>
						</div>
						{/*About us ends*/}

						{/*Contact start*/}
						<div key={"contact-us"} className="submenuOne">
							<div
								className="title"
								onClick={() =>
									setMenuNesting((pre) =>
										pre[0] === "contact-us" ? [] : ["contact-us"]
									)
								}>
								<span
									className={
										pathname === "contact-us" ? "activeMenu" : "inActiveMenu"
									}>
									<Link
										to="/contact-us"
										className={
											pathname === "/contact-us"
												? "activeMenu"
												: "inActiveMenuTwo"
										}>
										Contact Us
									</Link>
								</span>
							</div>
						</div>
						{/*Contact ends*/}

						{/* Logout Starts */}
						{parsedLoggedInData?.id ? (
							<div key={"logout"} className="submenuOne">
								<div className="title" onClick={() => handleLogout()}>
									<span className={"inActiveMenu"}>
										<Link>LogOut</Link>
									</span>
								</div>
							</div>
						) : null}
						{/* Logout ends */}
					</div>
				)}

				{/* mobile footer start */}
				<MobileFooter />
				{/* mobile footer end */}
			</div>

			<div
				className="header-menu-close"
				onClick={() => {
					setActiveMobileMenu(false);
				}}
				data-el-toggle=".js-mobile-menu-toggle">
				<div className="size-40 d-flex items-center justify-center rounded-full bg-white">
					<div className="icon-close text-dark-1 text-16"></div>
				</div>
			</div>

			<div
				className="header-menu-bg"
				onClick={() => setActiveMobileMenu(false)}></div>
		</div>
	);
}
