import {ADD_SUPER_INSTRUCTORS, GET_SUPER_INSTRUCTORS} from "../commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function AddSuperInstructorActions(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_SUPER_INSTRUCTORS, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.post(`${BASE_URL}users/createSellerUser`, body, config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_SUPER_INSTRUCTORS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: ADD_SUPER_INSTRUCTORS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getSuperInstructors(body,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_INSTRUCTORS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: '',
                page: body?.page !== undefined ? body.page : 1,
                rating: body?.rating || body?.rating === 0 ? body?.rating :'',
                search : body?.search ?? "",
                ordering: body?.ordering ?? ""
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}users/getUserListByAdmin`, Config);
            const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_INSTRUCTORS,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_INSTRUCTORS,
                status: ERROR,
                payload: error,
            });
        }
    };
}