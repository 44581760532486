/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import HeaderMenuArea from "../components/HeaderMenuArea";
import HeaderMobileMenuArea from "../components/HeaderMobileMenuArea";
import {customerProfileDropdownItems} from "../../common/profileDropdownItems";
import { Avatar } from "@mui/material";
import CartToggle from "../../common/CartToggle";
import { useDispatch } from "react-redux";
import { getCourseFromLocalCartAction, getCourseInCartAction } from "../../../redux/action/cartActions/cartActions";
import logo1 from '../../../images/logos/logo_icon.svg'

export default function Header() {
    const [activeMobileMenu, setActiveMobileMenu] = useState(false);
    const [isOnProfile, setIsOnProfile] = useState(false);
    const {pathname} = useLocation()
	const dispatch = useDispatch()

    const handleSideItemsClicked = (key) => {
        switch (key) {
            case "my-dashboard" :{
                window.location.replace('/my-dashboard')
                break;
            }
            case "logout": {
                localStorage.removeItem('beauty_key');
				localStorage.removeItem('beauty_cart_items');
				localStorage.removeItem('beauty_seller');
				localStorage.removeItem('beauty_buyer');
				localStorage.removeItem('loggedIn_user');
				dispatch(getCourseFromLocalCartAction())
                break;
            }
            default :
                return
        }
    }

    const loggedInUser = localStorage.getItem('loggedIn_user') ?? '{}'
	const parsedLoggedInData = JSON.parse(loggedInUser)

	const localStorageCart = localStorage.getItem('beauty_cart_items') ?? '[]'
	const localStorageCartItems = JSON.parse(localStorageCart)

	useEffect(() => {
		if (parsedLoggedInData.id) {
			dispatch(getCourseInCartAction());
		} else {
			dispatch(getCourseFromLocalCartAction(localStorageCartItems?.length ?localStorageCartItems :[]))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, parsedLoggedInData.id]);

    return (
			<>
				<header className="header -type-1 ">
					<div className="header__container">
						<div className="row justify-between items-center">
							<div className="col-auto">
								<div className="header-left">
									<div className="header__logo ">
										<Link to="/">
											<img
												src={logo1}
												alt="logo"
											/>
										</Link>
									</div>

									{/* header explore start */}
									{/*<HeaderExplore*/}
									{/*    allClasses={*/}
									{/*        "header__explore text-green-1 ml-60 xl:ml-30 xl:d-none"*/}
									{/*    }*/}
									{/*/>*/}
									{/* header explore end */}
								</div>
							</div>

							<HeaderMenuArea allClasses={"menu__nav text-white -is-active"} />
							<HeaderMobileMenuArea
								setActiveMobileMenu={setActiveMobileMenu}
								activeMobileMenu={activeMobileMenu}
							/>

							<div className="col-auto">
								<div className="header-right d-flex items-center">
									<div className="header-right__icons text-white d-flex items-center">
										{/* search toggle start */}
										{/*<SearchToggle />*/}
										{/* search toggle end */}

										{/* cart toggle start */}
										<CartToggle
										    parentClassess={"relative ml-30 xl:ml-20"}
										    allClasses={"d-flex items-center text-white"}
										/>
										{/* cart toggle end */}

										<div className="d-none xl:d-block ml-20">
											<button
												onClick={() => setActiveMobileMenu(true)}
												className="text-white items-center"
												data-el-toggle=".js-mobile-menu-toggle">
												<i className="text-11 icon icon-mobile-menu"></i>
											</button>
										</div>
									</div>

									<div className="header-right__buttons d-flex items-center ml-30 md:d-none">
										{localStorage.getItem("beauty_key") ? (
											<>
												<div
													className="relative d-flex items-center ml-10"
													onClick={() => setIsOnProfile((pre) => !pre)}>
													<a data-el-toggle=".js-profile-toggle">
														<Avatar
															src={JSON.parse(loggedInUser)?.profile_image ?? ""}
															alt={JSON.parse(loggedInUser)?.first_name ?? "profile"}
															sx={{ width: 56, height: 56 }}
															style={{cursor:'pointer'}}
														/>
													</a>

													<div
														className={`toggle-element js-profile-toggle ${
															isOnProfile ? "-is-el-visible" : ""
														} -`}>
														<div className="toggle-bottom -profile bg-white shadow-4 border-light rounded-8 mt-10">
															<div className="px-20 py-20">
																<div className="sidebar -dashboard">
																	{customerProfileDropdownItems.map(
																		(elm, i) => (
																			<div
																				key={i}
																				className={`sidebar__item ${
																					elm.key === pathname
																						? "-is-active -dark-bg-dark-2"
																						: ""
																				}`}
																				style={{
																					borderBottom:
																						i !==
																						customerProfileDropdownItems.length -
																							1
																							? "1px solid #ddd"
																							: "none",
																				}}>
																				<Link
																					to={""}
																					onClick={() =>
																						handleSideItemsClicked(elm.key)
																					}
																					className="d-flex items-center text-17 lh-1 fw-500 ">
																					<i className={elm.iconClass}></i>&nbsp;
																					{elm.text}
																				</Link>
																			</div>
																		)
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										) : (
											<>
												<Link
													to="/login"
													className="button -underline text-white">
													Log in
												</Link>
												<Link
													to="/signup"
													className="button -sm -white text-dark-1 ml-30">
													Sign up
												</Link>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</>
		);
}
