import React, { useEffect } from "react";
import {
	commonDashboardHeading,
	commonDashboardSecondaryHeading,
	commonDashboardSubHeading,
} from "../../../components/common/DashboardElements";
import { Box, Divider, LinearProgress } from "@mui/material";
import { LoggedInFooter } from "../../../components/layout/footers/LoggedInFooter";
import { useDispatch } from "react-redux";
import { getSuperDashboardData } from "./../../../redux/action/superAdminActions/superDashboardActions";
import { useSelector } from "react-redux";

export default function MySuperDashboard() {
	const dispatch = useDispatch();

	const { data: DashboardDetail, isLoading } = useSelector(
		(state) => state.superAdmin.superDashboard
	);

	useEffect(() => {
		dispatch(getSuperDashboardData());
	}, [dispatch]);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	const adminStates = [
		{
			id: 1,
			title: "This Month Profit",
			value: DashboardDetail?.admin?.this_month_sale_profit ?? 0,
			iconClass: "icon-coupon",
			sales: true,
		},
		{
			id: 2,
			title: "Total Sale Profit",
			value: DashboardDetail?.admin?.total_sale_profit ?? 0,
			iconClass: "icon-save-money",
			sales: true,
		},
	];

	const educatorsStates = [
		{
			id: 1,
			title: "Total Educators",
			value: DashboardDetail?.educator?.total_educator ?? 0,
			iconClass: "icon-person-3",
			sales: false,
		},
		{
			id: 2,
			title: "Active Educators",
			value: DashboardDetail?.educator?.active_educator ?? 0,
			iconClass: "icon-person-2",
			sales: false,
			textColor: "green",
		},
		{
			id: 3,
			title: "In Active Educators",
			value: DashboardDetail?.educator?.inactive_educator ?? 0,
			iconClass: "icon-person",
			sales: false,
			textColor: "red",
		},
		{
			id: 4,
			title: "Educators Joined This Month",
			value: DashboardDetail?.educator?.educator_joined_this_month ?? 0,
			iconClass: "icon-time-management",
			sales: false,
		},
		{
			id: 5,
			title: "Total Withdraw Amount",
			value: DashboardDetail?.educator?.total_withdraw_amount ?? 0,
			iconClass: "icon-coupon",
			sales: true,
		},
		{
			id: 6,
			title: "Total Amount in Clearing",
			value: DashboardDetail?.educator?.total_amount_in_clearing ?? 0,
			iconClass: "icon-list-2",
			sales: true,
		},
		{
			id: 7,
			title: "Total Withdraw Amount Left",
			value: DashboardDetail?.educator?.total_withdrawal_amount_left ?? 0,
			iconClass: "icon-book",
			sales: true,
		},
	];

	const studentsStates = [
		{
			id: 1,
			title: "Total Students",
			value: DashboardDetail?.student?.total_student ?? 0,
			iconClass: "icon-working-at-home-2",
			sales: false,
		},
		{
			id: 2,
			title: "Active Students",
			value: DashboardDetail?.student?.active_student ?? 0,
			iconClass: "icon-online-learning",
			sales: false,
			textColor: "green",
		},
		{
			id: 3,
			title: "In Active Students",
			value: DashboardDetail?.student?.inactive_student ?? 0,
			iconClass: "icon-person",
			sales: false,
			textColor: "red",
		},
		{
			id: 4,
			title: "Students Joined This Month",
			value: DashboardDetail?.student?.student_joined_this_month ?? 0,
			iconClass: "icon-time-management",
			sales: false,
		},
		{
			id: 5,
			title: "Total Courses Purchased",
			value: DashboardDetail?.student?.purchased_courses ?? 0,
			iconClass: "icon-online-learning-2",
			sales: false,
		},
		{
			id: 6,
			title: "Total Courses Purchased Amount",
			value: DashboardDetail?.student?.total_course_sell ?? 0,
			iconClass: "icon-online-learning-3",
			sales: true,
		},
	];

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50">
					<div className="col-auto">
						{commonDashboardHeading("Dashboard")}
						{commonDashboardSubHeading("Welcome to the Super Admin Dashboard.")}
					</div>
				</div>
				{/* Short Admin Start */}
				<div className="row y-gap-30">
					{commonDashboardSecondaryHeading("Overview")}
					{adminStates.map((elm, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<div
								className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
								style={{ marginTop: "-4px" }}>
								<div>
									<div className="lh-1 fw-500">{elm.title}</div>
									<div className="text-20 lh-1 fw-700 text-dark-1 mt-20">
										{elm.sales ? "$ " : null}
										{elm.value}
									</div>
								</div>
								<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
							</div>
						</div>
					))}
				</div>
				{/* Short Admin Ends */}
				<Divider style={{ margin: "20px 0px" }} />
				{/* Short Educators Start */}
				<div className="row y-gap-30">
					{commonDashboardSecondaryHeading("Educators Details")}
					{educatorsStates.map((elm, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<div
								className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
								style={{ marginTop: "-4px" }}>
								<div>
									<div className="lh-1 fw-500">{elm.title}</div>
									<div
										className={`text-20 lh-1 fw-700 ${
											elm?.textColor ? "" : "text-dark-1"
										} mt-20`}
										style={elm.textColor ? { color: elm.textColor } : {}}>
										{elm.sales ? "$ " : null}
										{elm.value}
									</div>
								</div>
								<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
							</div>
						</div>
					))}
				</div>
				{/* Short Educators Ends */}
				<Divider style={{ margin: "20px 0px" }} />
				{/* Short Students Start */}
				<div className="row y-gap-30">
					{commonDashboardSecondaryHeading("Students Details")}
					{studentsStates.map((elm, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<div
								className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
								style={{ marginTop: "-4px" }}>
								<div>
									<div className="lh-1 fw-500">{elm.title}</div>
									<div
										className={`text-20 lh-1 fw-700 ${
											elm?.textColor ? "" : "text-dark-1"
										} mt-20`}
										style={elm.textColor ? { color: elm.textColor } : {}}>
										{elm.sales ? "$ " : null}
										{elm.value}
									</div>
								</div>
								<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
							</div>
						</div>
					))}
				</div>
				{/* Short Students Ends */}
			</div>

			<LoggedInFooter />
		</div>
	);
}
