import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderMenuArea from "../components/HeaderMenuArea";
import HeaderMobileMenuArea from "../components/HeaderMobileMenuArea";
import logo1 from "../../../images/logos/logo_full.svg";

export default function HeaderAuth(props) {
	const location = useLocation();
	const {headerRef} = props;

	const isSignUpPage = location.pathname.includes("signup");

	const [activeMobileMenu, setActiveMobileMenu] = useState(false);
	return (
		<header
			className="header -base js-header"
			ref={headerRef}
			style={{ borderBottom: "1px solid #D4D4D4", background: "white" }}>
			<div className="header__container py-10">
				<div className="row justify-between items-center">
					<div className="col-auto">
						<div className="header-left">
							<div className="header__logo ">
								<Link data-barba to="/">
									<img
										src={logo1}
										alt="logo"
									/>
								</Link>
							</div>
						</div>
					</div>

					<div className="col-auto">
						<div className="header-right d-flex items-center">
							<HeaderMenuArea allClasses={"menu__nav text-dark-1 -is-active"} />
							<HeaderMobileMenuArea
								activeMobileMenu={activeMobileMenu}
								setActiveMobileMenu={setActiveMobileMenu}
							/>

							<div className="mr-30">
								<div className="d-none xl:d-block ml-20">
									<button
										className="text-dark-1 items-center"
										data-el-toggle=".js-mobile-menu-toggle"
										onClick={() => setActiveMobileMenu(true)}>
										<i className="text-11 icon icon-mobile-menu"></i>
									</button>
								</div>
							</div>

							<div className="header-right__buttons md:d-none">
								<a
									href={isSignUpPage ? "/login" : "/signup"}
									className="button -sm -rounded -dark-1 text-white">
									{isSignUpPage ? "Log In" : "Sign Up"}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
