import React, { useState } from "react";

export default function BecomeAnInstructorTabs() {
	const [activeTab, setActiveTab] = useState(1);
	return (
		<div className="row justify-center">
			<div className="col-xl-8 col-lg-9 col-md-11">
				<div className="tabs -active-purple-2 js-tabs">
					<div className="tabs__controls d-flex js-tabs-controls">
						<button
							onClick={() => setActiveTab(1)}
							className={`tabs__button js-tabs-button ${
								activeTab === 1 ? "is-active" : ""
							} `}
							data-tab-target=".-tab-item-1"
							type="button">
							Become an Educator
						</button>
						<button
							onClick={() => setActiveTab(2)}
							className={`tabs__button js-tabs-button ml-30 ${
								activeTab === 2 ? "is-active" : ""
							} `}
							data-tab-target=".-tab-item-2"
							type="button">
							Educator Commission
						</button>
						<button
							onClick={() => setActiveTab(3)}
							className={`tabs__button js-tabs-button ml-30 ${
								activeTab === 3 ? "is-active" : ""
							} `}
							data-tab-target=".-tab-item-3"
							type="button">
							Holding Period for Withdrawals
						</button>
					</div>

					<div className="tabs__content pt-60 lg:pt-40 js-tabs-content">
						<div
							className={`tabs__pane -tab-item-1 ${
								activeTab === 1 ? "is-active" : ""
							} `}>
							<p className="text-light-1">
								Welcome to Pro Beauty Port! We’re thrilled to have you here.
								Whether you’re a makeup artist, hairstylist, esthetician, lash
								tech, nail tech, natural hairstylist, or barber, this platform
								is designed specifically for professionals like you to share
								your expertise with the world. At Pro Beauty Port, you’ll find a
								supportive community of like-minded individuals eager to learn
								and grow in the beauty industry.
								<br />
								<br />
								Our dedicated website provides you with the perfect digital
								canvas to create and sell courses, reaching a broad audience of
								beauty enthusiasts seeking to expand their knowledge and skills.
								Join us at Pro Beauty Port and showcase your expertise to
								inspire beauty enthusiasts worldwide!
							</p>
						</div>
						<div
							className={`tabs__pane -tab-item-2 ${
								activeTab === 2 ? "is-active" : ""
							} `}>
							<p className="text-light-1">
								At our E-learning platform, we prioritize our educators'
								success. For every course sold, educators receive 80% of the
								revenue, while we retain a 20% commission to support our
								platform’s maintenance and development. This structure ensures
								that you are rewarded generously for your expertise and effort,
								allowing you to focus on creating and sharing valuable content
								with your students.
							</p>
						</div>
						<div
							className={`tabs__pane -tab-item-3 ${
								activeTab === 3 ? "is-active" : ""
							} `}>
							<p className="text-light-1">
								Please note that our payment gateway provider holds the funds
								for 14 days from the date of purchase. This holding period is a
								standard practice to ensure payment security and processing
								accuracy. During this time, you can track how many days remain
								until withdrawal in your profile dashboard. Once the funds are
								available for withdrawal, this will be reflected in your
								dashboard, and you will receive a notification. This ensures a
								smooth and reliable payment experience.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
