import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	getTrainerDetailsAction,
	updateTrainerDetailsAction,
} from "../../../../../../redux/action/trainerActions/trainerActions";
import { useSnackbar } from "notistack";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Select from "react-select";

export default function EditSellerAddress({ activeTab }) {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [countriesList, setCountriesList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [countryLoading, setCountryLoading] = useState(false);
	const [stateLoading, setStateLoading] = useState(false);
	const [cityLoading, setCityLoading] = useState(false);

	const { data: instructorDetails, isLoading } = useSelector(
		(state) => state.trainers.singleTrainer
	);

	const { register, handleSubmit, setValue, control } = useForm({
		defaultValues: {
			street: "",
			pin_code: "",
			city: "",
			city_id: "",
			state: "",
			state_id: "",
			country: "",
			country_id: "",
		},
	});

	const street = useWatch({ control, name: "street" });
	const pin_code = useWatch({ control, name: "pin_code" });
	const city_id = useWatch({ control, name: "city_id" });
	const city = useWatch({ control, name: "city" });
	const state_id = useWatch({ control, name: "state_id" });
	const state = useWatch({ control, name: "state" });
	const country_id = useWatch({ control, name: "country_id" });
	const country = useWatch({ control, name: "country" });

	useEffect(() => {
		setCountryLoading(true);
		GetCountries().then((result) => {
			const updateResult = result?.length
				? result.map((item) => ({
						...item,
						value: item.id,
						label: item.name,
				  }))
				: [];
			setCountriesList(updateResult);
			setCountryLoading(true);
		});
	}, []);

	useEffect(() => {
		setValue("street", instructorDetails?.street ?? "");
		setValue("pin_code", instructorDetails?.pin_code ?? "");
		setValue("city", instructorDetails?.city?? "");
		setValue("state", instructorDetails?.state ?? "");
		setValue("country", instructorDetails?.country ?? "");
		setValue("city_id", instructorDetails?.city_id ?? "");
		setValue("state_id", instructorDetails?.state_id ?? "");
		setValue("country_id", instructorDetails?.country_id ?? "");
	}, [instructorDetails, setValue]);

	const onSubmitBasicDetails = (e) => {
		setLoading(true);
		let body = {
			street: e.street,
			pin_code: e.pin_code,
			city: e.city_id ? e.city : null,
			state: e.state_id ? e.state : null,
			country: e.country_id ? e.country : null,
			city_id: e.city_id ? e.city_id : null,
			state_id: e.state_id ? e.state_id : null,
			country_id: e.country_id ? e.country_id : null,
		};

		dispatch(
			updateTrainerDetailsAction(body, instructorDetails.slug_name, {
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					let newData = {
						...instructorDetails,
						...success.data.user,
						slug_name: success.data.slug_name,
					};
					localStorage.setItem("loggedIn_user", JSON.stringify(newData));
					dispatch(getTrainerDetailsAction(success.data.slug_name));
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
			})
		);
	};

	const handleCountryChange = (selectedCountry) => {
		setStateLoading(true);
		setCityLoading(true);
		const country = countriesList.find((item) => item.id === selectedCountry.id);
		setValue("country_id", country.value);
		setValue("country", country.label);
		setValue("state_id", null);
		setValue("city_id", null);
		setValue("state", null);
		setValue("city", null);
		GetState(country.id).then((result) => {
		  const updateResult = result?.length
			? result.map((item) => ({
				...item,
				value: item.id,
				label: item.name,
			  }))
			: [];
		  setStateList(updateResult);
		  setCityList([]);
		  setStateLoading(false);
		  setCityLoading(false);
		});
	  };

	  const handleStateChange = (selectedState) => {
			setCityLoading(true)
			const state = stateList.find((item) => item.id === selectedState.id);
			setValue("state_id", state.value);
			setValue("state", state.label);
			setValue("city_id", null);
			setValue("city", null);
			GetCity(country_id, state.id).then((result) => {
				const updateResult = result?.length
					? result.map((item) => ({
							...item,
							value: item.id,
							label: item.name,
					  }))
					: [];
				setCityList(updateResult);
				setCityLoading(false)
			});
		};

	return (
		<div
			className={`tabs__pane -tab-item-2 ${
				activeTab === 4 ? "is-active" : ""
			} `}>
			<form
				onSubmit={handleSubmit(onSubmitBasicDetails)}
				className="contact-form row y-gap-30">
				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Street
					</label>

					<input
						{...register("street")}
						type="text"
						required
						placeholder="Street"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">City</label>
					<Select
						searchable
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								height: "55px",
							}),
						}}
						onChange={(e) => {
							const city = cityList.find((item) => item.id === e.id);
							setValue("city_id", city.value);
							setValue("city", city.label);
						}}
						options={cityList}
						value={
							city_id
								? {
										label: city,
										value: city_id,
								  }
								: null
						}
						placeholder={"Select City"}
						isLoading={cityLoading}
						loadingMessage={() => "Loading. Please wait..."}
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Zip Code
					</label>

					<input
						{...register("pin_code")}
						type="text"
						required
						autoComplete={"tel"}
						placeholder="Zip Code"
						maxLength={7}
						minLength={3}
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">State</label>

					<Select
						searchable
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								height: "55px",
							}),
						}}
						onChange={handleStateChange}
						options={stateList}
						value={
							state_id
								? {
										label: state,
										value: state_id,
								  }
								: null
						}
						placeholder={"Select State"}
						isLoading={stateLoading}
						loadingMessage={() => "Loading. Please wait..."}
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Country
					</label>

					<Select
						required
						searchable
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								height: "55px",
							}),
						}}
						onChange={handleCountryChange}
						options={countriesList}
						value={
							country_id
								? {
										label: country,
										value: country_id,
								  }
								: null
						}
						placeholder={"Select Country"}
						isLoading={countryLoading}
						loadingMessage={() => "Loading. Please wait..."}
					/>
				</div>

				<div className="col-12">
					{street.trim().length ||
					pin_code.trim().length ||
					city_id.trim().length ||
					state_id.trim().length ||
					country_id.trim().length ? (
						<Button
							className="button -md -purple-1 text-white"
							style={{ textTransform: "inherit" }}
							type="submit"
							onClick={handleSubmit(onSubmitBasicDetails)}
							disabled={isLoading || loading}>
							Save Address
						</Button>
					) : (
						<span className="text-purple-1">Please fill fields to update</span>
					)}
				</div>
			</form>
		</div>
	);
}
