import React from "react";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {LogIn} from "../../../redux/action/userActions/userActions";

export default function SuperAdminLoginForm() {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    const handleSuccess = (successData) => {
        const super_token = successData.data.token.access ?? "";
        localStorage.setItem("beauty_super_key", super_token)
        window.location.replace('dashboard')
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        // Accessing form values
        const email = event.target.elements.email.value;
        const password = event.target.elements.password.value;

        const passwordLengthChecked = password.trim().length < 3;
        if (passwordLengthChecked) {
            enqueueSnackbar('Password should be greater than 3 letters!', {variant: 'warning'});
        } else {
            let body = {
                email,
                password,
                role: 'SUPER_ADMIN'
            }
            dispatch(LogIn(body, {
                onLoading: (loading) => {
                    enqueueSnackbar('Loading...', {variant: 'info'});
                },
                onError: (error) => {
                    let errorMessage = String(error?.message ?? 'Something went wrong');
                    enqueueSnackbar(errorMessage, {variant: 'error'});
                },
                onSuccess: (success) => {
                    handleSuccess(success)
                    enqueueSnackbar(success.message[0], {variant: 'success'});
                    event.target.reset();
                }
            }))
        }

    };
    return (
        <div className="form-page__content">
            <div className="container">
                <div className="row justify-center items-center">
                    <div className="col-xl-8 col-lg-10">
                        <div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
                            <h3 className="text-30 lh-13 text-center">Login</h3>

                            <form
                                className="contact-form respondForm__form row y-gap-20 pt-30"
                                onSubmit={handleSubmit}
                            >
                                <div className="col-12">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Email
                                    </label>
                                    <input required type="text" autoComplete={'email'} name="email"
                                           placeholder="Enter your email here..."/>
                                </div>
                                <div className="col-12">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Password
                                    </label>
                                    <input
                                        required
                                        type="password"
                                        name="password"
                                        autoComplete='current-password'
                                        placeholder="Enter Password"
                                    />
                                </div>
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        name="submit"
                                        id="submit"
                                        className="button -md -green-1 text-dark-1 fw-500 w-1/1"
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
