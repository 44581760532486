import React, {useCallback, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, Typography, useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import { deleteSuperContactUsDataAction, getSuperContactUsListData } from "../../../../redux/action/superAdminActions/superContactUsListActions";
import { commonDashboardHeading, commonDashboardSubHeading } from "../../../../components/common/DashboardElements";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperContactUs() {

    const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

    const fetchContactUs = useCallback(() => {
		dispatch(getSuperContactUsListData());
	}, [dispatch]);

	useEffect(() => {
		fetchContactUs();
	}, [fetchContactUs]);

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const {
		data: ContactUsList,
		isLoading,
        meta_data
	} = useSelector((state) => state.superAdmin.superContactUsList);

    const handlePageChange = (value) => {
		const body = {
			page: value,
		};
		dispatch(getSuperContactUsListData(body));
	};

	const handleDelete =(contactId)=>{
		dispatch(
			deleteSuperContactUsDataAction(contactId, {
				onSuccess: (success) => {
					enqueueSnackbar("Contact Data Deleted Successfully...", {
						variant: "success",
					});
					dispatch(getSuperContactUsListData());
				},
			})
		);
	}

    const fullColumns = [
		{
			field: "name",
			width: 100,
			sortable: false,
			flex: 0.75,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Name"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 0.75,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Email"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.email ?? '--'}
					</div>
				);
			},
		},
		{
			field: "message",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Message"}</Typography>,
			renderCell: (params) => {
				return (
					<div
						style={{
							maxHeight: '100px',  
							overflowY: 'auto',    
							whiteSpace: 'normal', 
							wordBreak: 'break-word' 
						}}
					>
						<b>{params?.row?.message ?? '--'}</b>
					</div>
				);
			},
		},
        {
			field: "created_at",
			width: 50,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Created At"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{moment(params?.row?.created_at).format("DD-MM-YYYY") ?? '--'}
					</div>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex" style={{margin:'auto'}}>
						<Popconfirm
							title="Are you sure to delete?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "name",
			width: 100,
			sortable: false,
			flex: 0.75,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Name"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex" style={{margin:'auto'}}>
						<Popconfirm
							title="Are you sure to delete?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

    return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4" style={{paddingBottom:'0px'}}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("Contact Us list")}
						{commonDashboardSubHeading("Welcome to the Contact Us List Section.")}
					</div>
					<div className="col-auto ms-auto">
						{/* <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
							startIcon={<Add />}
							onClick={() => setOpenAddForm(true)}>
							Add Category
						</Button> */}
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(98vh - 260px)",
					}}>
					<DataGrid
						rows={ContactUsList}
						columns={columns}
                        rowHeight={100}
                        showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter/>
		</div>
	);
}
