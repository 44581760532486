import React from "react";
import { paymentSortingOptions } from "../../../../objectTemplates/objectTemplates";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { PRIMARY_COLOR } from "../../../../config/config";
import { Empty } from "antd";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
	"& .MuiInputBase-root": {
		height: "50px", // Change the height here
		backgroundColor: "#eef2f6", // Change the background color here
		color: "#4f547b", // Change the text color here
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none", // Change the border color here
	},
}));

export default function SuperPaymentFilterAndSortingArea(props) {
	const {
		loading,
		handleApplyFilters,
		handleResetFilters,
		sortBy,
		setSortBy,
		handleSorting,
		filterSeller,
		setFilterSeller,
		filterPurchasedDate,
		setFilterPurchasedDate,
		InstructorList,
	} = props;

	const handleFilterInstructors = (item) => {
		setFilterSeller(item);
		if (String(filterSeller) === String(item)) {
			setFilterSeller(0);
		} else {
			setFilterSeller(item);
		}
		document.getElementById("dd52button").classList.toggle("-is-dd-active");
		document.getElementById("dd52content").classList.toggle("-is-el-visible");
	};

	const handleSortChange = (item) => {
		if (sortBy.includes(item)) {
			setSortBy([]);
			handleSorting("");
		} else {
			setSortBy([item]);
			handleSorting(item);
		}
		document.getElementById("dd61button").classList.toggle("-is-dd-active");
		document.getElementById("dd61content").classList.toggle("-is-el-visible");
	};

	const getLabel = () => {
		let selectedInstructor =
			InstructorList?.find(
				(item) => String(item.seller_id) === String(filterSeller)
			) ?? {};

		if (selectedInstructor.seller_id) {
			return `${selectedInstructor?.user_first_name ?? ""} ${
				selectedInstructor?.user_last_name ?? ""
			}`;
		} else {
			return "Instructor";
		}
	};

	return (
		<>
			<div className="row y-gap-20 items-center justify-between pb-30">
				<div className="col-auto">
					<div className="row x-gap-20 y-gap-20">
						{/* Instructors Filer Starts */}
						<div className="col-auto">
							<div
								id="dd52button"
								className="dropdown js-dropdown js-category-active">
								<div
									className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
									onClick={() => {
										document
											.getElementById("dd52button")
											.classList.toggle("-is-dd-active");
										document
											.getElementById("dd52content")
											.classList.toggle("-is-el-visible");
									}}
									data-el-toggle=".js-category-toggle"
									data-el-toggle-active=".js-category-active">
									<span className="js-dropdown-title">
										{filterSeller ? getLabel() : "Instructors"}
									</span>
									<i className="icon text-9 ml-40 icon-chevron-down"></i>
								</div>

								<div
									id="dd52content"
									className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
									<div className="text-14 y-gap-15 js-dropdown-list">
										{InstructorList?.length ? (
											InstructorList.map((elm, i) => (
												<div
													key={i}
													onClick={() =>
														handleFilterInstructors(String(elm.seller_id))
													}
													className="sidebar-checkbox__item cursor d-flex">
													<div className="form-checkbox mr-10">
														<input
															type="radio"
															readOnly
															checked={
																String(filterSeller) === String(elm.seller_id)
															}
														/>
														<div className="form-checkbox__mark">
															<div className="form-checkbox__icon icon-check"></div>
														</div>
													</div>

													<div className="sidebar-checkbox__title">
														{elm?.user_first_name ?? ""}{" "}
														{elm?.user_last_name ?? ""}
													</div>
												</div>
											))
										) : (
											<Empty description="No Data Found" />
										)}
									</div>
								</div>
							</div>
						</div>
						{/* Instructors Filer Ends */}
						{/* Date Filter Starts  */}
						<div className="col-auto">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomDatePicker
									value={filterPurchasedDate}
									onChange={(newValue) => setFilterPurchasedDate(newValue)}
								/>
							</LocalizationProvider>
						</div>
						{/* Date Filter Ends  */}
					</div>
				</div>
				<div className="col-auto d-flex" style={{ justifyContent: "flex-end" }}>
					<div className="row x-gap-20 y-gap-20">
						<div className="col-auto d-flex">
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12 text-white"
								style={{ background: PRIMARY_COLOR }}
								disabled={loading}
								onClick={() => handleApplyFilters()}>
								{loading ? "Loading Data..." : "Apply Filters"}
							</Button>
							&emsp;
							<Button
								component="label"
								className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 lh-12"
								style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
								variant="outlined"
								disabled={loading}
								onClick={() => handleResetFilters()}>
								{loading ? "Loading..." : "Reset Filters"}
							</Button>
						</div>
						<div className="col-auto">
							<div className="d-flex items-center">
								<div className="text-14 lh-12 fw-500 text-dark-1 mr-20">
									Sort by:
								</div>

								<div
									id="dd61button"
									className="dropdown js-dropdown js-category-active">
									<div
										className="dropdown__button d-flex items-center text-14 rounded-8 px-20 py-10 text-14 lh-12"
										onClick={() => {
											document
												.getElementById("dd61button")
												.classList.toggle("-is-dd-active");
											document
												.getElementById("dd61content")
												.classList.toggle("-is-el-visible");
										}}
										data-el-toggle=".js-category-toggle"
										data-el-toggle-active=".js-category-active">
										<span className="js-dropdown-title">
											{sortBy?.length
												? paymentSortingOptions.find((x) => x.id === sortBy[0])
														.title
												: "Sort By"}
										</span>
										<i className="icon text-9 ml-40 icon-chevron-down"></i>
									</div>

									<div
										id="dd61content"
										className="toggle-element -dropdown -dark-bg-dark-2 -dark-border-white-10 js-click-dropdown js-category-toggle">
										<div className="text-14 y-gap-15 js-dropdown-list">
											<div
												onClick={() => {
													setSortBy([]);
													handleSorting("");
													document
														.getElementById("dd61button")
														.classList.toggle("-is-dd-active");
													document
														.getElementById("dd61content")
														.classList.toggle("-is-el-visible");
												}}>
												<span
													className={`d-block js-dropdown-link cursor ${
														sortBy?.length === 0 ? "activeMenu" : ""
													} `}>
													Default
												</span>
											</div>
											{paymentSortingOptions.map((elm, i) => (
												<div key={i} onClick={() => handleSortChange(elm.id)}>
													<span
														className={`d-block js-dropdown-link cursor ${
															sortBy?.[0] === elm.id ? "activeMenu" : ""
														} `}>
														{elm.title}
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
