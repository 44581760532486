import React from 'react';
import {COMPANY_NAME} from "../../config/config";
import Preloader from "../../components/common/Preloader";
import MetaComponent from "../../components/common/MetaComponent";
import Header from "../../components/layout/headers/Header";
import HomePageHero from "./heroes/HomePageHero";
import TopCategories from "./categories/TopCategories";
import MostPopularCourses from "./courses/MostPopularCourses";
import Testimonials from "../../components/others/testimonials/Testimonials";
import CourseFeatures from "./features/CourseFeatures";
import WhyOurCourses from "./features/WhyOurCourses";
import Instructors from "../../components/common/Instructors";
import Footer from "../../components/layout/footers/Footer";

const metadata = {
    title:
        `${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function HomePage() {

    return (
        <>
            <Preloader/>
            <MetaComponent meta={metadata}/>
            <Header/>

            <div className="content-wrapper  js-content-wrapper overflow-hidden">
                <HomePageHero/>
                <TopCategories/>
                <MostPopularCourses/>
                <Testimonials/>
                <CourseFeatures/>
                <WhyOurCourses/>
                <Instructors/>
                <Footer/>
            </div>
        </>
    );
}