import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import flexibleLearningImage from '../../../images/homepage/flexibleLearning/flexibleLearning.png'

const featureOne = [
	{
		id: 1,
		title: "Diverse course selection",
	},
	{
		id: 2,
		title: "Expert educators",
	},
    {
		id: 5,
		title: "Certification upon completion",
	},
	{
		id: 7,
		title: "User-friendly interface",
	},
	{
		id: 8,
		title: "Exclusive events and workshops",
	},
];

export default function CourseFeatures() {
	return (
		<section className="layout-pt-lg layout-pb-lg bg-beige-1">
			<div className="container">
				<div className="row y-gap-30 justify-between items-center">
					<div className="col-xl-5 col-lg-6 col-md-10 order-2 order-lg-1">
						<div className="about-content">
							<h2
								// className="about-content__title customSized"
								className="sectionTitle__title_2 customSized"
								data-aos="fade-up">
								<span>Flexible</span> Learning, Exceptional Results.
							</h2>
							<p className="about-content__text" data-aos="fade-up">
								Here are several reasons why you should join Pro Beauty Port
								today!
							</p>
							<div className="y-gap-20 pt-30">
								{featureOne.map((elm, i) => (
									<div
										key={i}
										className="d-flex items-center"
										data-aos="fade-up">
										<div className="about-content-list__icon">
											<span
												className="text-white"
												style={{
													fontSize: "10px",
													fontWeight: "300",
												}}
												aria-hidden="true">
												<FontAwesomeIcon icon={faCheck} />
											</span>
										</div>
										<div className="about-content-list__title">{elm.title}</div>
									</div>
								))}
							</div>

							<div className="d-inline-block mt-30">
								<Link to="/signup" className="button -md -dark-1 text-white">
									Start Learning
								</Link>
							</div>
						</div>
					</div>

					<div
						className="col-xl-5 col-lg-6 order-1 order-lg-2"
						data-aos="fade-up">
						<div className="about-image">
							<img
								style={{ height: "100%", width: "100%" }}
								src={flexibleLearningImage}
								alt="about"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
