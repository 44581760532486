// --------LIBRARIES--------
import { combineReducers } from "redux";
import sellerCourseReducer from "./sellerCourses";
import sellerChaptersReducer from "./sellerChapters";
import sellerCourseDetailsReducer from "./sellerCourseDetails";
import userDashboardReducer from "./userDashboard";
import sellerDashboardReducer from "./sellerDashboard";
import dashboardReducers from './dashboardReducers';
import sellerCoursePreviewReducer from "./sellerCoursePreview";

export default combineReducers({
    sellerCourses : sellerCourseReducer,
    sellerChapters : sellerChaptersReducer,
    sellerCourseDetails : sellerCourseDetailsReducer,
    userDashboard : userDashboardReducer,
    sellerDashboard : sellerDashboardReducer,
    dashboard: dashboardReducers,
    sellerCoursePreview : sellerCoursePreviewReducer
});