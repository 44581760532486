export const TESTIMONIAL_DEFAULT_TEMPLATE = {
	id: 0,
	imageSrc: "",
	name: "",
	position: "",
	comment: "",
	description: "",
};

export const audioLanguageArray = [
	{ value: "1", label: "English" },
	{ value: "2", label: "Hindi" },
	{ value: "3", label: "French" },
	{ value: "4", label: "Greek" },
];

export const courseLevelArray = [
	{ value: "BEGINNER", label: "Beginner" },
	{ value: "INTERMEDIATE", label: "Intermediate" },
	{ value: "EXPERT", label: "Expert" },
];

export const levels = [
	{ id: "BEGINNER", title: "Beginner", value: "BEGINNER" },
	{ id: "INTERMEDIATE", title: "Intermediate", value: "INTERMEDIATE" },
	{ id: "EXPERT", title: "Expert", value: "EXPERT" },
];

export const prices = [
	{ id: 1, title: "All", value: "1" },
	{ id: 2, title: "Free", value: "2" },
	{ id: 3, title: "Paid", value: "3" },
];

export const ratingList = [
	{ id: 1, star: 5, text: "4.5 & up", range: [4.5, 5], value: "4.5" },
	{ id: 2, star: 5, text: "4.0 & up", range: [4, 5], value: "4.0" },
	{ id: 3, star: 5, text: "3.5 & up", range: [3.5, 5], value: "3.5" },
	{ id: 4, star: 5, text: "3.0 & up", range: [3, 5], value: "3.0" },
];

export const ratingListWithAll = [
	{ id: 0, star: 5, text: "All", range: [], value: "0" },
	{ id: 1, star: 5, text: "4.5 & up", range: [4.5, 5], value: "4.5" },
	{ id: 2, star: 5, text: "4.0 & up", range: [4, 5], value: "4.0" },
	{ id: 3, star: 5, text: "3.5 & up", range: [3.5, 5], value: "3.5" },
	{ id: 4, star: 5, text: "3.0 & up", range: [3, 5], value: "3.0" },
];

export const durations = [
	{ id: 1, title: "Less than 4 hours", range: [0, 239], value: "4" },
	{ id: 2, title: "4 - 7 hours", range: [240, 419], value: "5" },
	{ id: 3, title: "7 - 18 hours", range: [420, 1079], value: "6" },
	{ id: 4, title: "20 + Hours", range: [1080, 5000], value: "7" },
];

export const studentStatusList = [
	{ id: "ACTIVE", title: "Active" },
	{ id: "INACTIVE", title: "In-Active" },
];

export const studentSortingOptions = [
	{ id: "user_name", title: "Name (asc)" },
	{ id: "-user_name", title: "Name (dsc)" },
	{ id: "course_take", title: "Course Taken" },
	{ id: "date_joined", title: "Date Joined" },
];

export const instructorSortingOptions = [
	{ id: "user_name", title: "Name (asc)" },
	{ id: "-user_name", title: "Name (dsc)" },
	{ id: "courses_count", title: "Courses Count (inc)" },
	{ id: "-courses_count", title: "Courses Count (dec)" },
	{ id: "student_count", title: "Student Count (inc)" },
	{ id: "-student_count", title: "Student Count (dec)" },
];

export const instructorFilterRatingList = [
	{
		label: "All",
		value: 0,
		count: 0,
	},
	{
		label: "4.5",
		value: 4.5,
		count: 0,
	},
	{
		label: "4",
		value: 4,
		count: 0,
	},
	{
		label: "3.5",
		value: 3.5,
		count: 0,
	},
	{
		label: "3",
		value: 3,
		count: 0,
	},
];

export const paymentSortingOptions = [
	{ id: "purchased_date", title: "Purchased Date" },
	{ id: "amount", title: "Course Price (inc)" },
	{ id: "-amount", title: "Course Price (dec)" },
	{ id: "admin_amount", title: "Admin Amount (inc)" },
	{ id: "-admin_amount", title: "Admin Amount (dec)" },
];

export const categoriesSortingOptions = [
	{ id: "name", title: "Name (asc)" },
	{ id: "-name", title: "Name (dsc)" },
	{ id: "available_course_count", title: "Courses Count (inc)" },
	{ id: "-available_course_count", title: "Courses Count (dec)" },
	{ id: "sub_category_count", title: "Sub Category Count (inc)" },
	{ id: "-sub_category_count", title: "Sub Category Count (dec)" },
];

export const subCategoriesSortingOptions = [
	{ id: "name", title: "Name (asc)" },
	{ id: "-name", title: "Name (dsc)" },
	{ id: "category", title: "Category (asc)" },
	{ id: "-category", title: "Category (dsc)" },
	{ id: "available_course_count", title: "Courses Count (inc)" },
	{ id: "-available_course_count", title: "Courses Count (dec)" },
];

export const allCoursesSortingOptions = [
	{ id: "sale_price", title: "Sale Price (inc)" },
	{ id: "-sale_price", title: "Sale Price (dec)" },
	{ id: "duration", title: "Duration (inc)" },
	{ id: "-duration", title: "Duration (dec)" },
	{ id: "rating", title: "Rating (inc)" },
	{ id: "-rating", title: "Rating (dec)" },
];