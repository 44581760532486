import {ADD_SUPER_SUB_CATEGORIES, GET_SUPER_SUB_CATEGORIES, REMOVE_SUPER_SUB_CATEGORIES, UPDATE_SUPER_SUB_CATEGORIES} from "../commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function AddSuperSubCategoryAction(name,categoryId, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_SUPER_SUB_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let body = {
                name,
                category: categoryId
            }
            let response = await Axios.post(`${BASE_URL}common/addCourseSubCategory`, body, config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_SUPER_SUB_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: ADD_SUPER_SUB_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getSuperSubCategories(body={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_SUB_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: 'id,name,value,category_name,category_id,available_course_count',
                search: body?.search ?? "",
                category: body?.category || body?.category !== 0 ? body.category: "",
                ordering: body?.ordering ?? ""
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}common/getCourseSubCategoryList`, Config);
            const modifiedData = {
				meta_data: {
					count: response?.data.data?.[0]?.count || 0,
					current: response?.data.data?.[0]?.links?.current || 0,
					hasNext: Boolean(response?.data.data?.[0]?.links?.next),
					total_pages: response?.data.data?.[0]?.links?.total_pages || 0,
					hasPrevious: Boolean(response?.data.data?.[0]?.links?.previous),
				},
				data: response?.data.data?.[0]?.results || [],
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_SUB_CATEGORIES,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_SUB_CATEGORIES,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function deleteSuperSubCourseCategoryAction(superSubCategoryId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: REMOVE_SUPER_SUB_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {

            let Config = {}
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_super_key")}
            }
            let response = await Axios.delete(`${BASE_URL}common/deleteCourseSubCategory/${superSubCategoryId}`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: REMOVE_SUPER_SUB_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: REMOVE_SUPER_SUB_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateSuperSubCourseCategoryAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_SUPER_SUB_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.patch(`${BASE_URL}common/updateCourseSubCategory/${body.id}`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_SUPER_SUB_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: UPDATE_SUPER_SUB_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}