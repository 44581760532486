import {SIDE_BAR_TOGGLE_CLICKED} from "../../action/commonActions/types";

const initialState = {
    sideBarToggle: false,
};

const dashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case SIDE_BAR_TOGGLE_CLICKED : {
            return{
                ...state,
                sideBarToggle : action.payload
            }
        }
        default :
            return state;
    }
};
export default dashboardReducers;
