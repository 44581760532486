import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, Typography, useMediaQuery } from "@mui/material";
import { getSuperPaymentsData } from "./../../../../redux/action/superAdminActions/superPaymentActions";
import moment from "moment/moment";
import {
	commonDashboardHeading,
	commonDashboardSubHeading,
} from "../../../../components/common/DashboardElements";
import dayjs from "dayjs";
import { debounce } from "lodash";
import SuperPaymentFilterAndSortingArea from "./SuperPaymentFIlterAndSortingArea";
import { getSuperInstructors } from "../../../../redux/action/superAdminActions/superInstructorsActions";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperPayments() {
	const dispatch = useDispatch();

	const [search, setSearch] = useState("");
	const [filterSeller, setFilterSeller] = useState(0);
	const [filterPurchasedDate, setFilterPurchasedDate] = useState(dayjs(""));
	const [sortBy, setSortBy] = useState([]);

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	const fetchPayments = useCallback(() => {
		dispatch(getSuperPaymentsData());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getSuperInstructors());
	}, [dispatch]);

	useEffect(() => {
		fetchPayments();
	}, [fetchPayments]);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	const generateFormattedDate = ()=>{
		let formattedDate = null;

		if (filterPurchasedDate && dayjs(filterPurchasedDate).isValid()) {
			formattedDate = dayjs(filterPurchasedDate).format("YYYY-MM-DD");
		}
		return formattedDate
	}

	const {
		data: Payments,
		isLoading,
		meta_data,
	} = useSelector((state) => state.superAdmin.superPayments);

	const { data: InstructorList } = useSelector(
		(state) => state.superAdmin.superInstructors
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value, filterSeller, formattedDate, sortBy) => {
			let body = {
				search: value,
				page: 1,
				seller: filterSeller,
				purchased_date: formattedDate,
				ordering: sortBy[0],
			};
			dispatch(getSuperPaymentsData(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const formattedDate = generateFormattedDate()
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value, filterSeller, formattedDate, sortBy);
	};

	const handleApplyFilters = () => { 
		const formattedDate = generateFormattedDate()
		const body = {
			page: 1,
			search: search,
			seller: filterSeller,
			purchased_date: formattedDate,
			ordering: sortBy[0],
		};

		dispatch(getSuperPaymentsData(body));
	};

	const handleResetFilters = () => {
		setFilterSeller(0);
		setFilterPurchasedDate(dayjs(""));
		let updatedBody = {
			page: 1,
			search: search,
			ordering: sortBy[0],
		};
		dispatch(getSuperPaymentsData(updatedBody));
	};

	const handleSorting = (item) => {
		const formattedDate = generateFormattedDate()
		const body = {
			page: meta_data.current ?? 1,
			search: search,
			seller: filterSeller,
			purchased_date: formattedDate,
			ordering: item,
		};
		dispatch(getSuperPaymentsData(body));
	};

	const handlePageChange = (value) => {
		const formattedDate = generateFormattedDate()
		const body = {
			page: value,
			search: search,
			seller: filterSeller,
			purchased_date: formattedDate,
			ordering: sortBy[0],
		};
		dispatch(getSuperPaymentsData(body));
	};

	const fullColumns = [
		{
			field: "course_name",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Course"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.course_name ?? "--"}</b>
					</div>
				);
			},
		},
		{
			field: "seller_name",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Educator"}</Typography>,
			renderCell: (params) => {
				return <div>{params?.row?.seller_name ?? "--"}</div>;
			},
		},
		{
			field: "course_prize",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Course Amount"}</Typography>
			),
			renderCell: (params) => {
				return <div>${params?.row?.amount ?? "0"}</div>;
			},
		},
		{
			field: "admin_profit",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Admin Profit"}</Typography>
			),
			renderCell: (params) => {
				return <div>${params?.row?.admin_amount ?? "0"}</div>;
			},
		},
		{
			field: "purchased_on",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Purchased On"}</Typography>
			),
			renderCell: (params) => {
				return (
					<div>
						{moment(params?.row?.created_at).format("DD-MM-YYYY") ?? "--"}
					</div>
				);
			},
		},
		// {
		// 	field: "actions",
		// 	renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
		// 	sortable: false,
		// 	renderCell: (params) => {
		// 		return (
		// 			<div className="d-flex justify-content-end">
		// 				<div
		// 					className="icon-edit"
		// 					style={{ cursor: "pointer" }}
		// 					onClick={() => {
		// 						setSelectedCategory(params.row)
		// 						setOpenEditForm(true)
		// 					}}></div>
		// 				&emsp;
		// 				<Popconfirm
		// 					title="Are you sure to delete this category?"
		// 					description={
		// 						<div>
		// 							<p>All the related details will be deleted.</p>
		// 							<span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
		// 							<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b>
		// 						</div>
		// 					}
		// 					okText="Yes"
		// 					cancelText="No"
		// 					onConfirm={() => handleDelete(params.row.id)}>
		// 					<div className="icon-bin" style={{ cursor: "pointer" }}></div>
		// 				</Popconfirm>
		// 			</div>
		// 		);
		// 	},
		// },
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "course_name",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Course"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.course_name ?? "--"}</b>
					</div>
				);
			},
		},
		{
			field: "course_prize",
			width: 50,
			sortable: false,
			flex: 0.5,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Amount"}</Typography>
			),
			renderCell: (params) => {
				return <div>${params?.row?.amount ?? "0"}</div>;
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForFilterAndSortingComponent = {
		loading: isLoading,
		handleApplyFilters,
		handleResetFilters,
		sortBy,
		setSortBy,
		handleSorting,
		filterSeller,
		setFilterSeller,
		filterPurchasedDate,
		setFilterPurchasedDate,
		InstructorList,
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4" style={{paddingBottom:'0px'}}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("Payments")}
						{commonDashboardSubHeading("Welcome to the Payments Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</div>

				<SuperPaymentFilterAndSortingArea
					{...propsForFilterAndSortingComponent}
				/>
				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(95vh - 320px)",
					}}>
					<DataGrid
						rows={Payments}
						columns={columns}
						rowHeight={100}
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter/>
		</div>
	);
}
