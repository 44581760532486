import { Close } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	DialogTitle,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
	getSuperStudentDetailData,
	getSuperStudentsData,
	updateSuperStudentOrTrainerStautsAction,
} from "../../../../redux/action/superAdminActions/superStudentActions";
import { IMAGE_FILE_URL } from "../../../../config/config";
import moment from "moment/moment";
import { Empty, Image, Popconfirm, Spin } from "antd";
import { Link } from "react-router-dom";
import { getSuperInstructors } from "../../../../redux/action/superAdminActions/superInstructorsActions";

const studentTabs = ["Overview", "Courses"];
const educatorTabs = ["Overview", "Courses", "Payment", "Personal Info"];

const SuperStudentDetail = (props) => {
	const { open, onClose, bodyForGetApi, type } = props;

	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState("Overview");
	const commonTabs = type === "INSTRUCTOR" ? educatorTabs : studentTabs;

	const maxHeightForCourseBox = "400px";

	// Memoize the function to prevent re-creation on each render
	const fetchStudentDetails = useCallback(
		(id) => {
			dispatch(getSuperStudentDetailData(id));
		},
		[dispatch]
	);

	useEffect(() => {
		if (id) {
			fetchStudentDetails(id);
		} else {
			if (type === "INSTRUCTOR") {
				navigate("/super_admin/instructors");
			} else {
				navigate("/super_admin/students");
			}
		}
	}, [fetchStudentDetails, id, navigate, type]);

	const {
		singleData: { data: studentDetail, isLoading },
	} = useSelector((state) => state.superAdmin.superStudents);

	const updateStudentStatus = (newStatus) => {
		setLoading(true);
		let body = {
			id: type === "INSTRUCTOR" ? studentDetail.seller_id : studentDetail.id,
			status: newStatus,
		};
		dispatch(
			updateSuperStudentOrTrainerStautsAction(body, {
				onSuccess: () => {
					if (type === "INSTRUCTOR") {
						dispatch(getSuperStudentDetailData(studentDetail.seller_id));
						dispatch(getSuperInstructors(bodyForGetApi));
					} else {
						dispatch(getSuperStudentDetailData(studentDetail.id));
						dispatch(getSuperStudentsData(bodyForGetApi));
					}
					setLoading(false);
				},
				onError: () => {
					if (type === "INSTRUCTOR") {
						dispatch(getSuperStudentDetailData(studentDetail.seller_id));
					} else {
						dispatch(getSuperStudentDetailData(studentDetail.id));
					}
					setLoading(false);
				},
			})
		);
	};

	const handleClose = () => {
		onClose?.();
	};

	const showDetailSection = () => (
		<div className="main-content">
			<div className="content-wrapper  js-content-wrapper">
				<section className="layout-pt layout-pb-lg">
					<div id="js-pin-container" className="js-pin-container relative">
						<section className="page-header -type-5 bg-light-6">
							<div className="page-header__bg">
								<div
									className="bg-image js-lazy"
									data-bg={`${IMAGE_FILE_URL}/img/event-single/bg.png`}></div>
							</div>

							<div className="container">
								<div className="page-header__content pt-40 pb-60">
									<div className="row y-gap-30">
										<div className="col-xl-12 col-lg-12">
											<div className="d-flex x-gap-15 y-gap-10 pb-20">
												<div className="d-flex items-center pt-20">
													<Avatar
														src={studentDetail?.user_profile_image ?? ""}
														alt="student"
														sx={{
															height: "100px",
															width: "100px",
														}}
													/>
													<div>
														<div className="text-20 lh-14 ml-10 fw-500">
															{studentDetail?.user_first_name +
																" " +
																studentDetail.user_last_name}
														</div>
														<div className="text-14 lh-14 ml-10">
															{studentDetail?.email_verified ? (
																<span style={{ color: "green" }}>Verified</span>
															) : (
																<span style={{ color: "red" }}>
																	Not Verified
																</span>
															)}
														</div>
													</div>
												</div>
											</div>

											<div className="tabs__controls flex-wrap d-flex x-gap-20 js-tabs-controls text-18">
												{commonTabs.map((elm, i) => (
													<div
														onClick={() => {
															setTab(elm);
														}}
														key={i}>
														<button
															className={`tabs__button px-25 py-12 rounded-8 js-tabs-button ${
																tab === elm ? "tabActive" : ""
															} `}
															data-tab-target=".-tab-item-2"
															type="button">
															{elm}
														</button>
													</div>
												))}
											</div>
											<Divider />

											{/* Overview starts */}
											{tab === "Overview" ? (
												<div className="mt-25">
													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-email"></div>
															<div className="ml-10">Email</div>
														</div>
														<div>{studentDetail?.user_email ?? "--"}</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-calendar"></div>
															<div className="ml-10">DOB</div>
														</div>
														<div>
															{studentDetail?.user_dob
																? moment(studentDetail.user_dob).format(
																		"MMMM Do YYYY"
																  )
																: "--"}
														</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-clock-2"></div>
															<div className="ml-10">Joined On</div>
														</div>
														<div>
															{studentDetail?.date_joined
																? moment(studentDetail.date_joined).format(
																		"MMMM Do YYYY"
																  )
																: "--"}
														</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-tech"></div>
															<div className="ml-10">Contact No.</div>
														</div>
														<div>{studentDetail?.user_phone ?? "--"}</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-bar-chart-2"></div>
															<div className="ml-10">Gender</div>
														</div>
														<div>{studentDetail?.user_gender ?? "--"}</div>
													</div>

													{type === "STUDENT" ? (
														<>
															<div className="d-flex justify-between py-8 border-top-light">
																<div className="d-flex items-center text-dark-1">
																	<div className="icon-translate"></div>
																	<div className="ml-10">
																		Total Amount Spend
																	</div>
																</div>
																<div>
																	{studentDetail?.total_purchase_amount
																		? `$ ${studentDetail.total_purchase_amount}`
																		: "--"}
																</div>
															</div>

															<div className="d-flex justify-between py-8 ">
																<div className="d-flex items-center text-dark-1">
																	<div className="icon-video-file"></div>
																	<div className="ml-10">
																		Total Enrolled Courses
																	</div>
																</div>
																<div>
																	{studentDetail?.enrolled_course_count ?? "--"}
																</div>
															</div>
														</>
													) : null}

													{type === "INSTRUCTOR" ? (
														<>
															<div className="d-flex justify-between py-8 border-top-light">
																<div className="d-flex items-center text-dark-1">
																	<div className="icon-translate"></div>
																	<div className="ml-10">Designation</div>
																</div>
																<div>
																	{studentDetail?.designation
																		? `${studentDetail.designation}`
																		: "--"}
																</div>
															</div>

															<div className="d-flex justify-between py-8 ">
																<div className="d-flex items-center text-dark-1">
																	<div className="icon-video-file"></div>
																	<div className="ml-10">
																		Total Enrolled Students
																	</div>
																</div>
																<div>{studentDetail?.student_count ?? "0"}</div>
															</div>
														</>
													) : null}
												</div>
											) : null}
											{/* Overview ends */}

											{/* Courses Start  */}

											{tab === "Courses" ? (
												<div className="row y-gap-30 pt-10">
													<div className="col-12 col-md-6 col-lg-6 col-xl-6">
														<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
															<div className="d-flex justify-between items-center py-10 py-md-20 px-10 px-md-30 border-bottom-light">
																<h2 className="text-17 lh-1 fw-500">
																	Completed Courses ({" "}
																	{studentDetail?.completed_course_count ?? 0} )
																</h2>
															</div>
															<div className="py-10 py-md-30 px-10 px-md-30">
																<Spin spinning={isLoading}>
																	<div
																		className="y-gap-30"
																		style={{
																			maxHeight: maxHeightForCourseBox,
																			overflowY: "auto",
																			overflowX: "hidden",
																		}}>
																		{studentDetail?.completed_course?.length ? (
																			studentDetail.completed_course.map(
																				(elm, i) => (
																					<div
																						key={i}
																						className={`d-flex ${
																							i > 0 && "border-top-light"
																						}`}>
																						<div className="shrink-0">
																							<Avatar
																								src={
																									elm?.course_thumbnail_image ??
																									""
																								}
																								variant="rounded"
																								alt="image"
																								sx={{ width: 75, height: 60 }}
																							/>
																						</div>
																						<div
																							className="ml-15"
																							style={{ width: "100%" }}>
																							<div className="d-flex justify-between items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																								<div className="d-flex items-center">
																									<h4 className="text-15 lh-11 fw-500">
																										<Link
																											className="text-purple-1 underline"
																											// to={`course-dashboard/${elm?.slug_name}`}
																										>
																											{elm.title}
																										</Link>
																									</h4>
																								</div>
																								<div className="d-flex items-center">
																									<Link
																										// onClick={() =>
																										// 	handleDownloadCertificate(elm)
																										// }
																										className="text-14 text-purple-1">
																										Certificate &nbsp;
																										<i
																											className={`text-10 icon-document text-purple-1`}></i>
																									</Link>
																								</div>
																							</div>
																							<div className="d-flex justify-between items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																								<div className="d-flex items-center">
																									<i className="icon-person-2 text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.seller_obj
																											?.user_first_name +
																											" " +
																											elm?.seller_obj
																												?.user_last_name}
																									</div>
																								</div>
																								<div className="d-flex items-center">
																									<i className="icon-video-file text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.lesson_count ?? 0}{" "}
																										lesson
																									</div>
																								</div>
																								<div className="d-flex items-center">
																									<i className="icon-time-management text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.course_duration ?? 0}{" "}
																										m
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				)
																			)
																		) : (
																			<Empty
																				description={"Course not added yet."}
																			/>
																		)}
																	</div>
																</Spin>
															</div>
														</div>
													</div>

													<div className="col-12 col-md-6 col-lg-6 col-xl-6">
														<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
															<div className="d-flex justify-between items-center py-10 py-md-20 px-10 px-md-30 border-bottom-light">
																<h2 className="text-17 lh-1 fw-500">
																	In Progress Courses ({" "}
																	{studentDetail?.incompleted_course?.length ??
																		0}{" "}
																	)
																</h2>
															</div>
															<div className="py-30 px-30">
																<Spin spinning={isLoading}>
																	<div
																		className="y-gap-30"
																		style={{
																			maxHeight: maxHeightForCourseBox,
																			overflowY: "auto",
																			overflowX: "hidden",
																		}}>
																		{studentDetail?.incompleted_course
																			?.length ? (
																			studentDetail?.incompleted_course.map(
																				(elm, i) => (
																					<div
																						key={i}
																						className={`d-flex ${
																							i > 0 && "border-top-light"
																						}`}>
																						<div className="shrink-0">
																							<Avatar
																								src={
																									elm?.course_thumbnail_image ??
																									""
																								}
																								variant="rounded"
																								alt="image"
																								sx={{ width: 75, height: 60 }}
																							/>
																						</div>
																						<div
																							className="ml-15"
																							style={{ width: "100%" }}>
																							<h4 className="text-15 lh-16 fw-500">
																								<Link
																									className="text-purple-1 underline"
																									// to={`course-dashboard/${elm?.slug_name}`}
																								>
																									{elm.title}
																								</Link>
																							</h4>
																							<div className="d-flex items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																								<div className="d-flex items-center">
																									<i className="icon-person-2 text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.seller_obj
																											?.user_first_name +
																											" " +
																											elm?.seller_obj
																												?.user_last_name}
																									</div>
																								</div>
																								<div className="d-flex items-center">
																									<i className="icon-video-file text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.lesson_count ?? 0}{" "}
																										lesson
																									</div>
																								</div>
																								<div className="d-flex items-center">
																									<i className="icon-time-management text-15 mr-10"></i>
																									<div className="text-13 lh-1">
																										{elm?.course_duration ?? 0}{" "}
																										m
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				)
																			)
																		) : (
																			<Empty
																				description={"Course not added yet."}
																			/>
																		)}
																	</div>
																</Spin>
															</div>
														</div>
													</div>
												</div>
											) : null}

											{/* Courses ends */}

											{/* Payment starts */}
											{tab === "Payment" ? (
												<div className="mt-25">
													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-bar-chart-2"></div>
															<div className="ml-10">Total Sale</div>
														</div>
														<div>${studentDetail?.total_sale ?? "0"}</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-infinity"></div>
															<div className="ml-10">Withdraw Amount</div>
														</div>
														<div>
															${studentDetail?.withdrawal_amount ?? "0"}
														</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-clock-2"></div>
															<div className="ml-10">Hold Amount</div>
														</div>
														<div>${studentDetail?.hold_amount ?? "0"}</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-calendar"></div>
															<div className="ml-10">This Month Sale</div>
														</div>
														<div>${studentDetail?.this_month_sale ?? "0"}</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-video-file"></div>
															<div className="ml-10">Published Courses</div>
														</div>
														<div>{studentDetail?.published_courses ?? "0"}</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-badge"></div>
															<div className="ml-10">Overall Rating</div>
														</div>
														<div>{studentDetail?.overall_rating ?? "0"}</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-person-2"></div>
															<div className="ml-10">
																Total Enrolled Students
															</div>
														</div>
														<div>{studentDetail?.enrolled_students ?? "0"}</div>
													</div>
												</div>
											) : null}
											{/* Payment ends */}

											{/* Personal Info starts */}
											{tab === "Personal Info" ? (
												<div className="mt-25">
													<div className="row y-gap-30 mb-20">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-calendar"></div>
															<div className="ml-10">License Preview</div>
														</div>
														<Image
															width={300}
															preview={{
																mask: false,
																getContainer: document.body, // This ensures the preview opens on top of everything
															}}
															style={{ zIndex: 10001, cursor: "pointer" }}
															src={
																studentDetail?.licence_number_image ?? "error"
															}
														/>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-calendar"></div>
															<div className="ml-10">License Number</div>
														</div>
														<div>{studentDetail?.licence_number ?? "--"}</div>
													</div>

													<div className="d-flex justify-between py-8 ">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-bar-chart-2"></div>
															<div className="ml-10">Business Name</div>
														</div>
														<div>{studentDetail?.business_name ?? "--"}</div>
													</div>

													<div className="d-flex justify-between py-8 border-top-light">
														<div className="d-flex items-center text-dark-1">
															<div className="icon-video-file"></div>
															<div className="ml-10">
																Address ( Street, State, City, Country, Pincode
																)
															</div>
														</div>
														<div>
															{studentDetail?.street ?? "--"},{" "}
															{studentDetail?.state ?? "--"},{" "}
															{studentDetail?.city ?? "--"},{" "}
															{studentDetail?.country ?? "--"},{" "}
															{studentDetail?.pin_code ?? "--"}
														</div>
													</div>
												</div>
											) : null}
											{/* Personal Info ends */}
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</section>
			</div>
		</div>
	);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => handleClose()}
			sx={{
				"& .MuiDrawer-paper": {
					width: "90%",
					maxWidth: "90vw",
				},
				zIndex: 999,
			}}
			SlideProps={{
				translate: "yes",
				timeout: 1000,
			}}>
			<Box>
				<DialogTitle
					sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between" }}
					id="customized-dialog-title">
					{type === "INSTRUCTOR" ? "Instructor" : "Student"} Details
					{studentDetail?.status === "ACTIVE" ? (
						<Popconfirm
							title="Update as Deactivate?"
							description="Are you sure to update it?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => updateStudentStatus("INACTIVE")}>
							<Button
								component="label"
								className="button h-40 px-25 -red-1 text-white mr-35"
								style={{ textTransform: "inherit" }}
							>
								{loading || isLoading ? "Loading..." : "Deactivate"}
							</Button>
						</Popconfirm>
					) : (
						<Popconfirm
							title="Update as Activate?"
							description="Are you sure to update it?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => updateStudentStatus("ACTIVE")}>
							<Button
								component="label"
								className="button h-40 px-25 -dark-1 -dark-button-white text-white mr-35"
								style={{ textTransform: "inherit" }}
							>
								{loading || isLoading ? "Loading..." : "Activate"}
							</Button>
						</Popconfirm>
					)}
				</DialogTitle>

				<IconButton
					aria-label="close"
					onClick={() => handleClose()}
					sx={{
						position: "absolute",
						right: 8,
						top: 15,
						color: (theme) => theme.palette.grey[500],
					}}>
					<Close />
				</IconButton>
				{isLoading && <LinearProgress />}
				<Divider />
				{!isLoading && showDetailSection()}
			</Box>
		</Drawer>
	);
};

export default SuperStudentDetail;
