export const commonDashboardHeading = (heading) => (
	<h1 className="text-30 lh-12 fw-700">{heading}</h1>
);

export const commonDashboardSecondaryHeading = (heading) => (
	<h1 className="text-20 lh-12 fw-500">{heading}</h1>
);

export const commonDashboardSubHeading = (subHeading) => (
	<div className="mt-10">{subHeading}</div>
);
