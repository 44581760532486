import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const socialMediaLinks = [
	{ id: 1, href: "#", iconClassName: "icon-facebook", replaceIcon: null },
	{
		id: 2,
		href: "#",
		iconClassName: "icon-twitter",
		replaceIcon: <FontAwesomeIcon icon={faXTwitter} />,
	},
	{ id: 3, href: "#", iconClassName: "icon-instagram", replaceIcon: null },
	{ id: 4, href: "#", iconClassName: "icon-linkedin", replaceIcon: null },
];
export default function Socials({ componentsClass, textSize }) {
	return (
		<>
			{socialMediaLinks.map((link, index) => (
				<a
					key={index}
					className={componentsClass ? componentsClass : ""}
					href={link.href}>
					{link.replaceIcon ? (
						link.replaceIcon
					) : (
						<i className={`${link.iconClassName} ${textSize}`}></i>
					)}
				</a>
			))}
		</>
	);
}
