import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { AddSuperCourseLanguageAction, getSuperCourseLanguageData, updateSuperCourseLanguageAction } from "../../../../redux/action/superAdminActions/superCourseLanguageActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const AddSuperCourseLanguage = (props) => {
	const { open, setOpen, type, selectedCourseLanguage } = props;

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: "",
		},
	});

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	

	useEffect(() => {
		if (type === "EDIT" && selectedCourseLanguage?.name) {
			setValue("name", selectedCourseLanguage.name);
		}
	}, [selectedCourseLanguage, setValue, type]);

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitBasicDetails = (data) => {
		if (type === "EDIT") {
			let updatedBody = {
				id : selectedCourseLanguage?.id ?? '',
				name: data.name,
			}
			dispatch(
				updateSuperCourseLanguageAction(updatedBody, {
					onLoading: () => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						dispatch(getSuperCourseLanguageData());
						handleClose();
					},
				})
			);
		} else {
            let body ={
                name : data.name
            }
			dispatch(
				AddSuperCourseLanguageAction(body, {
					onLoading: (loading) => {
						setLoading(true);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						enqueueSnackbar(success.message[0], { variant: "success" });
						dispatch(getSuperCourseLanguageData());
						handleClose();
					},
				})
			);
		}
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			maxWidth={"sm"}
			fullWidth>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				Add Course Language
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<form
					onSubmit={handleSubmit(onSubmitBasicDetails)}
					className="contact-form row y-gap-30">
					<div className="col-md-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Language
						</label>
						<input
							{...register("name", {
								required: "Language is required",
							})}
							type="text"
							placeholder="Enter Language"
						/>
						{errors.name && (
							<span style={{ color: "red" }}>{errors.name.message}</span>
						)}
					</div>
				</form>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					className="button -sm -purple-1 text-white"
					variant={"contained"}
					loading={loading}
					autoFocus
					type="submit"
					onClick={handleSubmit(onSubmitBasicDetails)}>
					{type === "EDIT" ? "Update" : "Save"}
				</LoadingButton>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default AddSuperCourseLanguage;
