import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { ADD_COURSE,GET_COURSE_PREVIEW, DELETE_COURSE, EMPTY_COURSE_DETAILS, GET_AWS_LINK, GET_COURSE, GET_COURSE_DETAILS, GET_SELLER_COURSE, UPDATE_COURSE, EMPTY_COURSE_PREVIEW } from "../commonActions/types";
import { onResponseError } from "../../../function/Interceptor";

export function getMediaUploadLink(media_key, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_AWS_LINK, status: LOADING});
        onLoading?.(true)
        try {
            let body ={
                media_key
            }
            let response = await Axios.post(`${BASE_URL}users/getUploadSignedURL`, body);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_AWS_LINK,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: GET_AWS_LINK,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function addCourseAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_COURSE, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.post(`${BASE_URL}courses/addCourse`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: ADD_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateCourseAction(body,courseId, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_COURSE, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}courses/updateCourse/${courseId}/`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: UPDATE_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getCourseListAction(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_COURSE, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}courses/listCourse`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}


export function getSellerCourseListAction(
	filterBody = {},
	actionResponse = {}
) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_SELLER_COURSE, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
				fields:
					"id,title,category_obj,category_obj__name,course_price,course_status,course_thumbnail_image,level,sale_price,slug_name,sub_category_obj,sub_category_obj__name,chapters_count,lesson_count,course_duration,is_popular_badge,is_new_badge,is_best_seller_badge,course_views",
				course_status: "",
				search: filterBody?.search ?? "",
				page: filterBody?.page !== undefined ? filterBody.page : 1,
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}
			let response = await Axios.get(
				`${BASE_URL}courses/listSellerCourse`,
				Config
			);
			const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results.map((item, index) => ({
					...item,
					index,
				})),
			};
			onSuccess?.(modifiedData);
			dispatch({
				type: GET_SELLER_COURSE,
				status: SUCCESS,
				payload: modifiedData,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_SELLER_COURSE,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}


export function getCourseDetailsAction(courseSlug,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_COURSE_DETAILS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}courses/getSellerCourse/${courseSlug}/`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_COURSE_DETAILS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_COURSE_DETAILS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getCoursePreviewAction(courseSlug,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_COURSE_PREVIEW, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}courses/getSellerCourse/${courseSlug}/`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_COURSE_PREVIEW,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_COURSE_PREVIEW,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function emptyCourseDetailsAction() {
    return async (dispatch) => {
            dispatch({
                type: EMPTY_COURSE_DETAILS,
                status: SUCCESS,
                payload: {},
            });
    };
}

export function emptyCoursePreviewAction() {
    return async (dispatch) => {
            dispatch({
                type: EMPTY_COURSE_PREVIEW,
                status: SUCCESS,
                payload: {},
            });
    };
}

export function deleteCourseAction(courseId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: DELETE_COURSE, status: LOADING});
        onLoading?.(true)
        try {

            let Config = {}
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.delete(`${BASE_URL}courses/deleteCourse/${courseId}`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: DELETE_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: DELETE_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getCourseCertificate(courseId, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let params = {
				pagination: false,
				fields: "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
				Config.responseType = "blob";
			}
			let response = await Axios.get(
				`${BASE_URL}users/getDownloadCertificate?course=${courseId}`,
				Config
			);
			const pdfBlob = new Blob([response.data], { type: "application/pdf" });
			let pdfName = `${new Date().getTime()}.pdf`;
			const link = document.createElement("a");
			link.href = URL.createObjectURL(pdfBlob);
			link.download = pdfName;
			document.body.append(link);
			link.click();
			link.remove();
			setTimeout(() => URL.revokeObjectURL(link.href), 7000);
			onSuccess?.(response?.data);
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
		}
	};
}