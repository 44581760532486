// --------LIBRARIES--------
import {combineReducers} from "redux";
import trainersReducers from "./trainers";
import singleTrainerReducer from "./singleTrainer";
import trainerCoursesRatingsReducer from "./trainersCoursesRatings";

export default combineReducers({
    trainers: trainersReducers,
    singleTrainer: singleTrainerReducer,
    trainerCoursesReviews : trainerCoursesRatingsReducer
});