import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";
import { GET_SUPER_ADMIN_DETAIL, UPDATE_SUPER_ADMIN_PASSWORD } from "../commonActions/types";

export function getSuperAdminDetailData(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_ADMIN_DETAIL, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
            };

            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}users/getAdminUser`, Config);
            onSuccess?.(response?.data?.data ?? {})
            dispatch({
                type: GET_SUPER_ADMIN_DETAIL,
                status: SUCCESS,
                payload: response?.data?.data ?? {},
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_ADMIN_DETAIL,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function updateSuperAdminPasswordAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_SUPER_ADMIN_PASSWORD, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/changePassword`, body,config);
            // Check if the status code indicates an error
            if (response?.data?.status_code === 400) {
                onError?.(response?.data)
                onResponseError(response?.data,"super_admin")
                return
            }
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_SUPER_ADMIN_PASSWORD,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: UPDATE_SUPER_ADMIN_PASSWORD,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}