import React from 'react';
import { COMPANY_NAME } from '../config/config';

const metadata = {
    title:
        `Page not found| ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function NotFoundPage() {
    return (
        <div className="main-content  ">
            Page not found...
            {/*<MetaComponent meta={metadata} />*/}

            {/*<Preloader />*/}

            {/*<Header />*/}
            {/*<div className="content-wrapper js-content-wrapper overflow-hidden">*/}
            {/*    <PageLinks />*/}
            {/*    <NotFound />*/}
            {/*    <FooterOne />*/}
            {/*</div>*/}
        </div>
    );
}