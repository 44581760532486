import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useSnackbar} from 'notistack';
import {useDispatch} from "react-redux";
import {customerSignUp} from "../../redux/action/userActions/userActions";

export default function CustomerSignUpForm() {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    const matchPasswordAndConfirmPassword = (password, confirmPassword) => {
        return password === confirmPassword
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // Accessing form values
        const firstName = event.target.elements.firstName.value;
        const lastName = event.target.elements.lastName.value;
        const email = event.target.elements.email.value;
        // const phoneNumber = event.target.elements.phoneNumber.value;
        const password = event.target.elements.password.value;
        const confirmPassword = event.target.elements.confirmPassword.value;

        const passwordLengthChecked = password.trim().length < 3;
        if (passwordLengthChecked) {
            enqueueSnackbar('Password should be greater than 3 letters!', {variant: 'warning'});
            return
        }

        const isPasswordMatched = matchPasswordAndConfirmPassword(password, confirmPassword)
        if (isPasswordMatched) {
            let body = {
                "first_name": firstName,
                "last_name": lastName,
                "phone": null,
                email,
                password
            }
            dispatch(customerSignUp(body, {
                onLoading: (loading) => {
                    setLoading(true)
                    enqueueSnackbar('Loading...', {variant: 'info'});
                },
                onError: (error) => {
                    setLoading(false)
                    let errorMessage = String(error?.message ?? 'Something went wrong');
                    enqueueSnackbar(errorMessage, {variant: 'error'});
                },
                onSuccess: (success) => {
                    setLoading(false)
                    enqueueSnackbar(success.message[0], {variant: 'success'});
                    event.target.reset();
                    setTimeout(() => {
                        window.location.replace(`verify-email-message/${window.btoa(email)}`)
                    }, 1000)
                }
            }))
        } else {
            enqueueSnackbar('Password not matched!', {variant: 'warning'});
        }
    };


    return (
        <div className="form-page__content lg:py-50">
            <div className="container">
                <div className="row justify-center items-center">
                    <div 
                        // className="col-xl-8 col-lg-9"
                        className="col-xl-9 col-lg-10"
                    >
                        <div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
                            <h3 className="text-24 lh-13 text-center">Student Sign Up</h3>
                            <p className="mt-10 text-center">
                                Already have a student account? &nbsp;
                                <Link to="/login" className="text-purple-1">
                                    Login as Student
                                </Link>
                            </p>
                            {/* <p>
                                Want to become an Educator? &nbsp;
                                <Link to="/trainer-sign-up" className="text-purple-1">
                                    Become an Educator
                                </Link>
                            </p> */}
                            <form
                                className="contact-form respondForm__form row y-gap-20 pt-30"
                                onSubmit={handleSubmit}
                            >

                                <div className="col-lg-6">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        First Name <span className="text-red">*</span>
                                    </label>
                                    <input required type="text" name="firstName" placeholder="First Name"/>
                                </div>
                                <div className="col-lg-6">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Last Name <span className="text-red">*</span>
                                    </label>
                                    <input required type="text" name="lastName" placeholder="Last Name"/>
                                </div>
                                <div className="col-lg-12">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Email address <span className="text-red">*</span>
                                    </label>
                                    <input required type="email" name="email" placeholder="Email Address"/>
                                </div>
                                {/* <div className="col-lg-6">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Phone Number *
                                    </label>
                                    <input required type="text" autoComplete={'tel'} name="phoneNumber"
                                           placeholder="Phone Number"/>
                                </div> */}
                                <div className="col-lg-6">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Password <span className="text-red">*</span>
                                    </label>
                                    <input required autoComplete='new-password' type="password" name="password"
                                           placeholder="Password"/>
                                </div>
                                <div className="col-lg-6">
                                    <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                        Confirm Password <span className="text-red">*</span>
                                    </label>
                                    <input required autoComplete='new-password' type="password" name="confirmPassword"
                                           placeholder="Confirm Password"/>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        name="submit"
                                        id="submit"
                                        className={`button -md ${loading ? "-blue-3" : "-purple-1"} text-white fw-500 w-1/1`}
                                        disabled={loading}
                                    >
                                        {loading ? 'Loading...' : 'Register'}
                                    </button>
                                </div>
                            </form>

                            {/*<div className="lh-12 text-dark-1 fw-500 text-center mt-20">*/}
                            {/*    Or sign in using*/}
                            {/*</div>*/}

                            {/*<div className="d-flex x-gap-20 items-center justify-between pt-20">*/}
                            {/*    <div>*/}
                            {/*        <button className="button -sm px-24 py-20 -outline-blue-3 text-blue-3 text-14">*/}
                            {/*            Log In via Facebook*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <button className="button -sm px-24 py-20 -outline-red-3 text-red-3 text-14">*/}
                            {/*            Log In via Google+*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
