import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuperStudentsData } from "./../../../../redux/action/superAdminActions/superStudentActions";
import { DataGrid } from "@mui/x-data-grid";
import {
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Pagination,
	Typography,
	useMediaQuery,
} from "@mui/material";
import moment from "moment/moment";
import { debounce } from "lodash";
import SuperStudentsFilterAndSortingArea from "./SuperStudentsFilterAndSortingArea";
import {
	commonDashboardHeading,
	commonDashboardSubHeading,
} from "../../../../components/common/DashboardElements";
import dayjs from "dayjs";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import SuperStudentDetail from "./SuperStudentDetail";
import { useParams, useNavigate } from "react-router-dom";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperStudents() {
	const { id } = useParams();

	const [search, setSearch] = useState("");
	const [studentStatus, setStudentStatus] = useState([]);
	const [dateFilter, setDateFilter] = useState(dayjs(""));
	const [sortBy, setSortBy] = useState([]);
	const [openDetailPage, setOpenDetailPage] = useState(!!id);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fetchStudenta = useCallback(() => {
		dispatch(getSuperStudentsData());
	}, [dispatch]);

	useEffect(() => {
		fetchStudenta();
	}, [fetchStudenta]);

	// Fetch data when student ID changes
	useEffect(() => {
		if (id) {
			// Fetch the student detail data based on the ID
			setOpenDetailPage(true);
		}
	}, [id]);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	const generateFormattedDate = () => {
		let formattedDate = null;

		if (dateFilter && dayjs(dateFilter).isValid()) {
			formattedDate = dayjs(dateFilter).format("YYYY-MM-DD");
		}
		return formattedDate;
	};

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	const {
		data: Students,
		isLoading,
		meta_data,
	} = useSelector((state) => state.superAdmin.superStudents);

	const bodyForGetApi = ()=>{
		let formattedDate = generateFormattedDate();

		let body = {
			status: studentStatus[0],
			date_joined: formattedDate,
			page: 1,
			search: search,
			ordering: sortBy[0],
		};

		return body;
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value, studentStatus, formattedDate, sortBy) => {
			let body = {
				search: value,
				page: 1,
				status: studentStatus[0],
				date_joined: formattedDate,
				ordering: sortBy[0],
			};
			dispatch(getSuperStudentsData(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		let formattedDate = generateFormattedDate();
		setSearch(value);
		handleSearchDebounced(value, studentStatus, formattedDate, sortBy);
	};

	const handleApplyFilters = () => {
		let formattedDate = generateFormattedDate();

		const body = {
			status: studentStatus[0],
			date_joined: formattedDate,
			page: 1,
			search: search,
			ordering: sortBy[0],
		};

		dispatch(getSuperStudentsData(body));
	};

	const handleResetFilters = () => {
		setStudentStatus([]);
		setDateFilter(dayjs(""));
		let updatedBody = {
			page: 1,
			search: search,
			ordering: sortBy[0],
		};
		dispatch(getSuperStudentsData(updatedBody));
	};

	const handleSorting = (item) => {
		let formattedDate = generateFormattedDate();

		const body = {
			status: studentStatus[0],
			date_joined: formattedDate,
			page: meta_data.current,
			search: search,
			ordering: item,
		};

		dispatch(getSuperStudentsData(body));
	};

	const handlePageChange = (value) => {
		let tempBody = bodyForGetApi()
		let body = {
			...tempBody,
			page: value,
		};
		dispatch(getSuperStudentsData(body));
	};

	const handleStudentClicked = (studentId) => {
		navigate(`${studentId}`);
		setOpenDetailPage(true);
	};

	const handleDetailPageClose = () => {
		navigate("/super_admin/students");
		setOpenDetailPage(false);
	};

	const fullColumns = [
		{
			field: "student",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Student"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={
								<b
									className="text-purple-1 cursor"
									onClick={() => handleStudentClicked(params?.row?.id)}>
									{params?.row?.user_first_name +
										" " +
										params?.row?.user_last_name ?? "--"}
								</b>
							}
							secondary={
								params?.row?.email_verified ? (
									<span style={{ color: "green" }}>Verified</span>
								) : (
									<span style={{ color: "red" }}>Not Verified</span>
								)
							}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Email"}</Typography>,
			renderCell: (params) => {
				return <div>{params?.row?.user_email ?? "--"}</div>;
			},
		},
		{
			field: "registration_date",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Registration Date"}</Typography>
			),
			renderCell: (params) => {
				return (
					<div>
						{moment(params?.row?.date_joined).format("DD-MM-YYYY") ?? "--"}
					</div>
				);
			},
		},
		{
			field: "course_take",
			width: 50,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">{"Course taken"}</Typography>
			),
			renderCell: (params) => {
				return <div>{params?.row?.course_taken ?? 0}</div>;
			},
		},
		{
			field: "status",
			width: 50,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Status"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.status === "ACTIVE" ? (
							<span style={{ color: "green" }}>ACTIVE</span>
						) : (
							<span style={{ color: "red" }}>In Active</span>
						)}
					</div>
				);
			},
		},
		// {
		// 	field: "actions",
		// 	renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
		// 	sortable: false,
		// 	renderCell: (params) => {
		// 		return (
		// 			<div className="d-flex justify-content-end">
		// 				<div
		// 					className="icon-edit"
		// 					style={{ cursor: "pointer" }}
		// 					onClick={() => {
		// 						setSelectedCategory(params.row)
		// 						setOpenEditForm(true)
		// 					}}></div>
		// 				&emsp;
		// 				<Popconfirm
		// 					title="Are you sure to delete this category?"
		// 					description={
		// 						<div>
		// 							<p>All the related details will be deleted.</p>
		// 							<span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
		// 							<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b>
		// 						</div>
		// 					}
		// 					okText="Yes"
		// 					cancelText="No"
		// 					onConfirm={() => handleDelete(params.row.id)}>
		// 					<div className="icon-bin" style={{ cursor: "pointer" }}></div>
		// 				</Popconfirm>
		// 			</div>
		// 		);
		// 	},
		// },
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "student",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Student"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={
								<b
									className="text-purple-1 cursor"
									onClick={() => handleStudentClicked(params?.row?.id)}>
									{params?.row?.user_first_name +
										" " +
										params?.row?.user_last_name ?? "--"}
								</b>
							}
							secondary={
								params?.row?.email_verified ? (
									<span style={{ color: "green" }}>Verified</span>
								) : (
									<span style={{ color: "red" }}>Not Verified</span>
								)
							}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "status",
			width: 50,
			sortable: false,
			flex: 0.4,
			renderHeader: () => <Typography variant="h6" className="text-purple-1">{"Status"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.status === "ACTIVE" ? (
							<span style={{ color: "green" }}>ACTIVE</span>
						) : (
							<span style={{ color: "red" }}>In Active</span>
						)}
					</div>
				);
			},
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForFilterAndSortingComponent = {
		loading: isLoading,
		handleApplyFilters,
		handleResetFilters,
		studentStatus,
		setStudentStatus,
		dateFilter,
		setDateFilter,
		sortBy,
		setSortBy,
		handleSorting,
	};

	return (
		<div className="dashboard__main">
			<div
				className="dashboard__content bg-light-4"
				style={{ paddingBottom: "0px" }}>
				<div className="row pb-20">
					<div className="col-auto">
						{commonDashboardHeading("Students")}
						{commonDashboardSubHeading("Welcome to the Students Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</div>

				<SuperStudentsFilterAndSortingArea
					{...propsForFilterAndSortingComponent}
				/>

				{openDetailPage && (
					<SuperStudentDetail
						open={openDetailPage}
						onClose={handleDetailPageClose}
						bodyForGetApi={bodyForGetApi}
						type={"STUDENT"}
					/>
				)}
				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(95vh - 320px)",
					}}>
					<DataGrid
						rows={Students}
						columns={columns}
						rowHeight={80}
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							}
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter />
		</div>
	);
}
