import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { IMAGE_FILE_URL } from "../../config/config";
import { deleteCourseInCartAction, deleteCourseInLocalCartAction, getCourseInCartAction } from "../../redux/action/cartActions/cartActions";
import { useSnackbar } from "notistack";

const CourseCartBox = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: courseCart,
} = useSelector(state => state.cart.courseCart)

const loggedInUser = localStorage.getItem('loggedIn_user') ?? '{}'
const parsedLoggedInData = JSON.parse(loggedInUser)

const localStorageCart = localStorage.getItem('beauty_cart_items') ?? '[]'
const localStorageCartItems = JSON.parse(localStorageCart)

  const handleRemoveCart = (course) => {
    if(parsedLoggedInData.id){
        dispatch(deleteCourseInCartAction(course.id, {
            onLoading: (loading) => {
                enqueueSnackbar('Loading...', {variant: 'info'});
            },
            onError: (error) => {
                let errorMessage = String(error?.message);
                enqueueSnackbar(errorMessage, {variant: 'error'});
            },
            onSuccess: (success) => {
                enqueueSnackbar(success.message[0], {variant: 'success'});
                dispatch(getCourseInCartAction())
            }
        }))
    }else{
        dispatch(deleteCourseInLocalCartAction(course.id))
    }
    const removedLocalHostData = localStorageCartItems.length? localStorageCartItems.filter(item => String(item.id) !== String(course.id)): [];
    localStorage.setItem('beauty_cart_items', JSON.stringify(removedLocalHostData));
  };

  useEffect(() => {
    let totalSalePrice = 0;
    courseCart?.length && courseCart.forEach(course => {
        totalSalePrice += parseFloat(course.sale_price);
    });
    setTotalPrice(totalSalePrice);
  }, [courseCart]);

  return (
    <div className="header-cart bg-white -dark-bg-dark-1 rounded-8">
      <div
        className="px-30 pt-30 pb-10"
        style={{ maxHeight: "300px", overflowY: "scroll" }}
      >
        {courseCart?.length ? courseCart.map((elm, i) => (
          <div key={i} className="row justify-between x-gap-40 pb-20">
            <Link
              style={{ textDecoration: "none" }}
              to={`/courses/${elm.slug_name}`}
              className="col"
            >
              <div className="row x-gap-10 y-gap-10">
                <div className="col-auto">
                  <Avatar variant="square"  style={{width:'100px',height:'80px',objectFit:'contain'}}  src={elm.course_thumbnail_image} alt="course" />
                </div>

                <div className="col">
                  <div className="text-dark-1 lh-15">{elm.title}</div>

                  <div className="d-flex items-center mt-10">
                    {!elm.is_course_free ? (
                      <>
                        <div className="lh-12 fw-500 line-through text-light-1 mr-10">
                          ${elm?.course_price ?? 0}
                        </div>
                        <div className="text-18 lh-12 fw-500 text-dark-1">
                          ${elm?.sale_price ?? 0}
                        </div>
                      </>
                    ) : (
                      <>
                        <div></div>
                        <div className="text-18 lh-12 fw-500 text-dark-1">
                          Free
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Link>

            <div className="col-auto" onClick={() => handleRemoveCart(elm)}>
              <button>
                <img src={`${IMAGE_FILE_URL}/assets/img/menus/close.svg`} alt="icon" />
              </button>
            </div>
          </div>
        )):null}
        {!courseCart?.length && (
          <div className="p-20 pb-30 text-18 text-dark-1">
            Your Course Cart Is Empty
          </div>
        )}
      </div>

      <div className="px-30 pt-20 pb-30 border-top-light">
        <div className="d-flex justify-between">
          <div className="text-18 lh-12 text-dark-1 fw-500">Total:</div>
          <div className="text-18 lh-12 text-dark-1 fw-500">${totalPrice}</div>
        </div>

        <div className="row x-gap-20 y-gap-10 pt-30">
          {courseCart?.length ? (
            <>
              <Link
                to={"/course-cart"}
                style={{ textDecoration: "none" }}
                className="col-sm-6"
              >
                <button className="button py-20 -dark-1 text-white -dark-button-white col-12">
                  View Cart
                </button>
              </Link>
              {parsedLoggedInData.id ?<Link
                to={"/course-checkout"}
                style={{ textDecoration: "none" }}
                className="col-sm-6"
              >
                <button className="button py-20 -purple-1 text-white col-12">
                  Checkout
                </button>
              </Link>:<Link
                to={"/login"}
                style={{ textDecoration: "none" }}
                className="col-sm-6"
              >
                <button className="button py-20 -purple-1 text-white col-12">
                  Login to Checkout
                </button>
              </Link>}
            </>
          ): null}
          {!courseCart?.length && (
            <>
              <Link
                to={"/courses"}
                style={{ textDecoration: "none" }}
                className="col-12"
              >
                <button className="button py-20 -purple-1 text-white col-12">
                  Continue Buying
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCartBox;
