import React from "react";
import signUpImage from '../../../images/career_page/howItWorks/Here_Sign_Up.svg'
import createCourseImage from '../../../images/career_page/howItWorks/Here_Create_Your_Course.svg'
import startLearningImage from '../../../images/career_page/howItWorks/Here_Start_earning.svg'

const steps = [
	{
		id: 1,
		icon: "icon-online-learning-4 text-64 text-green-1",
		image : signUpImage,
		title: "Sign Up",
		text: "Joining is simple and free--no credit card required.",
	},
	{
		id: 2,
		icon: "icon-graduation-1 text-64 text-green-1",
		image : createCourseImage,
		title: "Create Your Course",
		text: "Set up your first course and offer it to our global audience.",
	},
	{
		id: 3,
		icon: "icon-working-at-home-2 text-64 text-green-1",
		image : startLearningImage,
		title: "Start Earning",
		text: "Receive timely payments, funds are available for withdrawal as soon as they clear.",
	},
];

export default function HereHowItWorks() {
	return (
		<section className="layout-pt-lg layout-pb-lg bg-dark-2">
			<div className="container">
				<div className="row justify-center text-center">
					<div className="col-auto">
						<div className="sectionTitle ">
                        <p
								className="sectionTitle__text text-green-1"
								data-aos="fade-up"
								data-aos-duration={800}>
								Discover the seamless process..
							</p>
							<h2
								className="sectionTitle__title text-white lh-14"
								data-aos="fade-up"
								data-aos-duration={800}>
								Here's How it work
							</h2>
						</div>
					</div>
				</div>

				<div className="row y-gap-30 pt-50">
					{steps.map((elm, i) => (
						<div
							key={i}
							className="col-lg-4 col-md-6"
							data-aos="fade-up"
							data-aos-duration={(i + 1) * 400}
							style={{ display: "flex" }}>
							<div className="stepCard -type-1 -stepCard-hover">
								<div className="stepCard__content">
									<div className="stepCard__icon">
										<img src={elm.image} alt="here-how-it-works"/>
									</div>
									<h4 className="stepCard__title">{elm.title}</h4>
									<p className="stepCard__text"> {elm.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
