import {GET_SUPER_ADMIN_DETAIL} from "../../action/commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";

const initialState = {
    data: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
};

const superAdminDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPER_ADMIN_DETAIL : {
            switch (action.status) {
                case LOADING: {
                    return {
                        ...state,
                        isLoading: true,
                    }
                }
                case SUCCESS: {
                    return {
                        ...state,
                        data: action?.payload ?? {},
                        isSuccess: true,
                        isLoading: false,
                    }
                }
                case ERROR: {
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isSuccess: false
                    }
                }

                default:
                    return state
            }
        }
        default :
            return state;
    }
};
export default superAdminDetailReducer;
